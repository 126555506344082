import { textFromUid } from './textUtilities';

export const schemaInput = (name, options) => {
  const inputId = `input-id-${name}`;

  if (!options) options = {};

  const config = {
    ...options,
  };

  if (!config.id) config.id = inputId;
  if (!config.group) config.group = textFromUid(name);
  if (config.required == undefined) config.required = true;
  if (!config.inputType) config.inputType = 'text';
  if (!config.type) config.type = 'input-text';
  if (!config.config) config.config = {};
  if (!config.placeholder) config.placeholder = '';
  if (!config.options) config.options = [];

  return config;
};

export const orderFields = (fields, convertOptions) => {
  if (!convertOptions.order) return fields;

  var result = [];
  var available = fields;

  for (const key of convertOptions.order) {
    const value = available.find((p) => p.id == key);
    if (!value) continue;

    result.push(value);
    const index = available.indexOf(value);
    available.splice(index, 1);
  }

  result = result.concat(available);
  return result;
};

export const objectPaths = (model, convertOptions, basePath) => {
  let result = [];

  if (!model) return result;
  if (!convertOptions) convertOptions = {};
  if (!convertOptions.namePrefix) convertOptions.namePrefix = '';
  if (!basePath) basePath = '';

  for (var key in model) {

    var currentPath = key;
    
    if (basePath.length > 0) currentPath = `${basePath}.${key}`;
    if (convertOptions.ignore && convertOptions.ignore.find((pathIgnore) => pathIgnore == currentPath)) continue;

    var type = typeof model[key];
    if (model[key] == null) type = 'string';

    var value = model[key];
    var options = {
      id: currentPath,
      identifier: currentPath,
      field: textFromUid(
        `${convertOptions.namePrefix}${currentPath.replace('.', '_')}`
      ),
      path: currentPath,
    };

    if (convertOptions.handleValue)
    {
      value = convertOptions.handleValue(key, model, value);
    }

    if (convertOptions.handleType) 
    {
      var customType = convertOptions.handleType(key);
      if (customType != null){
        options.type = customType;
        result.push(options);
        continue;
      }
    }

    switch (type) {
      case 'string': {
        options.type = 'text';
        var date = Date.parse(value);

        if (key == 'data.tag') {
          debugger;
        }

        if (
          value != null &&
          !isNaN(date) &&
          Number.parseInt(value.substring(0, 4)) > 1900
        ) {
          options.type = 'date-time';
        }

        break;
      }
      case 'boolean': {
        options.type = 'boolean';

        break;
      }

      case 'object': {
        var value = model[key];
        var subresult = objectPaths(value, convertOptions, currentPath);

        result = [...result, ...subresult];

        continue;
        break;
      }
      default: {
        continue;
      }
    }

    if (!convertOptions.ignore && convertOptions.order && convertOptions.order.indexOf(currentPath) == -1) continue;
    result.push(options);
  }
  return orderFields(result, convertOptions);
};

export const objectToForm = (model, convertOptions, basePath) => {
  let result = [];

  if (!model) return result;
  if (!convertOptions) convertOptions = {};
  // if (!convertOptions.ignore) convertOptions.ignore = [];
  if (!convertOptions.required) convertOptions.required = [];
  if (!convertOptions.disabled) convertOptions.disabled = [];
  if (!convertOptions.namePrefix) convertOptions.namePrefix = '';
  if (!basePath) basePath = '';

  for (var key in model) {
    var currentPath = key;
    if (basePath.length > 0) currentPath = `${basePath}.${key}`;

    if (convertOptions.ignore && convertOptions.ignore.find((pathIgnore) => pathIgnore == currentPath)) {
      continue;
    }

    var type = typeof model[key];
    if (model[key] == null) type = 'string';

    var currentUid = `${convertOptions.namePrefix}${currentPath.replace(
      '.',
      '_'
    )}`;

    var value = model[key];
    var options = {
      id: currentPath,
      required: false,
      originalName : key,
      informationText: textFromUid(`information_${currentUid}`, ''),
    };

    if (convertOptions.handleType) {
      var customType = convertOptions.handleType(key);
      if (customType != null) type = customType.toLowerCase();
    }

    if (convertOptions.handleOptions) {
      var customOptions = convertOptions.handleOptions(key);

      if (customOptions != null) {
        result.push(schemaInput(currentUid, customOptions));

        continue;
      }
    }

    switch (type) {
      case 'text': 
      case 'string': {
        options.type = 'input-text';
        var date = Date.parse(value);
        var validDate =
          !isNaN(date) &&
          key != 'name' &&
          key != 'title' &&
          Number.parseInt(value.substring(0, 4)) > 1900;
        var checkdate = false;

        if (options.automaticType !== false) {
          if (key == 'description') {
            options.type = 'input-textarea';
          } else if (key == 'duration') {
            options.type = 'input-timer';
          } else if (key.indexOf('interval') != -1) {
            options.type = 'input-timespan';
          }else{
            checkdate = true;
          }
        }else{
          checkdate = true;
        }

        if (checkdate && validDate) {
          options.type = 'input-datetime';
          options.inputType = 'datetime-local';
          value = new Date(date).toDatetimeLocal();
        }

        break;
      }

      case 'number': {
        options.type = 'input-number';

        break;
      }

      case 'boolean': {
        options.type = 'input-option-v2';
        options.options = [
          { label: 'Sim', value: 'true' },
          { label: 'Não', value: 'false' },
        ];

        value = String(value);

        break;
      }
      case 'object': {
        var value = model[key];
        var subresult = objectToForm(value, convertOptions, currentPath);
        options.type = 'input-row';
        result = result.concat(subresult);
        continue;
        break;
      }
      default: {
        continue;
      }
    }

    if (convertOptions.required.find((p) => p == key)) {
      options.required = true;
    }

    if (convertOptions.disabled.find((p) => p == key)) {
      options.disabled = true;
    }

    options.currentValue = value;

    if (!convertOptions.ignore && 
      convertOptions.order && 
      convertOptions.order.indexOf(currentPath) == -1  &&
      convertOptions.order.indexOf(`${basePath}.*`) == -1) continue;

    result.push(schemaInput(currentUid, options));
  }

  return orderFields(result, convertOptions);
};

export const toTreeObject = (newSource, reference, result = {}) => {
  for (const key in newSource) {
    const components = key.split('.');
    var newSourceTarget = result;

    var referenceTarget = reference;
    if (newSource[key] === undefined) continue;

    for (const path of components) {
      if (referenceTarget != null && referenceTarget[path] === undefined)
        continue;
      if (referenceTarget != null) referenceTarget = referenceTarget[path];

      if (components.indexOf(path) == components.length - 1) {
        newSourceTarget[path] = newSource[key];
      } else {
        let sourceData = newSourceTarget[path];
        if (!sourceData) {
          sourceData = {};
          newSourceTarget[path] = sourceData;
        }

        newSourceTarget = sourceData;
      }
    }
  }

  return result;
};

export const updateValues = (newSource, reference) => {
  var result = {};

  for (const key in newSource) {
    const components = key.split('.');
    var newSourceTarget = result;

    var referenceTarget = reference;
    if (newSource[key] === undefined) continue;

    for (const path of components) {
      if (referenceTarget[path] === undefined) continue;
      if (newSourceTarget[path] === undefined) newSourceTarget[path] = {};

      referenceTarget = referenceTarget[path];

      if (components.indexOf(path) == components.length - 1) {
        referenceTarget[path] = newSource[key];
      } else {
        newSourceTarget = newSourceTarget[path];
      }
    }
  }

  return result;
};

export const dynamicPropertiesToForm = (dynamicProperties) =>
{
  const fields = dynamicProperties.map(p=> p.name);
  const data = {};
  dynamicProperties.forEach(p => data[p.name] = p.value);

  var options = objectToForm(data, {
    required: fields,
    order: fields,
    handleType: (data)=>
    { 
      var type = dynamicProperties.find(p=> p.name == data).type;
      return type;
    }
  });

  const values = {};

  options.forEach(option => option.id = `properties.${option.id}`);
  dynamicProperties.forEach(p => values[`properties.${p.name}`] = p.value);

  return {
    fields:options,
    values:values
  };
}

export const formToDynamicProperties = (form, dynamicProperties) =>
{
  var result = [ ...dynamicProperties ];

  result.forEach(property => {
    property.value = form[`properties.${property.name}`];
  })

  return result;
}

Date.prototype.toDatetimeLocal = function toDatetimeLocal() {
  var date = this,
    ten = function (i) {
      return (i < 10 ? '0' : '') + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds());
  return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS;
};
