import styled from 'styled-components';

export const ContainerTableTransaction = styled.div`
  background: #f5f7f8;
  position: absolute;
  bottom: 0px;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  width: auto;
`;

export const ContainerListTransaction = styled.div`
  ${ContainerTableTransaction} {
    top: ${(props) => (props.isFrame ? '56px' : '135px')};
    left: ${(props) => (props.isFrame ? '0' : '230px')};
  }
`;
