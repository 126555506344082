import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Loading,
  NoResult,
  ButtonDefault,
  IconAdd,
  TableDynamic,
  PageContainer,
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import { textFromUid } from '../../utils/textUtilities';
import Sdk from 'api.digitalpages.module.sdk.api';
import Components from 'rdp-builder-components/lib/components';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import _ from 'underscore';

import {
  ContainerNodeEntity,
  TabContainer,
  ContainerSearch,
  ContainerResultSearch,
  ContainerInput,
  ContainerListUser,
  ContainerButton,
  ContainerItens,
} from './styles';
import {
  objectPaths,
  objectToForm,
  toTreeObject,
} from '../../utils/formUtilities';
import { IconDisket, IconTrash } from '../../components/icon';

export default function NewGroupEntity({ consumer, match, history }) {
  const [formIndex, setFormIndex] = useState(0);

  const [selectEntityUid, setSelectEntityUid] = useState(null);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [users, setUsers] = useState(null);

  const [usersUpdate, setUsersUpdate] = useState(0);
  const [userFiltered, setUserFiltered] = useState([]);
  const [wordEntered, setWordEntered] = useState('');

  const [entity, setEntity] = useState(null);
  const [entityUpdated, setEntityUpdated]=useState(null);
  const [entityConfig, setEntityConfig] = useState(null);
  const [modalConfirmationConfig, setModalConfirmationConfig] = useState(null);
  const [schema, setSchema] = useState(null);
  const [roles, setRoles] = useState(null);
  const [lastRequest, setLastRequest] = useState(null);

  const [openModalMembers, setOpenModalMembers] = useState(false);
  const [listMembers, setListMembers] = useState([]);

  const theme = useTheme();
  const modelEntityRef = useRef("");

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_new_entity'),
      path: 'entity',
    });

    getEntity();
    loadSchema();
    loadRoles();
    getMembers();
    getUsers();
  }, [selectEntityUid]);

  useEffect(()=>
  {
    modelEntityRef.current = entityUpdated;
  }, [entityUpdated])

  useEffect(() => {
    if (lastRequest) clearTimeout(lastRequest);
    setLastRequest(setTimeout(getUsers, 1000));
  }, [wordEntered]);

  useEffect(() => {
    syncEntity();

    for (const node of entity ?? []) {
      if (entityConfig && entityConfig.uid == node.uid) {
        setEntityConfig(node);
      }

      if (node.__internal.state == 'created') {
        delete node.__internal.state;
        editEntity(node);

      } else if (node.__internal.state == 'updated') {
        delete node.__internal.state;
      }
    }
  }, [entity]);

  useEffect(() => {
    populateUsers(members);
  }, [members]);

  const loadRoles = async () => {
    const response = await Sdk.dynamic.bridge(`auth/v1.0/roles`, null, 'GET');

    setRoles(response);
  };

  const syncEntity = async () => {
    if (!entity) return;

    let updated = false;
    let result = [];
    let priority = entity.filter(
      (p) =>
        p.__internal.state == 'processing' && p.__internal.action == 'create'
    );

    console.log('prioridade:', entity);

    for (const node of entity) {
      if (node.__internal.state != 'processing') {
        result.push(node);
        continue;
      }

      if (
        priority.length > 0 &&
        priority.find((p) => p.uid == node.uid) == undefined
      ) {
        result.push(node);
        continue;
      }

      updated = true;

      try {
        switch (node.__internal.action) {
          case 'delete': {
            await toast.promise(
              Sdk.dynamic.bridge(
                `auth/v1.1/entity/uid/${node.uid}/management`,
                null,
                'DELETE'
              ),
              {
                pending: textFromUid(`pending_message_deteted_node`),
                success: textFromUid(`success_message_deteted_node`),
                error: textFromUid(`error_message_deteted_node`),
              }
            );
            break;
          }
          case 'create': {
            var nodeResult = await toast.promise(
              Sdk.dynamic.bridge(
                `auth/v1.1/entity/schema/uid/${match.params.schemaUid}/management`,
                node,
                'POST'
              ),
              {
                pending: textFromUid(`pending_message_created_node`),
                success: textFromUid(`success_message_created_node`),
                error: textFromUid(`error_message_created_node`),
              }
            );

            nodeResult.__internal = { state: 'created' };
            result.push(nodeResult);
            break;
          }
          case 'update': {
            var nodeResult = await toast.promise(
              Sdk.dynamic.bridge(
                `auth/v1.1/entity/uid/${node.uid}/management`,
                node,
                'PUT'
              ),
              {
                pending: textFromUid(`pending_message_updated_node`),
                success: textFromUid(`success_message_updated_node`),
                error: textFromUid(`error_message_updated_node`),
              }
            );

            nodeResult.__internal = { state: 'updated' };
            result.push(nodeResult);

            break;
          }
          case 'update-silence': {
            var nodeResult = await Sdk.dynamic.bridge(
              `auth/v1.1/entity/uid/${node.uid}/management`,
              node,
              'PUT'
            );

            nodeResult.__internal = { state: 'updated' };
            result.push(nodeResult);

            break;
          }
        }
      } catch (e) {
        node.__internal.state = 'error';
        node.__internal.stateDetail = 'Falha ao remover';
        result.push(node);
      }
    }

    if (!updated) return;

    var createdEntities = result.filter(p=> p.__internal && p.__internal.state == "created");

    if (createdEntities.length > 0)
    {
      result = await entitiesData();
      
      for(var enty of createdEntities)
      {
        var remoteDate = result.find(p=> p.uid == enty.uid);
        if (!remoteDate) continue;

        remoteDate.__internal = enty.__internal;
      }

    }


    setEntity(result);
  };

  const entitiesData = async () => {
    let entities = await Sdk.dynamic.bridge(
      `auth/v1.1/entity/schema/uid/${match.params.schemaUid}/management/items`,
      null,
      'GET'
    );

    entities = entities.sort((a, b) => a.row - b.row);

    entities.forEach((entity) => {
      entity.__internal = {};
    });

    return entities;
  }

  const getEntity = async () => {
    let entities = await entitiesData();
    setEntity(entities);
  };

  const populateUsers = async (data) => {
    if (!data) return;

    let init = usersUpdate;

    var tempUsers = _.uniq(members, function (item) {
      return item.user_uid;
    });

    for (const user of tempUsers) {
      let userName = textFromUid('user_notfound');

      try {
        var tempUser = await Sdk.dynamic.bridge(
          `auth/v1.0/user/uid/${user.user_uid}`
        );
        userName = tempUser.detail.name;
      } catch {
        // tempUsers[i].user_email = 'Usuário não existente';
      }

      init++;

      data
        .filter((userMember) => userMember.user_uid === user.user_uid)
        .forEach((userMember) => (userMember.user_name = userName));

      setUsersUpdate(init);
    }
  };

  const getMembers = async () => {
    if (!selectEntityUid) return;
    let schemaMembers = await Sdk.dynamic.bridge(
      `auth/v1.1/entity/uid/${selectEntityUid}/management/members`,
      null,
      'GET'
    );
    schemaMembers.forEach((member) => {
      member.user_name = textFromUid('loading_message');
    });

    setMembers(schemaMembers);
  };

  const getUsers = async () => {
    let url = `auth/v1.1/directory/management/users?page_size=10`

    if (wordEntered) url += `&search=${wordEntered}`;
    const response = await Sdk.dynamic.bridge(url);

    setUsers(response.result);
  };

  const addMembers = async (data) => {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.1/entity/uid/${selectEntityUid}/management/member`,
      data,
      'POST'
    );
  };

  const removeMembers = async (data, uidMember) => {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.1/entity/member/uid/${uidMember}/management`,
      data,
      'DELETE'
    );
  };

  const editEntity = (node) => {
    setEntityConfig(node);
  };

  const loadSchema = async () => {
    let schemaData = await Sdk.dynamic.bridge(
      `auth/v1.1/entity/schema/uid/${match.params.schemaUid}/management`,
      null,
      'GET'
    );

    setSchema(schemaData);
  };

  const createEntity = (parent) => {
    var newEntity = {
      uid: 'temp',
      name : '-',
      row: 0,
      column: 0,
      data: {},
      __internal: { state: 'processing', action: 'create' },
    };

    schema.properties.forEach((data) => {
      var defaultValue = '-';

      switch (data.type) {
        case 'ColorHex': {
          defaultValue = '#ff0000';
          break;
        }
        case 'NumberDecimal':
        case 'NumberInteger': {
          defaultValue = '0';
        }
      }

      newEntity.data[data.name] = defaultValue;
    });

    if (parent) {
      const childColumn = parent.column + 1;
      var childRow = parent.row + 1;

      for (const entities of entity.filter((node) => node.row > parent.row)) {
        if (entities.column <= parent.column) break;
        childRow = entities.row + 1;
      }

      newEntity.row = childRow;
      newEntity.column = childColumn;
    } else if (entity.length > 0) {
      newEntity.row = entity[entity.length - 1].row + 1;
    }

    entity.forEach((entity) => {
      if (entity.row < newEntity.row) return;
      // entity.__internal.state = 'processing';
      // entity.__internal.action = 'update-silence';
      entity.row++;
    });

    entity.push(newEntity);

    var result = [...entity.sort((a, b) => a.row - b.row)];
    setEntity(result);
  };

  const handleNodeInsert = (entity, contentType) => {
    switch (contentType) {
      case 'entity': {
        createEntity(entity);
        break;
      }
    }
  };

  const handleDeleteEntity = (dataEntity) => {
    var confirmation = {
      title: 'Atenção!',
      description: `Deseja realmente remover o nó "${dataEntity.data.title}", todas as atividades atreladas e os históricos dos usuários relacionados a essas atividades?`,
      action: () => {
        dataEntity.__internal.state = 'processing';
        dataEntity.__internal.action = 'delete';

        const entityUpdate = [...entity];
        setModalConfirmationConfig(null);
        setEntity(entityUpdate);
      },
    };

    setModalConfirmationConfig(confirmation);
  };

  const entitySchemas = useMemo(() => {
    if (formIndex !== 0) return null;
    if (!entity) return <Loading msg={textFromUid('loading_message')} />;
    if (!schema) return <Loading msg={textFromUid('loading_message')} />;

    return (
      <TabContainer key={`entities-tab`}>
        <Components.Headers.TitleAndDescription
          title={textFromUid('entity_structure_title')}
          description={textFromUid('entity_structure_description')}
        />
        <ContainerNodeEntity>
          {entity.length == 0 && <NoResult />}
          <Components.Recursive.TreeEditV2
            key={`nodes-tree`}
            nodes={entity}
            onDeleteNode={handleDeleteEntity}
            onEditNode={editEntity}
            handleNodeSelectAction={handleNodeInsert}
            optionsMenus={[
              { label: textFromUid('add_entity'), value: 'entity' },
            ]}
          />
        </ContainerNodeEntity>

        <ButtonDefault
          text={textFromUid('add_entity')}
          onClick={() => createEntity()}
          icon={<IconAdd />}
        />
      </TabContainer>
    );
  }, [entity, schema, formIndex]);

  const membersSelected = (uid) => {
    var index = selectedMembers.indexOf(uid);

    if (index !== -1) {
      selectedMembers.splice(index, 1);
    } else {
      selectedMembers.push(uid);
    }

    setSelectedMembers([...selectedMembers]);
  };

  const handleRemoveMembers = async () => {
    const adminHasRole = Sdk.hasRole(['AdministratorGlobal']);

    for (const memberInsert of selectedMembers) {
      const userMembersUpdated = await toast.promise(
        removeMembers({ role: adminHasRole.type }, memberInsert),
        {
          pending: textFromUid('pending_message_user_remove_member'),
          success: textFromUid('success_message_user_remove_member'),
          error: textFromUid('error_message_user_remove_member'),
        }
      );
    }

    await getMembers();
  };

  const handleAddMember = async (uidListMember) => {
    for (const memberInsert of uidListMember) {
      const userMembersUpdated = await toast.promise(
        addMembers({ user_uid: memberInsert.uid }),
        {
          pending: textFromUid('pending_message_user_add_member'),
          success: textFromUid('success_message_user_add_member'),
          error: textFromUid('error_message_user_add_member'),
        }
      );

      await getMembers();
      setOpenModalMembers(false);
      setListMembers([]);
    }
  };

  const membersSchemas = useMemo(() => {
    if (formIndex !== 1) return null;
    if (!entity) return <Loading msg={textFromUid('loading_message')} />;

    // const order = ['author_user_uid'];

    const ignoreAvailableFiels = [
      'author_user_uid',
      'created_at',
      'directory_uid',
      'project_uid',
      'updated_at',
      'uid',
    ];

    const paths = objectPaths(members && members[0], {
      // order: order,
      namePrefix: 'table_header_members_',
      ignore: ignoreAvailableFiels,
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('member_structure_title')}
          description={textFromUid('member_structure_description')}
        />

        {selectEntityUid &&
        selectEntityUid == '' &&
        members &&
        members.length == 0 ? (
          <NoResult />
        ) : (
          <>
            <ContainerNodeEntity>
              <Components.Buttons.Select
                borderColor="#d3d5dd"
                title="Tipo"
                // defaultValue="Text"
                items={entity.map((selectEntity) => ({
                  label: selectEntity.data[schema.properties[0].name],
                  value: selectEntity.uid,
                }))}
                onChange={(event) => setSelectEntityUid(event)}
              />

              <TableDynamic
                headers={paths}
                defaultFields={[]}
                data={members && members}
                hasCheckbox={true}
                onCheckboxClick={(user_uid) => membersSelected(user_uid)}
              />
            </ContainerNodeEntity>

            <ButtonDefault
              text={textFromUid('button_remove_members')}
              icon={<IconTrash />}
              onClick={() => handleRemoveMembers()}
              disabled={selectedMembers.length == 0 ? true : false}
            />
          </>
        )}
      </>
    );
  }, [formIndex, members, entity, usersUpdate, selectedMembers]);

  const handleOpenModalMembers = () => {
    setOpenModalMembers(true);
  };

  const handleCloseModalMembers = () => {
    setOpenModalMembers(false);
    setListMembers([]);
  };

  const modalAddMembers = useMemo(() => {
    if (!openModalMembers) return null;

    const newUsers = [...users];

    const handleFilter = (event) => {
      const searchWord = event.target.value;
      setWordEntered(searchWord);

      // const newFilter =
      //   newUsers &&
      //   newUsers.filter((nameUser) => {
      //     return nameUser.detail.name
      //       .toLowerCase()
      //       .includes(searchWord.toLowerCase());
      //   });

      // if (searchWord === '') {
      //   setUserFiltered([]);
      // } else {
      //   setUserFiltered(newFilter);
      // }
      // setUserFiltered(newFilter);
    };

    const clearInput = () => {
      setWordEntered('');
    };

    const addMembersList = (members) => {
      const membersCopy = [...listMembers];

      membersCopy.push(members);

      setListMembers(membersCopy);
      clearInput();
    };

    const removeMembersList = (index) => {
      const membersCopy = [...listMembers];

      membersCopy.splice(index, 1);
      setListMembers(membersCopy);
    };

    return (
      <Components.Alerts.Modal
        onCloseClick={handleCloseModalMembers}
        title={textFromUid('modal_title_add_member')}
        closeIconColor="#764AB0"
        maxHeight="52.875rem"
        boxHeight="53.125rem"
        boxWidth="46.125rem"
        buttons={<>
          <ButtonDynamic color="blue" icon="plus" actionClick={()=> handleAddMember(listMembers)}>
            {textFromUid("button_save_members")}
          </ButtonDynamic>
        </>}
      >
        <>
          <ContainerSearch>
            <ContainerInput>
              <input
                placeholder={textFromUid('placeholder_search_user')}
                type="text"
                onChange={handleFilter}
                value={wordEntered}
              />
            </ContainerInput>

            {users.length != 0 && wordEntered !== '' && (
              <ContainerResultSearch>
                {users.map((nameUser, key) => {
                  if (nameUser.detail.email_principal) {
                    return (
                      <div
                        key={nameUser.uid}
                        // onClick={async () => await handleAddMember(nameUser.uid)}
                        onClick={async () => await addMembersList(nameUser)}
                      >
                        <p>{nameUser.detail.email_principal}</p>
                      </div>
                    );
                  };
                })}
              </ContainerResultSearch>
            )}
          </ContainerSearch>

          <ContainerListUser>
            <ul>
              {listMembers.map((nameMember, index) => (
                <ContainerItens>
                  <li key={nameMember.uid}>{nameMember.detail.email_principal}</li>

                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      removeMembersList(index);
                    }}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#764AB0"
                        d="M5.16329 6.60098L0.211513 11.5528L1.44738 12.7887L6.39914 7.83687L11.5621 13L12.798 11.7641L7.63501 6.60098L13 1.23589L11.7641 0L6.39915 5.36509L1.23587 0.201656L0 1.43754L5.16329 6.60098Z"
                      ></path>
                    </svg>
                  </span>
                </ContainerItens>
              ))}
            </ul>
          </ContainerListUser>
        </>

        <ContainerButton>
          <ButtonDefault
            text={textFromUid('button_save_members')}
            icon={<IconDisket />}
            onClick={() => handleAddMember(listMembers)}
          />
        </ContainerButton>
      </Components.Alerts.Modal>
    );
  }, [openModalMembers, users, wordEntered, userFiltered, listMembers]);

  const handleUpdateEntity = () => 
  {
    if (!entityConfig) return;

    var data = modelEntityRef.current;
    const result = toTreeObject(data, entityConfig, entityConfig);

    result.__internal.state = 'processing';
    result.__internal.action = 'update';

    var updateEntity = [...entity];

    setEntity(updateEntity);
  };

  const modalEntity = useMemo(() => {
    if (!entityConfig) return null;
    if (!roles) return null;

    const rolesType = roles.map((role) => {
      return {
        value: role.type,
        label: textFromUid(`role_type_${role.type.toLowerCase()}`),
      };
    });

    const disabledFields = ['uid', 'created_at', 'updated_at'];
    const requiredFields = ['name'];

    var objRoles = {
      id: 'roles',
      group: textFromUid('schema_type'),
      required: true,
      type: 'input-select',
      config: {},
      options: rolesType,
      defaultValue: 'Inherited',
      field: 'roles',
      placeholder: 'Digite o tipo',
    };

    var options = objectToForm(entityConfig, {
      disabled: disabledFields,
      required: requiredFields,
      namePrefix: 'entity_',
      order: [
        'uid',
        'roles',
        'row',
        'column',
        'created_at',
        'updated_at',
        'name',
        'data.*',
      ],

      handleOptions: (key) => {
        if (key === 'roles') return objRoles;
        return null;
      },
    });

    const currentValues = {};
    options.forEach((field) => {
      if (field.id.indexOf('data.') == 0) field.group = field.originalName;
      currentValues[field.id] = field.currentValue;
    });

    return (
      <Components.Alerts.Modal
        theme={theme}
        closeIconColor="#764AB0"
        title={textFromUid('modal_definitions_group')}
        boxWidth={'90%'}
        boxHeight="100%"
        maxHeight={'80%'}
        onCloseClick={() => setEntityConfig(null)}
        // onActionClick={()=>{}}
        // actionLabel={textFromUid('modal_node_edit_button')}
        buttons={<>
          <ButtonDynamic color="blue" icon="plus" actionClick={handleUpdateEntity}>
            {textFromUid("activity_button_update")}
          </ButtonDynamic>
        </>}
      >
        <DynamicForm
          form={{ fields: options }}
          defaultValues={currentValues}
          handleChanges={setEntityUpdated}
        />
      </Components.Alerts.Modal>
    );
  }, [entityConfig]);

  const modalConfirmation = useMemo(() => {
    if (!modalConfirmationConfig) return null;

    return (
      <Components.Alerts.Modal
        closeIconColor="#764AB0"
        title={modalConfirmationConfig.title}
        text={modalConfirmationConfig.description}
        onCloseClick={() => setModalConfirmationConfig(null)}
        buttons={<>
          <ButtonDynamic color="blue" icon="plus" actionClick={modalConfirmationConfig.action}>
            {textFromUid("modal_button_confirm_delete_node")}
          </ButtonDynamic>
        </>}
      />
    );
  }, [modalConfirmationConfig]);

  return (
    <div className="rdp-admin-edit-user">
      <Header />
      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />

      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        newOption={formIndex === 1 && handleOpenModalMembers}
        customNewOptionTitle={
          formIndex === 1 && textFromUid('button_add_new_member')
        }
        disabledNewOption={selectEntityUid !== null || '' ? false : true}
      />

      <PageContainer>
        <div className="form-buttons">
          <button
            className={`tab-button ${formIndex === 0 ? 'active' : null}`}
            onClick={() => setFormIndex(0)}
          >
            {textFromUid('tab_members_emtity_information')}
          </button>
          {schema && schema.type == 'Security' && (
            <button
              className={`tab-button ${formIndex === 1 ? 'active' : null}`}
              onClick={() => setFormIndex(1)}
            >
              {textFromUid('tab_members_information')}
            </button>
          )}
        </div>

        {entitySchemas}
        {membersSchemas}
      </PageContainer>

      {modalEntity}
      {modalConfirmation}
      {modalAddMembers}
    </div>
  );
}
