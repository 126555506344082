import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Header,
  Sidebar,
  Breadcrumb,
  TableDynamic,
  HeaderFilter,
  Pagination,
  NotifyBox,
} from '../components';

import { IconConstruct, IconNone } from '../components/icon';
import Loading from '../components/loading';
import { textFromUid } from '../utils/textUtilities';
import RDP_CONFIG from '../config/config';

export const GenericList = ({
  hideDelete = false,
  consumer = {},
  breadcrumb = {},
  elements = {},
  defaultFilters = {},
  loading = {},
  options = {},
  messages = {},
  actions = {},
  fields = [],
  entity,
  entityId,
  title,
  entityOptionFilter,
  enableLocalSearch = false,
  localSearchPlaceholder = 'Buscar',
  buttonDelete,
  showLoadMore = false,
  loadMoreBtnLabel = 'Carregar mais',
  isPaginated = false,
  onLoadMoreClick = function () {},
  onPreviousButtonClick = () => {},
  onNextButtonClick = () => {},
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [elementDelete, setElementDelete] = useState([]);
  const [orderedData, setOrderedData] = useState(elements.result);
  const [filters, setFilters] = useState(defaultFilters);
  const [localSearchResults, setLocalSearchResults] = useState(null);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  const isFrame = window.location.hash.includes('embed/');

  const redirectToNew = () => {
    if(isFrame) {
      setRedirectTo('/embed/product/new');
      return;
    }
    if (breadcrumb.routeNew) {
      if (typeof breadcrumb.routeNew === 'function') {
        breadcrumb.routeNew();
      } else {
        setRedirectTo(breadcrumb.routeNew);
      }
    }
  };

  const handleCheckBoxSelectAll = (action, values) => {
    if (action === 'add-check') {
      setElementDelete(values);
    } else {
      setElementDelete([]);
    }
  };

  const handleCheckBoxClick = (value) => {
    const elements = [...elementDelete];
    const findIndex = elements.findIndex((element) => element === value);

    if (findIndex >= 0) {
      elements.splice(findIndex, 1);
    } else {
      elements.push(value);
    }

    setElementDelete(elements);
  };

  const onUpdate = (id) => {
    if (actions.onUpdate) {
      actions.onUpdate(id);
    }
  };

  const onClone = (uid) => {
    if (actions.onClone) {
      actions.onClone(uid);
      setElementDelete([]);
    }
  };

  const onDelete = (uid) => {
    if (actions.onDelete) {
      actions.onDelete(uid || elementDelete);
      setElementDelete([]);
    }
  };

  const getObject = (field, obj) => {
    if (field.path) {
      return eval(`obj.${field.path}`);
    }

    return obj[field.identifier];
  };

  const sortItems = async (filter) => {
    let aux = [...elements.result];
    let filtersAux = { ...filters };
    let flag = false;

    const ordered = aux.sort((itemA, itemB) => {
      if (
        filter.identifier === filters.order_field &&
        filters.order_type === 'desc'
      ) {
        flag = true;
        if (getObject(filter, itemA) < getObject(filter, itemB)) return -1;
        if (getObject(filter, itemA) > getObject(filter, itemB)) return 1;
      } else {
        if (getObject(filter, itemA) < getObject(filter, itemB)) return 1;
        if (getObject(filter, itemA) > getObject(filter, itemB)) return -1;
      }

      return 0;
    });

    filtersAux.order_field = filter.identifier;

    if (flag) filtersAux.order_type = 'asc';
    else filtersAux.order_type = 'desc';

    setFilters(filtersAux);

    return ordered;
  };

  const onFilterChange = async (filter) => {
    const ordered = await sortItems(filter);

    setOrderedData(ordered);
  };

  const hasContent = elements.result && elements.result.length;

  const getCounter = (elements, filters) =>
    (filters.page ? filters.page - 1 : 0) * elements.page_size +
    (elements.result ? elements.result.length : 0);

  const onLocalSearch = (value) => {
    var filtered = elements.result.filter((item) =>
      item.detail.name
        ? item.detail.name.toLowerCase().includes(value.toLowerCase())
        : false
    );
    // console.log(filtered);
    setLocalSearchResults(filtered ? filtered : null);
  };

  const onResetSearch = () => {
    setLocalSearchResults(null);
  };

  const handlePaginationClick = (page) => {
    // console.log(page);

    // this.setState({
    //   filters: {
    //     ...this.state.filters,
    //     page: page,
    //   }
    // }, () => {
    //   this.getNotices(this.state.currentRoute);
    // });
  };

  return (
    <div className="rdp-admin-list-content">
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />

      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        currentGroup={consumer ? consumer.currentGroup : null}
        options={breadcrumb.selectOptions}
        optionSelected={breadcrumb.handleOptionSelected}
        newOption={breadcrumb.routeNew ? redirectToNew : null}
        label={breadcrumb.label}
        customNewOptionTitle={breadcrumb.customNewOptionTitle}
        importCsvEnabled={breadcrumb.importCsvEnabled || false}
        importCsvOnClick={breadcrumb.importCsvOnClick || function() {}}
      />
      <div id="rdp-admin-content-area" className="rdp-admin-content" style={{ 
        ...(isFrame && {
          top: 56,
          left: 0,
        })
      }}>
        {loading.submit && <Loading msg={loading.submit} />}
        {!loading.preloader && messages.success && (
          <NotifyBox
            type="success"
            onClose={() => actions.closeMessage('success')}
            message={messages.success}
          />
        )}
        <>
          {hasContent && (
            <HeaderFilter
            hideDelete={hideDelete}
              entityOptionFilter={entityOptionFilter}
              configuration={elements.details}
              count={getCounter(elements, filters) || 0}
              total={elements.row_count}
              selectedToDelete={elementDelete}
              buttonDelete={buttonDelete}
              body={elements.result}
              onChangeEntityValue={(value) => this.onChangeEntityValue(value)}
              // onStatusChange={(status) => this.onFilterStatusChange(status)}
              // onDateChange={(start, finish) => this.onFilterDataChange(start, finish)}
              // onTextChange={(text) => this.onFilterTextChange(text)}
              // status={filters.status}
              // start_at={filters.start_at}
              // finish_at={filters.finish_at}
              text={filters.text}
              entityId={entityId}
              entity={entity}
              onDelete={onDelete}
              enableLocalSearch={enableLocalSearch}
              localSearchPlaceholder={localSearchPlaceholder}
              onLocalSearch={(value) => onLocalSearch(value)}
              onResetSearch={() => onResetSearch()}
            />
          )}
          {!loading.preloader && !hasContent && (
            <div id="rdp-admin-content-area" className="rdp-admin-content">
              <div className="box-construction">
                <div>
                  <IconConstruct />
                </div>
                <p> {textFromUid('no_result')}</p>
              </div>
            </div>
          )}
          {!loading.preloader && messages.error && (
            <NotifyBox
              type="error"
              onClose={() => actions.closeMessage('error')}
              message={messages.error}
            />
          )}
          {!loading.preloader && messages.noContent && (
            <div className="box-construction">
              <div>
                {' '}
                <IconNone />{' '}
              </div>
              <p>{textFromUid('no_result')}</p>
            </div>
          )}
          {loading.preloader && <Loading msg="Carregando conteúdo..." />}
          {!loading.preloader && (
            <TableDynamic
              title={title}
              configuration={elements.details}
              headers={(elements.details || []).slice(0, 4)}
              defaultFields={fields}
              data={
                localSearchResults !== null
                  ? localSearchResults
                  : orderedData
                  ? orderedData
                  : elements.result
              }
              hasCheckbox={options.hasCheckbox}
              hasEdit={options.hasEdit}
              hasDelete={options.hasDelete}
              hasClone={options.hasClone}
              filters={filters}
              onFilterChange={(filter) => onFilterChange(filter)}
              onCheckboxClick={handleCheckBoxClick}
              onCheckBoxCheckAll={handleCheckBoxSelectAll}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onClone={onClone}
            />
          )}

          {showLoadMore && (
            <button
              onClick={() => onLoadMoreClick()}
              className="rdp-load-more-btn"
            >
              {loadMoreBtnLabel}
            </button>
          )}

          {isPaginated && (
            <Pagination
              currentPage={elements.current_page}
              registerCount={elements.row_count}
              totalPages={elements.total_pages}
              onPaginationButtonClick={(page) => handlePaginationClick(page)}
              onPreviousButtonClick={onPreviousButtonClick}
              onNextButtonClick={onNextButtonClick}
            />
          )}
        </>
      </div>
    </div>
  );
};
