import React, { useEffect, useState, useMemo, useRef } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';

import {
  Header,
  Sidebar,
  Breadcrumb,
  PageContainer,
  Loading,
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import { textFromUid } from '../../utils/textUtilities';
import { objectToForm } from '../../utils/formUtilities';
import { toast } from 'react-toastify';
import Components from 'rdp-builder-components';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import { Redirect } from 'react-router-dom';

export default function ViewNewProject({ consumer, match }) {
  const [currentInfo, setCurrentInfo] = useState(null);
  const [updatedInfo, setUpdatedInfo] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const info = useRef('');

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_edit_project'),
      path: 'project/:projectUid',
    });

    getDirectory();
  }, []);

  useEffect(() => {
    info.current = updatedInfo;
  }, [updatedInfo]);

  const infoChanged = useMemo(() => {
    var result = JSON.stringify(currentInfo) === JSON.stringify(updatedInfo);
    return result;
  }, [currentInfo, updatedInfo]);

  const getDirectory = async () => {
    var activeDirectory = Sdk.authorization.activeDirectory;
    let directoryData = await Sdk.authorization.directoryInfo(
      activeDirectory.uid
    );

    var info = { name: '' };
    directoryData.config.required_project_properties.forEach((prop) => {
      info[prop.name] = '';
    });

    setCurrentInfo(info);
  };

  const handleSubmit = async () => {
    var data = { name: updatedInfo.name, properties: [] };

    for (var key in updatedInfo) {
      if (key == 'name') continue;

      data.properties.push({
        name: key,
        value: updatedInfo[key],
        type: 'text',
      });
    }

    const res = await toast.promise(
      Sdk.dynamic.bridge(`auth/v1.1/project/management`, data, 'POST'),
      {
        pending: textFromUid('pending_message_new_project'),
        success: textFromUid('success_message_new_project'),
        error: textFromUid('error_message_new_project'),
      }
    );

    setCurrentInfo(updatedInfo);
    await Sdk.Api.authorization.refreshToken();

    if (!res) return;

    setRedirectTo(`/project/${res.uid}`);
  };

  const projectOptions = useMemo(() => {
    if (!currentInfo) return <Loading msg={textFromUid('loading_message')} />;

    var fields = objectToForm(currentInfo);
    return (
      <DynamicForm
        form={{ fields: fields }}
        defaultValues={currentInfo}
        handleChanges={setUpdatedInfo}
        handleSubmit={handleSubmit}
        submitText={textFromUid('button_new_project')}
        disabledButtonSubmit={infoChanged}
      />
    );
  }, [currentInfo, infoChanged]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <Header />

      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />

      <PageContainer>
        <Components.Headers.TitleAndDescription
          title={textFromUid('project_new_information_title')}
          description={textFromUid('project_new_information_description')}
          padding="20px 0px 20px 0px"
        />
        {projectOptions}
      </PageContainer>
    </>
  );
}
