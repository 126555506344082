import React, { useState, useEffect } from 'react';
import Components from 'rdp-builder-components';
import collapseArrow from '../../assets/images/icons/collapse-arrow.png';

export default function MultiCheckbox({
  field,
  defaultValues = [],
  handleChange,
}) {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (defaultValues && defaultValues.length > 0 && !!field.options) {
      const targetOptions = field.options.filter((option) =>
        defaultValues.includes(option.uid)
      );
      targetOptions.forEach((option) => {
        if (typeof handleChange === 'function') handleChange(true, option.uid);
      });
    }
  }, []);
  return (
    <div key={field.id} className="row">
      <div style={{ color: 'black' }}>
        <div className="row-header" onClick={(e) => setOpen(!open)}>
          <img
            style={{
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
            src={collapseArrow}
          />
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </div>
      </div>
      <div
        style={{ visibility: open ? 'visible' : 'visible' }}
        className={`checkbox-row ${
          !open ? 'checkbox-row-hide' : 'checkbox-row-show'
        }`}
      >
        {open && (
          <div
            style={{ visibility: open ? 'visible' : 'visible' }}
            className={`checkbox-row ${
              !open ? 'checkbox-row-hide' : 'checkbox-row-show'
            }`}
          >
            <ul>
              {field &&
                field.options.map((option) => (
                  <>
                    <li style={{ marginTop: '5px' }}>
                      <Components.Buttons.Checkbox
                        label={option.label}
                        checkedColor="#764AB0"
                        defaultChecked={defaultValues.includes(option.uid)}
                        onChange={(value, label, checked) => {
                          // console.log('calling onchange');
                          handleChange(checked, option.uid);
                        }}
                      />
                    </li>
                  </>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
