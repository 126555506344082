import React, { Component } from 'react';
import './list-courses.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import Configuration from './list-courses.config';
import { GenericList } from '../../builders/list';
import { Redirect } from 'react-router-dom';

export default class ViewCourseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preloader: true,
      loadingSubmit: null,
      messages: {
        success: null,
        error: null,
      },
      redirectTo: null,
      filters: {
        page: 1,
      },
      body: [],
      seletedElementToDelete: [],
      elements: {},
    };
    this.deleteProducts = this.deleteProducts.bind(this);
  }

  componentDidMount() {
    this.getCourses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.entityId !== prevProps.match.params.entityId) {
      this.setState(
        {
          filters: {
            order_field: 'created_at',
            order_type: 'desc',
            status: '',
            start_at: '',
            finish_at: '',
            text: '',
            page: 1,
          },
        },
        () => {
          this.getCourses();
        }
      );
      this.setState({
        seletedElementToDelete: [],
      });
    }
  }

  async getCourses() {
    this.setState({ preloader: true });
    const courses = await Sdk.Api.cms.courses();

    const result = await Sdk.authorization.entity.entitySchemasAvailableInProject();
    const tempStructure = {
      result: result,
      current_page: 1,
      page_count: 1,
      page_size: result.length,
      row_count: result.length,
      messages: {
        noContent: result.length == 0 ? 'Nenhum curso cadastrado' : null,
      },
    };

    this.setState({
      elements: tempStructure,
      preloader: false,
      messages: {
        noContent: result.length == 0 ? 'Nenhum curso cadastrado' : null,
      },
    });
  }

  newCourse = () =>
  {

  }

  redirectToNew = () => {
    return this.setState({ redirectTo: `/learning/courses/new` });
  };

  onUpdate = (uid) => {
    return this.setState({ redirectTo: `/learning/courses/${uid}` });
  };

  handleMultiDelete = (id) => {
    this.setState(
      {
        deleteModal: false,
        loadingSubmit: 'Apagando dados...',
      },
      () => {
        this.deleteProducts(this.state.seletedElementToDelete);
      }
    );
  };

  async deleteProducts(uids) {
    const products = this.state.elements.result;

    for (const index in uids) {
      const uid = uids[index];
      const product = products.find((p) => p.uid == uid);

      if (!product) continue;

      await Sdk.store.deleteProduct(product);
    }

    this.setState(
      {
        loadingSubmit: null,
        seletedElementToDelete: [],
        messages: {
          success: 'Produto(s) removido(s)',
          error: null,
        },
      },
      this.getCourses
    );
  }

  closeMessage = (type) => {
    this.setState({
      messages: {
        ...this.state.messages,
        [type]: null,
      },
    });
  };

  render() {
    const { messages, elements, preloader, redirectTo } = this.state;

    const { entity } = this.props.match.params;
    const { consumer } = this.props;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <GenericList
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={Configuration.fields}
        options={Configuration.options}
        breadcrumb={{
          selectOptions: null,
          routeNew: '/learning/courses/new',
          label: entity,
          customNewOptionTitle: 'Novo curso',
          newOption:this.newCourse
        }}
        loading={{
          preloader,
        }}
        actions={{
          closeMessage: this.closeMessage,
          onUpdate: this.onUpdate,
          onDelete: (e) => console.log(e),
        }}
      />
    );
  }
}
