import styled from 'styled-components';

export const Options = styled.div`
    display: inline-block;
    width: 100%;
    justify-content: center;
    overflow: hidden;
`;

export const Option = styled.button`
    display: inline-block;
    // margin-bottom: 20px;
    padding: 16px 30px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    background: #ffffff;
    color: #2d2f3e;
    border-bottom: 5px solid #ffffff;

    ${
        ({ active }) =>
        {
            var dynamicInfo = '';

            if (active){
                dynamicInfo += 'color: #764ab0\;';
                dynamicInfo += 'border-bottom: 5px solid #764ab0\;';
            }

            return dynamicInfo;
        }
    }
`;