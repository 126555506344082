import React, { useEffect, useState } from 'react';
// import './new-course.scss';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  Loading,
  NotifyBox,
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import DynamicForm from '../../components/form-dynamic-v2';

export default function NewOffer({ consumer, history, match }) {
  const [entity, setEntity] = useState();
  const [entityUpd, setEntityUpd] = useState();
  const [refList, setRefList] = useState();
  const [ids, setIds] = useState();
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  const getOptions = async (list) => {
    const options = await list.map((item) => {
      return { uid: item.uid, label: item.data[Object.keys(item.data)[0]] };
    });

    return options;
  };

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    setEntity(forms['new-offer']);
  };

  const loadEntities = async () => {
    if (entity) {
      const entitiesCp = { ...entity };
      let idList = [];

      const res = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/available?type=security`,
        null,
        'GET'
      );

      for (const [idx, entity] of res.entries()) {
        const tree = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/management/schema/uid/${entity.uid}/items`,
          null,
          'GET'
        );

        const schemaInfo = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/schema/uid/${entity.uid}`,
          null,
          'GET'
        );

        entitiesCp.fields.push({
          id: `container-category-${idx}`,
          group: entity.name,
          schema_properties: schemaInfo.properties,
          required: true,
          type: 'input-tree-checkbox',
          defaultValues: refList,
          config: {},
          field: `entities-${idx}`,
          options: tree,
        });

        idList.push(`container-category-${idx}`);
      }

      const certificateTemplates = await Sdk.dynamic.bridge(
        `storage/v1.0/search?filter=(content_type eq 'digitalpages/template') and (project_uid eq '${Sdk.Api.authorization.activeProject.uid}')&top=1000&orderBy=last_modified desc&(content_info/any(info: info eq 'type=certificate'))`
      );

      if (certificateTemplates.results.length > 0) {
        entitiesCp.fields.push({
          id: 'certificate_internal_content_uid',
          config: {},
          field: 'certificate_internal_content_uid',
          group: 'Template de certificado',
          placeholder: 'Select a type',
          required: false,
          type: 'input-select',
          options: certificateTemplates.results.map((item) => {
            return {
              label: item.document.content_info_values[1],
              value: item.document.content_uid,
            };
          }),
        });
      }

      setEntityUpd(entitiesCp);
      setIds(idList);
    }
  };

  async function getDefaultValues() {
    const register = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/register/uid/${match.params.registerUid}/management`,
      null,
      'GET'
    );

    const list = await register.authorizations
      .flatMap((a) =>
        a.references.map((ref) => {
          return ref.reference_uid;
        })
      )
      .filter((value, index, self) => self.indexOf(value) === index);

    setRefList(list);
    setDefaultValues(register);
  }

  useEffect(() => {
    getForm();
    getDefaultValues();
  }, []);

  useEffect(() => {
    loadEntities();
  }, [refList]);

  const handleSubmit = async (inputs, setInputs) => {
    const uids = await ids.map((item) => {
      if (inputs[item]) {
        return Object.keys(inputs[item]).filter((key) => inputs[item][key]);
      }
    });

    const auths = adjustAuthorizations(uids, false);

    try {
      setLoadingSubmit(true);

      let {
        name,
        description,
        register_start,
        register_end,
        visibility_start,
        visibility_end,
        interaction_start,
        interaction_end,
        certificate_internal_content_uid,
      } = { ...inputs };
      const putRegisterObj = {
        name,
        description,
        register_start,
        register_end,
        visibility_start,
        visibility_end,
        interaction_start,
        interaction_end,
        certificate_internal_content_uid,
        authorizations: auths,
      };

      putRegisterObj.certificate_internal_content_custom_service =
        '/default/target';

      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${match.params.registerUid}/management`,
        putRegisterObj,
        'PUT'
      );

      setMsgSuccess('Oferta criada com sucesso');
      setInputs({});
      history.push(`/tree/${match.params.treeUid}`);
      setLoadingSubmit(false);
    } catch (error) {
      setMsgError('Algo deu errado ... Tente novamente mais tarde');
    }
  };

  function adjustAuthorizations(uids, combined) {
    let groups = [];

    if (combined) {
      let uidflat = uids.flat().filter((el) => el !== undefined);
      groups.push(uidflat);
    } else {
      var result = [[]];
      uids
        .filter((group) => group != undefined)
        .forEach(
          (group, index) =>
            (result = group.flatMap((g) => result.map((r) => r.concat(g))))
        );
      groups = result;
    }

    let authorizations = groups.map((entities) => {
      return {
        role: 'Viewer',
        references: entities.map((item) => {
          return { reference_uid: item, type: 'Entity' };
        }),
      };
    });

    return authorizations;
  }

  return (
    <div>
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content">
        {loadingSubmit && <Loading msg="Criando oferta..." />}
        {!loadingSubmit && msgSuccess && (
          <NotifyBox
            type="success"
            onClose={() => setMsgSuccess('')}
            message={msgSuccess}
          />
        )}
        {!loadingSubmit && msgError && (
          <NotifyBox
            type="error"
            onClose={() => setMsgError('')}
            message={msgError}
          />
        )}
        {entity && defaultValues ? (
          <DynamicForm
            handleSubmit={handleSubmit}
            form={entityUpd ? entityUpd : entity}
            submitText="SALVAR OFERTA"
            defaultValues={defaultValues}
          />
        ) : (
          <Loading msg="Configurando formulário ..." />
        )}
      </div>
      <Footer version="01" />
    </div>
  );
}
