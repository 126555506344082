import React, { Component } from 'react';
import './button-grey.scss';

export default class ButtonGrey extends Component {
  render() {
    const { onClick, label = "Importar", icon = "" } = this.props;
    return <button onClick={() => onClick()} className="rdp-admin-component-button-grey">
      <span className={`rdp-admin-component-button-grey-icon ${icon}`}></span>
      <span className="rdp-admin-component-button-grey-label">{label}</span>
    </button>
  }
}