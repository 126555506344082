import React, { Component } from 'react';
import { Header, Sidebar, Breadcrumb, Footer } from '../../components';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export default class ViewCorrectContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect:null
    };
  }

  componentDidMount()
  {

    if (this.state.redirect || 
      !this.props.consumer?.groups || 
      this.props.consumer.groups.length == 0 ||
      !this.props.consumer.groups[0] ||
      !this.props.consumer.groups[0].subItems ||
      this.props.consumer.groups[0].subItems.length == 0) return;

    const uri = this.props.consumer.groups[0].subItems[0].uri;

    console.log(uri);
    this.setState({redirect:uri});
  }


  render() {
    const { redirect } = this.state;
    const { consumer } = this.props;
    const componentHeight = `100%`;

    if (redirect) return <Redirect to={redirect}/>

    return (
      <>
        <div className="rdp-admin-tex">
          <Header />
          <Sidebar
            currentRoute={consumer ? consumer.currentRoute : null}
            defineGroup={
              this.props.consumer ? this.props.consumer.defineGroup : null
            }
            defineRoute={
              this.props.consumer ? this.props.consumer.defineRoute : null
            }
            groups={this.props.consumer ? this.props.consumer.groups : null}
          />
          <Breadcrumb
            currentRoute={consumer ? consumer.currentRoute : null}
            defineRoute={consumer ? consumer.defineRoute : null}
          />
          <div id="rdp-admin-content-area" className="rdp-admin-content">

          </div>
          <Footer version="01" />
        </div>
      </>
    );
  }
}
