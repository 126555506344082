import React, { useState, useEffect, useMemo } from 'react';
import {
  Sidebar,
  Breadcrumb,
  Header,
  Footer,
  TableDynamic,
  MessageNoItemInTable,
} from '../../components';
import Loading from '../../components/loading';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';
import Sdk from 'api.digitalpages.module.sdk.api';
import Components from 'rdp-builder-components';
import { ContainerListSchema, ContainerTableSchema, Container } from './style';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

export default function ListEntities({ consumer, entity, history, match }) {
  const [schemas, setSchemas] = useState(null);
  const [activeSchema, setActiveSchema] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_title'),
      path: '/schema',
    });

    getSchemas();
  }, []);

  const getSchemas = async () => {
    const projectUid = Sdk.authorization.activeProject.uid;
    const data = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/schema/available/project/uid/${projectUid}`,
      null,
      'GET'
    );

    for (const schema of data) {
      const items = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/uid/${schema.uid}/items`
      );
      //todo: componente espera esse valor
      items.forEach(
        (item) => (item.data.title = item.data.name ?? item.data.nome)
      );
      schema.items = items;
    }

    setSchemas(data);
  };

  const newSchema = () => {
    const mockSchema = {
      name: textFromUid('default_new_schema_name'),
      type: 'Category',
      properties: [
        {
          name: 'name',
          type: 'Text',
          regex_validation: '',
        },
      ],
    };

    setActiveSchema(mockSchema);
  };

  const handleEditSchema = (schemaUid) => {
    var schema = schemas.find((schema) => schema.uid == schemaUid);
    if (!schema) return;

    setActiveSchema(schema);
  };

  const handleDeleteSchema = async (schemaUid) => {
    await toast.promise(
      Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${schemaUid}`,
        null,
        'DELETE'
      ),
      {
        pending: textFromUid('pending_message_schema_delete'),
        success: textFromUid('success_message_schema_delete'),
        error: textFromUid('error_message_schema_delete'),
      }
    );

    var schemaUpdated = schemas.filter((schema) => schema.uid != schemaUid);
    setSchemas(schemaUpdated);
  };

  const schemaFields = useMemo(() => {
    if (!schemas) return <Loading msg={textFromUid('loading_message')} />;
    if (schemas == 0)
      return <MessageNoItemInTable message="table_user_no_item" />;

    const orderFields = [
      'uid',
      'name',
      'scope',
      'type',
      'public',
      'self_register',
      'created_at',
      'updated_at',
    ];

    const paths = objectPaths(schemas[0], {
      order: orderFields,
      namePrefix: 'table_header_schema_',
    });
    return (
      <Container>
        <Components.Headers.TitleAndDescription
          title={textFromUid('schema_detail_title')}
          description={textFromUid('schema_detail_description')}
          padding={'0 20px'}
        />
        <TableDynamic
          headers={paths}
          defaultFields={[]}
          data={schemas}
          hasCheckbox={false}
          hasView={false}
          hasEdit={true}
          hasDelete={true}
          onUpdate={handleEditSchema}
          onDelete={handleDeleteSchema}
        />
      </Container>
    );
  }, [schemas]);

  return (
    <ContainerListSchema>
      <Header />
      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        customNewOptionTitle={textFromUid('button_new_schema')}
        newOption={newSchema}
      />
      <ContainerTableSchema>{schemaFields}</ContainerTableSchema>
    </ContainerListSchema>
  );
}
