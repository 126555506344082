import React, { useRef, useState } from 'react';
import { IconDownload } from '../../components/icon';
import LoadingSpinner from '../../components/loading/loading-spinner';

export const RegisterItemDownload = ({ url, register }) => {
  const [loading, setLoading] = useState(false);
  const frameRef = useRef();

  const getDocumentFrame = async () => {
    try {
      const frameDocument = await fetch(url);
      const htmlString = await frameDocument.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const iframeSrc = doc.querySelector('iframe').getAttribute('src');

      return iframeSrc;
    } catch (err) {
      return url;
    }
  };

  const createVirtualFrame = async () => {
    let frameSource = await getDocumentFrame();

    // frameSource = frameSource.replace(
    //   `https://api.digitalpages.com.br/product/template_player/c/uid/4cc6684df7/p/-/p/init?`,
    //   'http://localhost:3003/?'
    // );
    // tests

    const iframe = document.createElement('iframe');
    iframe.src = frameSource;
    iframe.style.width = '1000px';
    iframe.style.height = '100vh';
    iframe.style.position = 'absolute';
    iframe.style.visibility = 'hidden';
    iframe.id = register.uid;

    frameRef.current = iframe;
    return iframe;
  };

  const onDownloadCertificate = async () => {
    setLoading(true);
    const iframe = await createVirtualFrame();

    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow.postMessage(
        `RDPEvent:auto_download:${register.uid}`,
        '*'
      );
    };

    handleMessageListener();
  };

  const handleMessageCallback = (e) => {
    if (
      typeof e.data === 'string' &&
      e.data.indexOf(`RDPEvent:download_fulfilled:${register.uid}`) !== -1
    ) {
      document.body.removeChild(frameRef.current);
      window.removeEventListener('message', handleMessageCallback);
      frameRef.current = null;
      setLoading(false);
    }
  };

  const handleMessageListener = () => {
    window.addEventListener('message', handleMessageCallback);
  };

  return (
    <span
      className="button-download-certificate"
      onClick={() => onDownloadCertificate()}
    >
      {loading ? <LoadingSpinner /> : <IconDownload />}
    </span>
  );
};
