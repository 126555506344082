import React, { useRef } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import './styles.scss';

export default function Iframe({ src, onClickOutside, ...rest }) {
  const optionsRef = useRef();

  useOnClickOutside(optionsRef, () => onClickOutside());

  return (
    <iframe ref={optionsRef} className="iframe-content" src={src} {...rest} />
  );
}
