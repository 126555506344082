import React from 'react';
import { textFromUid } from '../../utils/textUtilities';
import { IconNone } from '../icon';

export default function MessageNoItemInTable({ message }) {
  return (
    <div className="rdp-admin-container-no-item-table">
      <span className="rdp-admin-container-icon-table-user">
        <IconNone />
      </span>
      <span className="rdp-admin-container-table-message-text">
        {textFromUid(message)}
      </span>
    </div>
  );
}
