import React, { useEffect, useState, useMemo } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  NotifyBox,
  Footer,
  Loading,
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import { textFromUid } from '../../utils/textUtilities';
import { objectToForm, toTreeObject } from '../../utils/formUtilities';

import Sdk from 'api.digitalpages.module.sdk.api';
import { toast } from 'react-toastify';

import './new-product-v2.scss';

export default function NewProductV2({ consumer, history }) {
  const [product, setProduct] = useState({
    name: 'name',
    currency: 'currency',
    amount: 'currency',
    unique_name: 'unique_name',
    installments_max: 'currency',
  });

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_new_product_title'),
      uri: '/product/new',
    });
  }, []);

  const isFrame = window.location.hash.includes('embed/');

  const updatedProduct = async (dataProduct, paymentConnector) => {
    const res = await Sdk.dynamic.bridge(
      `store/v1.0/product/connector/uid/${paymentConnector.uid}`,
      dataProduct,
      'POST'
    );

    history.push('/products');

    return res;
  };

  const handleCreateNewProduct = async (data) => {
    const { activeProject } = Sdk.Api.authorization;

    let connectors = activeProject.connectors;
    const paymentConnector = connectors.find((connector) =>
      connector.type.toLowerCase().includes('payment')
    );

    const productData = toTreeObject(data, null);

    const installmentsTax = [];

    Array.from(Array(parseInt(data?.installments_max)).keys()).forEach(
      (item, index) => {
        installmentsTax.push(data[`installment-${index + 1}`]);
      }
    );

    productData.installments_tax = installmentsTax;
    productData.type = 'NotConsumable';

    const productUpdated = await toast.promise(
      updatedProduct(productData, paymentConnector),
      {
        pending: textFromUid('pending_message_new_product_insert'),
        success: textFromUid('success_message_new_product_insert'),
        error: textFromUid('error_message_new_product_insert'),
      }
    );

    setProduct(productUpdated);
  };

  const productOptions = useMemo(() => {
    if (!product) return <Loading msg={textFromUid('loading_message')} />;

    const requiredFields = ['name', 'currency', 'amount', 'installments_max'];

    var objInstallmenst = {
      id: 'installments_max',
      group: textFromUid('new_product_installments'),
      type: 'input-installments',
      field: 'installments_max',
      required: true,
      config: {},
      placeholder: textFromUid('new_product_installments_placeholder'),
    };

    var options = objectToForm(product, {
      required: requiredFields,

      namePrefix: 'new_product_',

      handleOptions: (key) => {
        if (key === 'installments_max') return objInstallmenst;

        return null;
      },
    });

    return (
      <DynamicForm
        handleSubmit={handleCreateNewProduct}
        form={{ fields: options }}
        submitText={textFromUid('button_new_product')}
        defaultValues={{}}
      />
    );
  }, [product]);

  return (
    <div className="rdp-admin-list-content">
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="rdp-admin-content" style={{ 
        ...(isFrame && {
          left: 0,
          top: 50,
        })
      }}>{productOptions}</div>
    </div>
  );
}
