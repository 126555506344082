import React from 'react';
import './button-default.scss';

export default function ButtonDefault({ icon, text, onClick, disabled }) {
  return (
    <button className="register-button" onClick={onClick} disabled={disabled}>
      <span>{icon}</span>
      <div>{text}</div>
    </button>
  );
}
