import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  InputDefault,
  TableDynamic,
  InformationTooltip,
  NoResult,
  PageContainer,
  Pagination,
} from '../../components';

import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import DynamicForm from '../../components/form-dynamic-v2';
import Loading from '../../components/loading';

import {
  objectToForm,
  schemaInput,
  toTreeObject,
  objectPaths,
} from '../../utils/formUtilities';
import { textFromUid } from '../../utils/textUtilities';

import { toast } from 'react-toastify';
import Components from 'rdp-builder-components';
import Sdk from 'api.digitalpages.module.sdk.api';

import './styles.scss';
import { forIn } from 'lodash';
import { forEach } from 'jszip';
import { IconDownload, IconFilterArrowDown } from '../../components/icon';
import { UserRegisterList } from './register-list';

const Container = styled.div`
  // margin-top: 20px;
  width: 100%;
`;

export default function EditUser({ consumer, match, history }) {
  const [formIndex, setFormIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [projectAlias, setProjectAlias] = useState(null);
  const [registers, setRegisters] = useState(null);
  const [connectorLearning, setConnectorLearning] = useState(null);
  const [schemas, setSchemas] = useState(null);
  const [roles, setRoles] = useState(null);
  const [products, setProducts] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [connectorStore, setConnectorStore] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [openModalRoles, setOpenModalRoles] = useState(false);
  const [openModalNewTransaction, setOpenModalNewTransaction] = useState(false);
  const [linkUserProjects, setLinkUserProjects] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [userEntities, setUserEntities] = useState();
  const [passwordUser, setPasswordUser] = useState('');
  const [confirmPasswordUser, setConfirmPasswordUser] = useState('');
  const [activeRegister, setActiveRegister] = useState(null);
  const [selectRegistered, setSelectRegistered] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    loadEntities();
    loadRoles();
    loadProducts();
    loadTransactions();
  }, []);

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('user_title_screen'),
      path: 'users/:uid',
    });
    loadUserInfo(match.params.userUid);
  }, [match.params.userUid]);

  useEffect(() => {
    if (!user) return;

    loadUserEntities(user);
    loadRegistrations(user);
  }, [user, currentPage]);

  const loadEntities = async () => {
    const res = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/management/schema/available?type=security`,
      null,
      'GET'
    );
    const result = [];

    for (const [idx, entity] of res.entries()) {
      const schemaInfo = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}`,
        null,
        'GET'
      );
      const items = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}/items`,
        null,
        'GET'
      );

      items.sort((a, b) => a.row - b.row);
      schemaInfo.items = items;
      result.push(schemaInfo);
    }

    setSchemas(result);
  };

  const loadRegistrations = async (currentUser) => {
    if (!currentUser) return;

    setProcessing(true);
    var connectors = await Sdk.authorization.activeProject.connectors.filter(
      (connector) => connector.type == 'Learning_1'
    );

    if (connectors) {
      const initUser = currentUser;
      const registered = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/user/uid/${initUser.uid}/management/registers`
      );

      const available = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/user/uid/${initUser.uid}/management/registers/available?page=${currentPage}&page_size=50`
      );
      if (initUser != user) return;

      setCurrentPage(available.current_page);
      setTotalPages(available.total_pages);
      setTotalResults(available.total_results);

      setRegisters({
        registered: registered.result,
        available: available.result,
      });

      setProcessing(false);

      setConnectorLearning(connectors);
    }
  };

  const userIsRegister = (registerInfo) => {
    if (!registerInfo) return false;

    var register = registers.registered.find(
      (register) => register.uid == registerInfo.uid
    );

    return register != undefined;
  };

  const updateActiveRegister = (registerUid) => {
    let register = registers.available.find(
      (register) => register.uid == registerUid
    );

    if (!register) {
      register = registers.registered.find(
        (register) => register.uid == registerUid
      );
    }

    if (!register) return;

    setActiveRegister(register);
  };

  const loadUserInfo = async (uid) => {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.0/user/uid/${uid}/management?include_properties=true&include_projects=true`,
      null,
      'GET'
    );

    setUser(result);
  };

  const loadUserEntities = async (currentUser) => {
    const initUser = currentUser;
    const resp = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/items/available/from/user/uid/${initUser.uid}`,
      null,
      'GET'
    );
    const userEntities = await resp.map((item) => item.uid);

    if (initUser != user) return;

    setUserEntities(userEntities);
  };

  const loadRoles = async () => {
    const response = await Sdk.dynamic.bridge(`auth/v1.0/roles`, null, 'GET');

    setRoles(response);
  };

  const loadProducts = async () => {
    const response = await Sdk.dynamic.bridge(
      `store/v1.0/product/available`,
      null,
      'GET'
    );

    setProducts(response);
  };

  const loadTransactions = async () => {
    var connectors = await Sdk.authorization.activeProject.connectors.filter(
      (connector) => connector.service == 'Store'
    );

    if (connectors) {
      const response = await Sdk.dynamic.bridge(
        `store/v1.0/transaction/management/available?user_uid=${match.params.userUid}`,
        null,
        'GET'
      );

      setTransactions(response);
      setConnectorStore(connectors);
    }
  };

  const addUserRole = async (data) => {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.0/user/uid/${user.uid}/role`,
      data,
      'POST'
    );
  };

  const addUserProduct = async (data) => {
    var connectors = await Sdk.authorization.activeProject.connectors.filter(
      (connector) => connector.type == 'Payment_1'
    );

    for (const connector of connectors) {
      const result = await Sdk.dynamic.bridge(
        `store/v1.0/transaction/connector/uid/${connector.uid}/management`,
        data,
        'POST'
      );
    }
  };

  const removeUserRole = async (roleRemove) => {
    try {
      const result = await Sdk.dynamic.bridge(
        `auth/v1.0/user/uid/${user.uid}/role/uid/${roleRemove}`,
        null,
        'DELETE'
      );
    } catch (e) {}
  };

  const updateUser = async (data) => {
    const userResult = await Sdk.dynamic.bridge(
      `auth/v1.0/user/uid/${user.uid}/management`,
      data,
      'PUT'
    );

    return userResult;
  };

  const updatePassword = async (data) => {
    //TODO - Verificar retorno no SDKJS excedendo exception
    try {
      const resultPassword = await Sdk.dynamic.bridge(
        `auth/v1.0/user/uid/${user.uid}/reset/password`,
        data,
        'POST'
      );
    } catch (error) {}

    // return resultPassword;
  };

  const updateRegistration = async (register, acessStatus) => {
    //TODO - Verificar retorno no SDKJS excedendo exception

    if (userIsRegister(register)) {
      //todo: corrigir fluxo
      var userRegister = register;
      const resultRegistared = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${userRegister.register.uid}/user/uid/${user.uid}/management/register`,
        acessStatus,
        'PUT'
      );
    } else {
      const resultAvailable = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${register.uid}/user/uid/${user.uid}/management/register`,
        null,
        'POST'
      );
    }

    // return resultPassword;
  };

  const updateCourseCategories = async (formData) => {
    const entities = [];

    for (const schema in formData) {
      for (const entity in formData[schema]) {
        ///entity = UID
        ///entityValue = true |false
        const entityValue = formData[schema][entity];
        const userHasValue = userEntities.find(
          (uidEntity) => uidEntity == entity
        );

        var action = null;
        var actionLabel = null;

        if (entityValue === true && !userHasValue) {
          action = 'POST';
          actionLabel = 'insert';
        } else if (userHasValue && !entityValue) {
          actionLabel = 'delete';
          action = 'DELETE';
        }

        if (!action) continue;

        await toast.promise(
          Sdk.dynamic.bridge(
            `auth/v1.0/entity/uid/${entity}/member`,
            { user_uid: user.uid },
            action
          ),
          {
            pending: textFromUid(`pending_message_user_entity_${actionLabel}`),
            success: textFromUid(`success_message_user_entity_${actionLabel}`),
            error: textFromUid(`error_message_user_entity_${actionLabel}`),
          }
        );
      }
    }

    //opcional
    await loadUserEntities(user);
  };

  const handleUpdateUser = async (data) => {
    const userData = toTreeObject(data, user);

    userData.user_properties = [];

    for (var key in data) {
      if (
        key !== undefined &&
        key != 'uid' &&
        key != 'detail.first_name' &&
        key != 'detail.last_name' &&
        key != 'detail.name' &&
        key != 'login' &&
        key != 'detail.email_principal' &&
        key != 'detail.cpf' &&
        key != 'created_at' &&
        key != 'updated_at' &&
        key != 'pending_confirm' &&
        key != 'blocked_access'
      ) {
        userData.user_properties.push({
          name: key,
          value: data[key],
          type: 'text',
        });
      }
    }

    const userUpdated = await toast.promise(updateUser(userData), {
      pending: textFromUid('pending_message_user_info_update'),
      success: textFromUid('success_message_user_info_update'),
      error: textFromUid('error_message_user_info_update'),
    });

    setUser(userUpdated);
  };

  const handleUpdatePassword = async () => {
    if (passwordUser === confirmPasswordUser) {
      const data = { password: passwordUser };
      const passwordUpdated = await toast.promise(updatePassword(data), {
        pending: textFromUid('pending_message_user_password_update'),
        success: textFromUid('success_message_user_password_update'),
        error: textFromUid('error_message_user_password_update'),
      });
      setOpenModal(false);
    } else {
      toast.error(textFromUid('error_message_user_password_confirmation'));
    }
  };

  const handleUpdateRegistration = async () => {
    const acessStatus = {
      access_status: selectRegistered,
    };

    if (userIsRegister(activeRegister)) {
      const registerUpdate = await toast.promise(
        updateRegistration(activeRegister, acessStatus),
        {
          pending: textFromUid('pending_message_user_register_status_update'),
          success: textFromUid('success_message_user_register_status_update'),
          error: textFromUid('error_message_user_register_status_update'),
        }
      );
    } else {
      const registerUpdate = await toast.promise(
        updateRegistration(activeRegister),
        {
          pending: textFromUid('pending_message_user_register_insert'),
          success: textFromUid('success_message_user_register_insert'),
          error: textFromUid('error_message_user_register_insert'),
        }
      );
    }

    loadRegistrations(user);
  };

  const onPreviousButtonClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextButtonClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  const offerFileds = useMemo(() => {
    if (formIndex !== 2) return null;
    if (!registers) return <Loading msg={textFromUid('loading_message')} />;
    if (registers.available.length == 0) return <NoResult />;

    const order = [
      'course.thumb_url',
      'course.uid',
      'course.title',
      'uid',
      'name',
    ];

    const paths = objectPaths(registers.available[0], {
      namePrefix: 'table_header_register_',
      order: order,
      handleType: (key) => {
        if (key != 'thumb_url') return null;
        return 'image';
      },
    });

    return (
      <Container>
        <Components.Headers.TitleAndDescription
          title={textFromUid('user_edit_offers_available_title')}
          description={textFromUid('user_edit_offers_available_description')}
          padding="20px 0px 20px 0px"
        />
        <>
          {processing && <Loading msg={textFromUid('loading_message')} />}

          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={registers.available}
            hasCheckbox={false}
            hasEdit={true}
            onUpdate={(registerUid) => {
              updateActiveRegister(registerUid);
            }}
          />

          <Pagination
            currentPage={currentPage ? currentPage : 1}
            registerCount={totalPages}
            totalPages={totalPages}
            onPaginationButtonClick={(page) => handlePaginationClick(page)}
            onPreviousButtonClick={onPreviousButtonClick}
            onNextButtonClick={onNextButtonClick}
          />
        </>
      </Container>
    );
  }, [registers, formIndex, processing]);

  const registerFields = useMemo(() => {
    if (formIndex !== 3) return null;
    if (!registers) return <Loading msg={textFromUid('loading_message')} />;

    const order = [
      'uid',
      'access_status',
      'register_status',
      'score',
      'created_at',
      'course.uid',
      'course.title',
      'register.uid',
      'register.name',
    ];

    const paths = objectPaths(registers.registered[0], {
      order: order,
      namePrefix: 'table_header_user_register_',
    });

    var models = [];

    registers.registered.forEach((reger) => {
      var data = { ...reger };
      data.access_status = textFromUid(
        `register_access_status_${reger.access_status.toLocaleLowerCase()}`
      );
      data.register_status = textFromUid(
        `register_register_status_${reger.register_status.toLocaleLowerCase()}`
      );
      models.push(data);
    });

    return (
      <Container>
        <Components.Headers.TitleAndDescription
          title={textFromUid('user_edit_register_linked_title')}
          description={textFromUid('user_edit_register_linked_description')}
          padding="20px 0px 20px 0px"
        />

        {registers.registered.length == 0 && <NoResult />}
        {registers.registered.length > 0 && (
          <UserRegisterList models={models} paths={paths} onUpdate={updateActiveRegister} />
        )}
      </Container>
    );
  }, [registers, formIndex]);

  const transactionFields = useMemo(() => {
    if (formIndex !== 4) return null;
    if (!transactions) return <Loading msg={textFromUid('loading_message')} />;

    const ignoreAvailableFiels = [
      'pix_qrcode',
      'products',
      'ticket_url',
      'user_uid',
    ];

    const paths = objectPaths(transactions.result[0], {
      ignore: ignoreAvailableFiels,
      // order: order,
      namePrefix: 'table_header_user_transaction_',
    });

    const dataResultTransactions = transactions.result;

    return (
      <Container>
        <Components.Headers.TitleAndDescription
          title={textFromUid('user_edit_transactions')}
          description={textFromUid('user_edit_description_transactions')}
          padding="20px 0px 20px 0px"
        />
        {transactions.result.length == 0 && <NoResult />}
        {transactions.result.length > 0 && (
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={dataResultTransactions.map((result) => {
              return {
                ...result,
                status: textFromUid(
                  `transaction_status_${result.status.toLowerCase()}`
                ),
              };
            })}
            hasCheckbox={false}
          />
        )}
      </Container>
    );
  }, [transactions, formIndex]);

  const userOptions = useMemo(() => {
    if (formIndex !== 0) return null;
    if (!user) return <Loading msg={textFromUid('loading_message')} />;

    const requiredFields = [
      'login',
      'email_principal',
      'first_name',
      'last_name',
    ];

    const disabledUserFileds = ['uid', 'created_at', 'updated_at'];

    const order = [
      'uid',
      'detail.first_name',
      'detail.last_name',
      'detail.name',
      'login',
      'detail.email_principal',
      'detail.cpf',
      'created_at',
      'updated_at',
      'pending_confirm',
      'blocked_access',
    ];

    var options = objectToForm(user, {
      required: requiredFields,
      disabled: disabledUserFileds,
      order: order,
      informations: [],
      namePrefix: 'user_',
    });

    const currentValues = {};

    options.forEach(
      (field) => (currentValues[field?.id] = field?.currentValue)
    );

    const objPropDynamic = user.user_properties.reduce((obj, item) => {
      obj = {
        id: item?.name,
        required: true,
        originalName: 'name',
        informationText: ' ',
        type: 'input-text',
        currentValue: item?.value,
        group: item?.name,
        inputType: 'text',
        config: {},
        placeholder: '',
        options: [],
        required: false,
      };
      return obj;
    }, {});

    options.splice(7, 0, objPropDynamic);

    currentValues[objPropDynamic && objPropDynamic.id] =
      objPropDynamic && objPropDynamic?.currentValue;

    delete currentValues.undefined;

    const optionsField = options.filter(
      (objeto) => Object.keys(objeto).length !== 0
    );

    return (
      <div
        className={`rdp-manager-item ${
          formIndex === 0 ? 'rdp-manager-open' : null
        }`}
      >
        <Container>
          <Components.Headers.TitleAndDescription
            title={textFromUid('user_edit_information_title')}
            description={textFromUid('user_edit_information_description')}
            padding="20px 0px 20px 0px"
          />

          <DynamicForm
            handleSubmit={handleUpdateUser}
            form={{ fields: optionsField }}
            submitText={textFromUid('user_edit_information_button_update_user')}
            defaultValues={currentValues}
          />
        </Container>
      </div>
    );
  }, [user, formIndex]);

  const userEntitiesOptions = useMemo(() => {
    if (formIndex !== 1) return null;
    if (!schemas || !userEntities)
      return <Loading msg={textFromUid('loading_message')} />;

    const currentEntities = {};
    const entitiesFields = { fields: [] };

    for (const schmea of schemas) {
      entitiesFields.fields.push({
        id: `category-${schmea.uid}`,
        required: true,
        schema: schmea,
        type: 'input-schema-checkbox',
      });
    }

    if (schemas && userEntities) {
      schemas.forEach((schema) => {
        if (!schema.items) return;

        var values = [];

        schema.items.forEach((entity) => {
          if (userEntities.find((entityUid) => entityUid == entity.uid)) {
            values.push(entity.uid);
          }
        });
        currentEntities[schema.uid] = values;
      });
    }

    const paths = [
      {
        field: textFromUid('table_header_role_type'),
        id: 'type',
        identifier: 'type',
        path: 'type',
        type: 'text',
      },
      {
        field: textFromUid('table_header_role_type_description'),
        id: 'description',
        identifier: 'description',
        path: 'description',
        type: 'text',
      },
    ];

    return (
      <div
        className={`rdp-manager-item ${
          formIndex === 1 ? 'rdp-manager-open' : null
        }`}
      >
        <Container>
          <Components.Headers.TitleAndDescription
            title={textFromUid('user_groups_edit_roles_title')}
            description={textFromUid('user_groups_edit_roles_description')}
            padding="20px 0px 20px 0px"
          />

          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={user.roles.map((role) => {
              return {
                type: textFromUid(`role_type_${role.type.toLowerCase()}`),
                description: textFromUid(
                  `role_description_type_${role.type.toLowerCase()}`
                ),
              };
            })}
            hasCheckbox={false}
          />

          <Components.Headers.TitleAndDescription
            title={textFromUid('user_groups_edit_entities_title')}
            description={textFromUid('user_groups_edit_entities_description')}
            padding="20px 0px 20px 0px"
          />
          <DynamicForm
            form={entitiesFields}
            submitText={textFromUid('update')}
            handleSubmit={updateCourseCategories}
            defaultValues={currentEntities}
          />
        </Container>
      </div>
    );
  }, [schemas, userEntities, formIndex, user]);

  /// >>>>>>> User Projects

  const removeUserFromProject = async (projectUid) => {
    toast
      .promise(
        Sdk.dynamic.bridge(
          `auth/v1.1/project/uid/${projectUid}/management/register/user/uid/${user.uid}`,
          null,
          'DELETE'
        ),
        {
          pending: textFromUid('pending_message_user_remove_project'),
          success: textFromUid('success_message_user_remove_project'),
          error: textFromUid('error_message_user_remove_project'),
        }
      )
      .then(() => {
        var userUpdated = { ...user };
        userUpdated.projects = userUpdated.projects.filter(
          (p) => p.uid != projectUid
        );

        setUser(userUpdated);
      });
  };

  const projectSelect = (projectUid) => {
    var linkUpdated = [...linkUserProjects];

    if (linkUpdated.find((p) => p == projectUid)) {
      linkUpdated = linkUpdated.filter((p) => p != projectUid);
    } else {
      linkUpdated.push(projectUid);
    }

    setLinkUserProjects([...linkUpdated]);
  };

  const aliasProjectSelect = (projectUid) => {
    var alias = user.project_links.find((p) => p.project_uid == projectUid);
    alias.newLogin = alias.login;

    setProjectAlias(alias);
  };

  const updateProjectLinks = async () => {
    var userUpdated = { ...user };

    debugger;

    for (var projectUid of linkUserProjects) {
      var found = user.projects.find((p) => p.uid == projectUid);
      if (found) continue;

      try {
        await toast.promise(
          Sdk.dynamic.bridge(
            `/auth/v1.1/project/uid/${projectUid}/management/register/user/uid/${user.uid}`,
            null,
            'POST'
          ),
          {
            pending: textFromUid('pending_message_user_add_role'),
            success: textFromUid('success_message_user_add_role'),
            error: textFromUid('error_message_user_add_role'),
          }
        );

        var project = Sdk.authorization.projects.find(
          (p) => p.uid == projectUid
        );
        userUpdated.projects.push(project);
      } catch (e) {}
    }

    for (var project of userUpdated.projects) {
      var found = linkUserProjects.find((p) => p == project.uid);
      if (found) continue;

      try {
        await toast.promise(
          Sdk.dynamic.bridge(
            `/auth/v1.1/project/uid/${project.uid}/management/register/user/uid/${user.uid}`,
            null,
            'DELETE'
          ),
          {
            pending: textFromUid('pending_message_user_add_role'),
            success: textFromUid('success_message_user_add_role'),
            error: textFromUid('error_message_user_add_role'),
          }
        );
        userUpdated.projects = userUpdated.projects.filter(
          (p) => p.uid != project.uid
        );
      } catch (e) {}
    }

    setUser(userUpdated);
  };

  const updateProjectAlias = async () => {
    var data = {
      project_links: [
        { project_uid: projectAlias.project_uid, login_alias: projectAlias.newLogin },
      ],
    };

    var result = await toast.promise(
      Sdk.dynamic.bridge(
        `/auth/v1.1/user/uid/${user.uid}/management`,
        data,
        'PUT'
      ),
      {
        pending: textFromUid('pending_message_update_user_project'),
        success: textFromUid('success_message_update_user_project'),
        error: textFromUid('error_message_update_user_project'),
      }
    );

    var userUpdated = {
      ...user,
      project_links: result.project_links,
    };

    setUser(userUpdated);
    setProjectAlias(null);
  };

  const userProjects = useMemo(() => {
    if (formIndex !== 5) return null;
    if (!user) return <Loading msg={textFromUid('loading_message')} />;
    if (user.projects.length == 0) return <NoResult />;

    var data = user.projects.map((project) => {
      var rData = {
        uid: project.uid,
        name: project.name,
        login_alias: user.project_links.find((l) => l.project_uid == project.uid)
          ?.login_alias,
      };

      return rData;
    });

    const paths = [
      {
        field: textFromUid('table_header_project_uid'),
        id: 'uid',
        identifier: 'project_uid',
        path: 'uid',
        type: 'text',
      },
      {
        field: textFromUid('table_header_project_name'),
        id: 'name',
        identifier: 'name',
        path: 'name',
        type: 'text',
      },
      {
        field: textFromUid('table_header_project_login_alias'),
        id: 'login_alias',
        identifier: 'login_alias',
        path: 'login_alias',
        type: 'text',
      },
    ];

    return (
      <div>
        <Container>
          <Components.Headers.TitleAndDescription
            title={textFromUid('user_projects_edit_title')}
            description={textFromUid('user_projects_edit_description')}
            padding="20px 0px 20px 0px"
          />

          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={data}
            hasEdit={true}
            hasDelete={true}
            hasCheckbox={false}
            onDelete={removeUserFromProject}
            onUpdate={aliasProjectSelect}
          />
        </Container>
      </div>
    );
  }, [formIndex, user]);

  /// <<<<<<< UserProjects

  const closeModal = () => {
    setOpenModal(false);
    setActiveRegister(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const closeModalLogin = () => {
    setOpenModalLogin(false);
  };

  const handleOpenModalLogin = () => {
    setOpenModalLogin(true);
  };

  const closeModalRole = () => {
    setOpenModalRoles(false);
  };

  const closeModalTransaction = () => {
    setOpenModalNewTransaction(false);
  };

  const handleOpenModalRoles = () => {
    setSelectedRoles(user.roles.map((role) => role.type));
    setOpenModalRoles(true);
  };

  const handleOpenModalNewTransaction = () => {
    setOpenModalNewTransaction(true);
  };

  const openAppAsUser = async (appConfig) => {
    const res = await Sdk.dynamic.bridge(
      `auth/v1.0/user/uid/${user.uid}/management/impersonate`,
      null,
      'POST'
    );
    if (!res || !res.access_token) return;

    const url = `${appConfig.uri}?access_token=${res.access_token}&language=pt_br`;
    window.open(url);
  };

  const modalLoginAs = useMemo(() => {
    if (!openModalLogin) return null;

    const appgroup = consumer.groups.find((group) => group.type == 'apps');

    if (!appgroup) return null;

    return (
      <Components.Alerts.Modal
        onCloseClick={closeModalLogin}
        title={textFromUid('title_user_login')}
        closeIconColor="#764AB0"
        boxHeight="175px"
        boxWidth="460px"
      >
        <div className="rdp-admin-container-modal-impersonate">
          {appgroup.subItems.map((item, i) => (
            <Components.Buttons.Button
              key={`loginas-${i}`}
              marginBottom="5px"
              text={item.name}
              onClick={() => openAppAsUser(item)}
            />
          ))}
        </div>
      </Components.Alerts.Modal>
    );
  }, [openModalLogin]);

  const handleSaveRoles = async () => {
    const rolesActiveUsers = user.roles.map((role) => role.type);

    const insertRoles = selectedRoles.filter(
      (roleType) => rolesActiveUsers.includes(roleType) == false
    );

    const removeRoles = rolesActiveUsers.filter(
      (roleType) => selectedRoles.includes(roleType) == false
    );

    for (const roleInsert of insertRoles) {
      const userRolesUpdated = await toast.promise(
        addUserRole({ type: roleInsert }),
        {
          pending: textFromUid('pending_message_user_add_role'),
          success: textFromUid('success_message_user_add_role'),
          error: textFromUid('error_message_user_add_role'),
        }
      );
    }

    for (const roleRemove of removeRoles) {
      const currentRole = user.roles.find((role) => role.type == roleRemove);
      const userRolesUpdated = await toast.promise(
        removeUserRole(currentRole.uid),
        {
          pending: textFromUid('pending_message_user_remove_role'),
          success: textFromUid('success_message_user_remove_role'),
          error: textFromUid('error_message_user_remove_role'),
        }
      );
    }

    await loadUserInfo(user.uid);
  };

  const handleSaveProducts = async () => {
    let dataProducts = {
      user_uid: user.uid,
      products: selectedProducts,
    };

    const productRegistered = await toast.promise(
      addUserProduct(dataProducts),
      {
        pending: textFromUid('pending_message_user_add_products'),
        success: textFromUid('success_message_user_add_products'),
        error: textFromUid('error_message_user_add_products'),
      }
    );

    setOpenModalNewTransaction(false);
    await loadTransactions();
  };

  const rolesSelected = (uid) => {
    var index = selectedRoles.indexOf(uid);

    if (index !== -1) {
      selectedRoles.splice(index, 1);
    } else {
      selectedRoles.push(uid);
    }

    setSelectedRoles([...selectedRoles]);
  };

  const productsSelected = (uid) => 
  {
    var index = selectedProducts.indexOf(uid);
    var produtsResult = [...selectedProducts];

    if (index !== -1) {
      produtsResult.splice(index, 1);
    } else {
      produtsResult.push(uid);
    }
    setSelectedProducts(produtsResult);
  };

  const modalRole = useMemo(() => {
    if (!openModalRoles) return null;

    if (!roles) return null;

    var rolesSelect = user.roles.map((role) => role.type);

    var currentEntities = [];

    const paths = [
      {
        field: textFromUid('table_header_role_type'),
        id: 'title',
        identifier: 'title',
        path: 'title',
        type: 'text',
      },
      {
        field: textFromUid('table_header_role_type_description'),
        id: 'description',
        identifier: 'description',
        path: 'description',
        type: 'text',
      },
    ];

    const rolesData = roles.map((role) => {
      return {
        uid: role.type,
        title: textFromUid(`role_type_${role.type.toLowerCase()}`),
        description: textFromUid(
          `role_description_type_${role.type.toLowerCase()}`
        ),
      };
    });

    return (
      <Components.Alerts.Modal
        onCloseClick={closeModalRole}
        title={textFromUid('title_user_roles')}
        closeIconColor="#764AB0"
        maxHeight="80%"
        boxHeight="100%"
        boxWidth="60%"
        buttons={
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={handleSaveRoles}
            >
              {textFromUid('button_update_roles')}
            </ButtonDynamic>
          </>
        }
      >
        <TableDynamic
          headers={paths}
          defaultFields={rolesSelect}
          data={rolesData}
          hasCheckbox={true}
          onCheckboxClick={(uid) => rolesSelected(uid)}
        />
      </Components.Alerts.Modal>
    );
  }, [openModalRoles, roles, user]);

  const modalNewTransaction = useMemo(() => {
    if (!openModalNewTransaction) return false;

    const paths = [
      {
        field: textFromUid('table_header_product_uid'),
        id: 'uid',
        identifier: 'uid',
        path: 'uid',
        type: 'text',
      },
      {
        field: textFromUid('table_header_product_name'),
        id: 'name',
        identifier: 'name',
        path: 'name',
        type: 'text',
      },
      {
        field: textFromUid('table_header_product_uinique_name'),
        id: 'uniqueName',
        identifier: 'uniqueName',
        path: 'uniqueName',
        type: 'text',
      },
    ];

    const productsData = products.map((product) => {
      return {
        uid: product.uid,
        name: product.name,
        uniqueName: product.unique_name,
      };
    });

    return (
      <div>
        <Components.Alerts.Modal
          onCloseClick={closeModalTransaction}
          title={textFromUid('title_user_transaction')}
          closeIconColor="#764AB0"
          boxHeight={'80%'}
          boxWidth="800px"
          buttons={
            <>
              <ButtonDynamic
                color="blue"
                icon="plus"
                actionClick={handleSaveProducts}
              >
                {textFromUid('button_insert_transaction')}
              </ButtonDynamic>
            </>
          }
        >
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={productsData}
            hasCheckbox={true}
            onCheckboxClick={(uid) => productsSelected(uid)}
          />
        </Components.Alerts.Modal>
      </div>
    );
  }, [openModalNewTransaction, products, user, selectedProducts]);

  const modalProjects = useMemo(() => {
    if (linkUserProjects == null) return null;

    const paths = [
      {
        field: textFromUid('table_header_project_uid'),
        id: 'uid',
        identifier: 'uid',
        path: 'uid',
        type: 'text',
      },
      {
        field: textFromUid('table_header_project_name'),
        id: 'name',
        identifier: 'name',
        path: 'name',
        type: 'text',
      },
    ];

    const data = Sdk.Api.authorization.projects.map((project) => {
      return { uid: project.uid, name: project.name };
    });

    return (
      <Components.Alerts.Modal
        onCloseClick={() => setLinkUserProjects(null)}
        title={textFromUid('user_title_link_user_project')}
        closeIconColor="#764AB0"
        maxHeight="80%"
        boxHeight="100%"
        boxWidth="60%"
        buttons={
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={updateProjectLinks}
            >
              {textFromUid('button_link_user_project')}
            </ButtonDynamic>
          </>
        }
      >
        <TableDynamic
          headers={paths}
          defaultFields={linkUserProjects}
          data={data}
          hasCheckbox={true}
          onCheckboxClick={projectSelect}
        />
      </Components.Alerts.Modal>
    );
  }, [linkUserProjects]);

  const modalAlias = useMemo(() => {
    if (projectAlias == null) return null;

    var changed = projectAlias.login_alias != projectAlias.newLogin;

    return (
      <Components.Alerts.Modal
        onCloseClick={() => setProjectAlias(null)}
        title={textFromUid('user_edit_project_alias')}
        closeIconColor="#764AB0"
        maxHeight="21%"
        boxHeight="100%"
        boxWidth="50%"
        buttons={
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={updateProjectAlias}
              disabled={!changed}
            >
              {textFromUid('button_update_project_alias')}
            </ButtonDynamic>
          </>
        }
      >
        <InputDefault
          title={textFromUid('user_login_alias')}
          placeholder={projectAlias.login_alias}
          type={'text'}
          onChange={(event) => {
            var newValue = event.target.value;
            var newData = { ...projectAlias };

            if (newValue.length == 0) {
              newValue = newData.login_alias;
            }

            newData.newLogin = newValue;
            setProjectAlias(newData);
          }}
        />
      </Components.Alerts.Modal>
    );
  }, [projectAlias]);

  const registerAccessStatus = [
    'PendingPayment',
    'PaymentFailed',
    'Active',
    'Blocked',
    'Inactive',
  ];

  const topBarActions = useMemo(() => {
    var result = null;

    switch (formIndex) {
      case 0: {
        result = (
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={handleOpenModalLogin}
            >
              {textFromUid('button_login_as')}
            </ButtonDynamic>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={handleOpenModal}
              style={{ marginLeft: '10px' }}
            >
              {textFromUid('button_update_password')}
            </ButtonDynamic>
          </>
        );
        break;
      }
      case 1: {
        result = (
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={handleOpenModalRoles}
            >
              {textFromUid('button_update_roles')}
            </ButtonDynamic>
          </>
        );
        break;
      }
      case 4: {
        result = (
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={handleOpenModalNewTransaction}
            >
              {textFromUid('button_new_transaction')}
            </ButtonDynamic>
          </>
        );
        break;
      }
      case 3: {
        break;
      }
      case 5: {
        result = (
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={() => {
                var ids = user.projects.map((p) => p.uid);
                setLinkUserProjects(ids);
              }}
            >
              {textFromUid('button_link_project')}
            </ButtonDynamic>
          </>
        );
        break;
      }
    }

    return result;
  }, [formIndex, user]);

  var userIsRegistered = userIsRegister(activeRegister);

  return (
    <>
      {activeRegister && userIsRegistered && (
        <Components.Alerts.Modal
          onCloseClick={closeModal}
          actionLabel={textFromUid('modal_user_change_access_status_button')}
          onActionClick={() => handleUpdateRegistration()}
          title={textFromUid('modal_user_change_access_status_title')}
          closeIconColor="#764AB0"
          boxHeight="240px"
        >
          <div>
            <div> {textFromUid('modal_user_change_access_status_message')}</div>

            <div>
              <Components.Buttons.Select
                onChange={(event) => setSelectRegistered(event)}
                defaultValue={activeRegister.access_status}
                maxOptionsHeight={'90px'}
                items={registerAccessStatus.map((status) => {
                  return {
                    label: textFromUid(
                      `register_access_status_${status.toLocaleLowerCase()}`
                    ),
                    value: status,
                  };
                })}
              />
            </div>
          </div>
        </Components.Alerts.Modal>
      )}

      {activeRegister && !userIsRegistered && (
        <Components.Alerts.Modal
          onCloseClick={closeModal}
          actionLabel={textFromUid('modal_register_user_button')}
          onActionClick={() => handleUpdateRegistration()}
          title={textFromUid('modal_register_user_title')}
          closeIconColor="#764AB0"
          boxHeight="230px"
        >
          <div>{textFromUid('modal_register_user_message')}</div>
        </Components.Alerts.Modal>
      )}

      <div className="rdp-admin-edit-user">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
          {topBarActions}
        </Breadcrumb>
        {/* // newOption={handlebuttonBreadcrumb(formIndex)}
          // customNewOptionTitle={textbuttonBreadcrumb(formIndex)}
          // customTitleLoginAs={textFromUid('button_login_as')}
          // buttonLoginAs={formIndex === 0 && 'active' && handleOpenModalLogin}
        //> */}

        <PageContainer>
          <div className="form-buttons">
            <button
              className={`tab-button ${formIndex === 0 ? 'active' : null}`}
              onClick={() => setFormIndex(0)}
            >
              {textFromUid('tab_user_information')}
            </button>

            <button
              className={`tab-button ${formIndex === 5 ? 'active' : null}`}
              onClick={() => setFormIndex(5)}
            >
              {textFromUid('tab_user_projects')}
            </button>
            <button
              className={`tab-button ${formIndex === 1 ? 'active' : null}`}
              onClick={() => setFormIndex(1)}
            >
              {textFromUid('tab_user_groups')}
            </button>

            {connectorLearning && connectorLearning.length > 0 && (
              <button
                className={`tab-button ${formIndex === 2 ? 'active' : null}`}
                onClick={() => setFormIndex(2)}
              >
                {textFromUid('tab_user_offers')}
              </button>
            )}

            {connectorLearning && connectorLearning.length > 0 && (
              <button
                className={`tab-button ${formIndex === 3 ? 'active' : null}`}
                onClick={() => setFormIndex(3)}
              >
                {textFromUid('tab_user_registers')}
              </button>
            )}

            {connectorStore && connectorStore.length > 0 && (
              <button
                className={`tab-button ${formIndex === 4 ? 'active' : null}`}
                onClick={() => setFormIndex(4)}
              >
                {textFromUid('tab_user_transactions')}
              </button>
            )}
          </div>

          {userOptions}
          {userProjects}
          {userEntitiesOptions}
          {offerFileds}
          {registerFields}
          {transactionFields}
        </PageContainer>
      </div>
      {modalLoginAs}
      {modalRole}
      {modalNewTransaction}
      {openModal && (
      <Components.Alerts.Modal
        onCloseClick={closeModal}
        title={textFromUid('user_title_updated_password')}
        closeIconColor="#764AB0"
        boxHeight="280px"
        buttons={
          <>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={handleUpdatePassword}
            >
              {textFromUid('button_update_password')}
            </ButtonDynamic>
          </>
        }
      >
        <InputDefault
          title={textFromUid('user_new_password')}
          type={'password'}
          onChange={(event) => setPasswordUser(event.target.value)}
        />

        <InputDefault
          title={textFromUid('user_new_password_confirm')}
          type={'password'}
          onChange={(event) => setConfirmPasswordUser(event.target.value)}
        />
      </Components.Alerts.Modal>
      )}
      {modalProjects}
      {modalAlias}
    </>
  );
}
