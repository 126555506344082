import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';

export default function ListSubmission({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [totalPages, setTotalPages] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [previousPageQuery, setPreviousPageQuery] = useState(null)

  useEffect(() => {
    configTable();
    getRevision();

    consumer.defineRoute({ name: 'Submissão', uri: '/submission' });
  }, []);

  async function configTable() {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.submissions);
  }

  async function getRevision(query) {
    const { activeProject } = Sdk.Api.authorization;
    let _connector = null;
    let connector = null;

    const tmpStructureCp = { ...elements };
    let revision = [];

    if (activeProject && activeProject.connectors.length > 0) {
      _connector = activeProject.connectors;
    }

    connector = _connector && _connector[0] ? _connector[0].uid : null;

    var params = query ? `?${query}` : '';

    const result = await Sdk.dynamic.bridge(
      `/cms/v1.0/review/flow/uid/8b3be3a2-723e-451b-8051-08d9171aba15/management/tasks${params}`
    );

    console.log('result', result);

    revision = result.result;

    console.log('revision', revision);
    if (revision.length == 0) {
      console.log('Nenhuma revisao encontrada')
      setMessages({ noContent: 'Nenhuma transação registrada' });
      setPreloader(false)
    }
    revision.forEach(async (uidSchemas) => {
      tmpStructureCp.result = Array.isArray(tmpStructureCp.result)
        ? [...tmpStructureCp.result, { ...uidSchemas }]
        : [{ ...uidSchemas }];


    });

    tmpStructureCp.current_page = result.current_page;
    tmpStructureCp.page_count = result.total_pages;
    tmpStructureCp.page_size = revision.length;
    tmpStructureCp.row_count = result.total_results;

    setElements(tmpStructureCp);

    setPreloader(false);

    setCurrentPage(result.current_page);
    setTotalPages(result.total_pages);
    setNextQuery(result.next_page_query);

    setShowLoadMore(result.current_page < result.total_pages);
  }
  const handlePaginationClick = (page) => {

  }
  const onPreviousButtonClick = () => {
    console.log('inside onPreviousButtonClick')
    if (previousPageQuery) {
      getRevision(previousPageQuery)
      setPreviousPageQuery(null)
    }
  }
  const onNextButtonClick = () => {
    console.log('inside onNextButtonClick')
    if (nextQuery) {
      getRevision(nextQuery)
      setNextQuery(null)
    }
  }
  return (
    <>
      <GenericList
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        buttonDelete={false}
        loading={{
          preloader,
        }}
        isPaginated={true}
        onPaginationButtonClick={handlePaginationClick}
        onPreviousButtonClick={onPreviousButtonClick}
        onNextButtonClick={onNextButtonClick}
        // loadMoreBtnLabel={loadMoreBtnLabel}
        // showLoadMore={showLoadMore}
        // onLoadMoreClick={() => onLoadMoreClick()}
      />
      {deleting ? <Loading msg={config.loadingDeleteText} /> : null}
      <Footer />
    </>
  );
}
