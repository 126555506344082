import React, { useState, useEffect, useMemo } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';

import DynamicForm from '../../components/form-dynamic-v2';
import { Breadcrumb, Header, Loading, Sidebar } from '../../components';
import { textFromUid } from '../../utils/textUtilities';
import { objectToForm } from '../../utils/formUtilities';

import './edit-access-external.scss';
import { toast } from 'react-toastify';

export default function ViewEditAccessExternal({ consumer, match, history }) {
  const [schemas, setSchemas] = useState(null);
  const [projects, setProjects] = useState(null);
  const [external, setExternal] = useState({
    ip_range_filter: '',
    target_uid: '',
    type: '',
  });

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_edit_access_external'),
      path: 'external/:uid',
    });
  }, []);

  useEffect(() => {
    loadEntities();
    loadProjects();
  }, []);

  const loadEntities = async () => {
    const res = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/management/schema/available?type=security`,
      null,
      'GET'
    );

    const result = [];

    for (const [idx, entity] of res.entries()) {
      const schemaInfo = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}`,
        null,
        'GET'
      );
      const items = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}/items`,
        null,
        'GET'
      );

      items.sort((a, b) => a.row - b.row);
      schemaInfo.items = items;
      result.push(schemaInfo);
    }

    setSchemas(result);
  };

  const loadProjects = async () => {
    const { projects } = Sdk.authorization;

    setProjects(projects);
  };

  const handleSubmit = async (data) => {};

  const accessExternalOptions = useMemo(() => {
    if (!external) return <Loading msg={textFromUid('loading_message')} />;
    if (!schemas) return <Loading msg={textFromUid('loading_message')} />;
    if (!projects) return <Loading msg={textFromUid('loading_message')} />;

    const currentEntities = {};

    return (
      <>
        <DynamicForm
          handleSubmit={handleSubmit}
          form={{ fields: [] }}
          submitText={textFromUid('access_external_new_create')}
          defaultValues={currentEntities}
        />
      </>
    );
  }, [schemas, projects]);

  return (
    <div className="rdp-admin-edit-user">
      <Header />

      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />

      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />

      <div className="rdp-admin-content rdp-admin-component-form-dynamic">
        {accessExternalOptions}
      </div>
    </div>
  );
}
