import React, { useState, useEffect, useMemo, useRef } from 'react';
import csvToJson from 'convert-csv-to-json';
import {
  Sidebar,
  Breadcrumb,
  Header,
  Footer,
  TableDynamic,
  MessageNoItemInTable,
  Pagination,
  Filter,
  PageContainer,
  LoadingFullscreen,
  ButtonDefault
} from '../../components';
import Loading from '../../components/loading';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';
import Components from 'rdp-builder-components/lib/components';
import {
  ContainerNodeEntity,
  TabContainer,
  ContainerSearch,
  ContainerResultSearch,
  ContainerInput,
  ContainerListUser,
  ContainerButton,
  ContainerItens,
} from '../new-group-entity/styles';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import Sdk from 'api.digitalpages.module.sdk.api';
import { IconDisket, IconTrash } from '../../components/icon';

import { set } from 'lodash';
import { toast } from 'react-toastify';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';

export function ViewActiveProjectUserList(props) 
{
  return ViewDirectoryUserList({ ...props, onlyFromActiveProject:true })
}

export function ViewDirectoryUserList({ consumer, match, history, onlyFromActiveProject }) 
{
  const [uploadCsvUploading, setUploadCsvUploading] = useState(false);
  const [uploadCsvSuccess, setUploadCsvSuccess] = useState(false);
  const [uploadCsvError, setUploadCsvError] = useState(false);
  const [csvInputRef, setCsvInputRef] = useState(React.createRef());


  const [users, setUsers] = useState(null);
  const [usersFilter, setUsersFilter] = useState(null);
  const [usersSearch, setUsersSearch] = useState(null)
  const [redirectTo, setRedirectTo] = useState(null);
  const [lastRequest, setLastRequest] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const [previousPageQuery, setPreviousPageQuery] = useState(null);

  const [openModalMembers, setOpenModalMembers] = useState(false);
  const [wordEntered, setWordEntered] = useState('');
  const [listMembers, setListMembers] = useState([]);
  const [selectProjectUid, setSelectProjectUid] = useState(null);
  const [members, setMembers] = useState([]);
  const [userFiltered, setUserFiltered] = useState([]);



  const addMembers = async (data) => {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.0/project/uid/${selectProjectUid}/register/user/uid/${data.user_uid}`,
      data,
      'POST'
    );
  };

  const removeMembers = async (data, uidMember) => {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.1/entity/member/uid/${uidMember}/management`,
      data,
      'DELETE'
    );
  };

  const handleOpenModalMembers = () => {
    setOpenModalMembers(true);
  };

  const handleCloseModalMembers = () => {
    setOpenModalMembers(false);
    setListMembers([]);
  };

  const handleAddMember = async (uidListMember) => {
    for (const memberInsert of uidListMember) {
      const userMembersUpdated = await toast.promise(
        addMembers({ user_uid: memberInsert.uid }),
        {
          pending: textFromUid('pending_message_user_add_member'),
          success: textFromUid('success_message_user_add_member'),
          error: textFromUid('error_message_user_add_member'),
        }
      );

      setOpenModalMembers(false);
      setListMembers([]);
      getUsers();
    }
  };
  function getRouteNameFromPath(path) {
    const routeMappings = [
      { path: '/directory/users', name: textFromUid('menu_option_configs_directory_users') },
      { path: '/project/users', name: textFromUid('menu_option_configs_project_users') },
    ];
  
    const matchedRoute = routeMappings.find(route => route.path === path);
  
    return matchedRoute ? matchedRoute.name : null;
  }
  useEffect(() => {
    setOpenModalMembers(false);
    setSelectProjectUid(Sdk.authorization.activeProject.uid);
    
    getUsersMember();
    return () => setSelectProjectUid('');
  }, [selectProjectUid]);

  useEffect(() => {
    if (lastRequest) clearTimeout(lastRequest);
    setLastRequest(setTimeout(getUsersMember, 1000));
  }, [wordEntered]);

  useEffect(() => {
    const routeName = getRouteNameFromPath(match.path);
    if (routeName) {
      consumer.defineRoute({
        name: routeName,
        path: match.path,
      });
    }
  }, [match.path]);

  useEffect(()=>{
    setCurrentPage(1);
  }, [usersFilter, usersSearch])

  useEffect(()=>{

    if (lastRequest) clearTimeout(lastRequest);
    setLastRequest(setTimeout(getUsers, 1000))

  }, [currentPage, usersFilter, usersSearch]);


  const getUsersMember = async () => {
    let url = `auth/v1.1/directory/management/users?page_size=10`

    if (wordEntered) url += `&search=${wordEntered}`;
    const response = await Sdk.dynamic.bridge(url);

    setMembers(response.result);
  };

  const getUsers = async () => 
  {
    const project = Sdk.authorization.activeProject;
    if (!project) return;

    setProcessing(true);

    let url = `auth/v1.1/directory/management/users?page=${currentPage}&page_size=20`;
    
    if (onlyFromActiveProject) {
      url = `auth/v1.1/project/uid/${project.uid}/management/users?page=${currentPage}&page_size=20`;
    }

    if (usersFilter)
    {
      for (const filter in usersFilter) {
        const values = usersFilter[filter].map(fv=> `${filter}=${fv}`);
        url += `&${values.join("&")}`;
      }
    }


    if (usersSearch) url +=`&search=${usersSearch}`;
    const response = await Sdk.dynamic.bridge(url);

    setCurrentPage(response.current_page);
    setTotalPages(response.total_pages);
    setTotalResults(response.total_results);
    setUsers(response.result);
    setProcessing(false);
  };

  const importCsvOnClick = () => {
    csvInputRef.current.click();
  };

  const onRefreshUsers = () => {
    setUploadCsvUploading(false);
    setUploadCsvSuccess(false);
    
    setTimeout(getUsers, 50);
  };
  

  const onCsvError = () => {
    setUploadCsvUploading(false);
    setUploadCsvError(false);
  };

  const onCsvFileChange = (evt) => {
    var _this = this;

    //_this.setState({
    //  "upload_csv_uploading": true
    //})
    setUploadCsvUploading(true);

    var url = `${Sdk.Api.domain}/auth/v1.0/csv/import`;
    var fd = new FormData();
    var files = evt.currentTarget.files[0];
    fd.append('users.csv', files, "users.csv")

    $.ajax({
      url: url,
      type: "POST",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": `${Sdk.Api.authorization.credential.type} ${Sdk.Api.authorization.credential.accessToken}`,
        "Project-Key" : Sdk.Api.authorization.projectKey,
        "Api-Env" : Sdk.Api.enviroment
      },
      success: function (response) {
        console.log(response)

        setTimeout(function() {
          //_this.setState({
          //  "upload_csv_uploading": false,
          //  "upload_csv_success": true
          //})
          setUploadCsvUploading(false);
          setUploadCsvSuccess(true);

        }, 3000)
      },
      error: function (response) {
        console.log(response)

        setTimeout(function() {
          //_this.setState({
          //  "upload_csv_uploading": false,
          // "upload_csv_error": true
          //})
          setUploadCsvUploading(false);
          setUploadCsvError(true);
        }, 3000)
      }
    });
  };

  const newUser = () => {
    let redirectTo = `/users/new`;
    setRedirectTo(redirectTo);
  };

  const updatedUser = (uid) => {
    if (uid) {
      let redirectTo = `/users/${uid}`;

      setRedirectTo(redirectTo);
    }
  };

  const onPreviousButtonClick = () => {
    setCurrentPage(Math.max(1, currentPage-1));
  };

  const onNextButtonClick = () => {
    setCurrentPage(Math.min(totalPages, currentPage+1));
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(Math.max(0, Math.min(totalPages, page)));
  };



  const filterOptions = useMemo(() => {
    var filters = [
      {
        uid : "blocked_access",
        group : "blocked_access",
        label : textFromUid("user_field_blocked_access"),
        children : [
          {
            uid : "blocked",
            group : "true",
            label : textFromUid("default_true")
          },
          {
            uid : "nblocked",
            group : "false",
            label : textFromUid("default_false")
          }
        ]
      },
      {
        uid : "pending_confirm",
        group : "pending_confirm",
        label : textFromUid("user_field_pending_confirm"),
        children : [
          {
            uid : "blocked",
            group : "true",
            label : textFromUid("default_true")
          },
          {
            uid : "nblocked",
            group : "false",
            label : textFromUid("default_false")
          }
        ]
      }
    ]

    return (
    <Filter
      filtersValues={filters}
      onChange={(filterIds) => setUsersFilter(filterIds)}
      onSearch={(text) => setUsersSearch(text)}
    />);
  },[]);
  const modalAddMembers = useMemo(() => {
    if (!openModalMembers) return null;

    const newUsers = [...members];

    const handleFilterT = (event) => {
      const searchWord = event.target.value;
      setWordEntered(searchWord);
    };

    const clearInput = () => {
      setWordEntered('');
    };

    const addMembersList = (members) => {
      const membersCopy = [...listMembers];

      membersCopy.push(members);

      setListMembers(membersCopy);
      clearInput();
    };

    const removeMembersList = (index) => {
      const membersCopy = [...listMembers];

      membersCopy.splice(index, 1);
      setListMembers(membersCopy);
    };

    return (
      <Components.Alerts.Modal
        onCloseClick={handleCloseModalMembers}
        title={textFromUid('modal_title_add_member')}
        closeIconColor="#764AB0"
        maxHeight="52.875rem"
        boxHeight="53.125rem"
        boxWidth="46.125rem"
        buttons={
          <ButtonDynamic icon="plus" color="blue" actionClick={() => handleAddMember(listMembers)}>
            {textFromUid('button_save_members')}
          </ButtonDynamic>
        }
      >
        <>
          <ContainerSearch>
            <ContainerInput>
              <input
                placeholder={textFromUid('placeholder_search_user')}
                type="text"
                onChange={handleFilterT}
                value={wordEntered}
              />
            </ContainerInput>

            {members.length != 0 && wordEntered !== '' && (
              <ContainerResultSearch>
                {members.map((nameUser, key) => {
                  if (nameUser.detail.email_principal) {
                    return (
                      <div
                        key={nameUser.uid}
                        // onClick={async () => await handleAddMember(nameUser.uid)}
                        onClick={async () => await addMembersList(nameUser)}
                      >
                        <p>{nameUser.detail.email_principal}</p>
                      </div>
                    );
                  };
                })}
              </ContainerResultSearch>
            )}
          </ContainerSearch>

          <ContainerListUser>
            <ul>
              {listMembers.map((nameMember, index) => (
                <ContainerItens>
                  <li key={nameMember.uid}>{nameMember.detail.email_principal}</li>

                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      removeMembersList(index);
                    }}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#764AB0"
                        d="M5.16329 6.60098L0.211513 11.5528L1.44738 12.7887L6.39914 7.83687L11.5621 13L12.798 11.7641L7.63501 6.60098L13 1.23589L11.7641 0L6.39915 5.36509L1.23587 0.201656L0 1.43754L5.16329 6.60098Z"
                      ></path>
                    </svg>
                  </span>
                </ContainerItens>
              ))}
            </ul>
          </ContainerListUser>
        </>
      </Components.Alerts.Modal>
    );
  }, [openModalMembers, members, wordEntered, userFiltered, listMembers]);

  const usersFields = useMemo(() => 
  {
    if (!users) return <Loading msg={textFromUid('loading_message')} />;
    
    const orderFields = [
      'uid',
      'login',
      'detail.name',
      'pending_confirm',
      'blocked_access',
      'created_at',
      'updated_at',
    ];

    const paths = objectPaths(users[0], {
      order: orderFields,
      namePrefix: 'user_field_',
    });



    return (
      <>
        {filterOptions}
        {processing && <Loading msg={textFromUid('loading_message')} />}
        {users == 0 ?
          <MessageNoItemInTable message="table_user_no_item" />
        :
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={users}
            hasCheckbox={false}
            hasView={false}
            hasEdit={true}
            onUpdate={(userUid) => {
              updatedUser(userUid);
            }}
          />
        }

        <Pagination
          currentPage={currentPage ? currentPage : 1}
          registerCount={totalPages}
          totalPages={totalPages}
          onPaginationButtonClick={(page) => handlePaginationClick(page)}
          onPreviousButtonClick={onPreviousButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      </>
    );
  }, [users, processing]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <div className="rdp-manager-admin-csv-input-group">
        <input ref={csvInputRef} type="file" hidden className="rdp-manager-admin-csv-input" onChange={(e) => onCsvFileChange(e)}/>
      </div>
      <ContainerListUser>
        {modalAddMembers}
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          currentRoute={consumer ? consumer.currentRoute : null}
          newOption={newUser}
          customNewOptionTitle={textFromUid('button_new_user')}
          importCsvEnabled={true}
          importCsvOnClick={() => importCsvOnClick()}
          newMember={onlyFromActiveProject ? handleOpenModalMembers : null}
          customNewMemberTitle={onlyFromActiveProject ? textFromUid('button_add_new_member') : null}
        />

        <PageContainer>
            {usersFields}
        </PageContainer>

        {uploadCsvUploading &&
          <LoadingFullscreen
            renderType="uploading"
            uploadingMessage={"Realizando upload do CSV de usuários.<br>Aguarde alguns instantes..."}
          />
        }
        {uploadCsvSuccess &&
            <LoadingFullscreen
              renderType="success"
              successMessage="CSV de usuários importado com sucesso!"
              successBtnLabel="Atualizar listagem de usuários"
              successBtnOnClick={() => onRefreshUsers()}
            />
        }
        {uploadCsvError &&
          <LoadingFullscreen
            renderType="error"
            errorMessage="Erro na importação dos usuários.<br>Verifique se o arquivo CSV está correto."
            errorBtnLabel="Fechar e tentar novamente"
            errorBtnOnClick={() => onCsvError()}
          />
        }
      </ContainerListUser>
      
    </>
  );
}
