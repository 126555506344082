import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  ListRubric,
  NotifyBox,
  Loading,
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import { textFromUid } from '../../utils/textUtilities';

export default function ListEditRubric({ consumer, entity, history }) {
  const [redirectTo, setRedirectTo] = useState(null);
  const [dataRubric, setDataRubric] = useState();
  const [deleting, setDeleting] = useState(false);
  const [messagesSucess, setMessagesSucess] = useState('');
  const [messagesError, setMessagesError] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    loadTableRubric();
  }, []);

  const loadTableRubric = async (tableDataRubric) => {
    let tempRubrics = tableDataRubric;

    const tmpStructureCp = { ...entity };

    if (!tableDataRubric) {
      tempRubrics = await Sdk.dynamic.bridge(`cms/v1.0/review/rubrics`);
    }

    setDataRubric(tempRubrics);
  };

  const breadcrumb = {
    selectOptions: null,
    routeNew: '/rubric/new',
    label: 'Nova Rubrica',
    customNewOptionTitle: textFromUid('rubric_create'),
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const redirectToNew = () => {
    if (breadcrumb.routeNew) {
      if (typeof breadcrumb.routeNew === 'function') {
        breadcrumb.routeNew();
      } else {
        setRedirectTo(breadcrumb.routeNew);
      }
    }
  };

  const handleDelete = async (uid) => {
    setDeleting(true);

    try {
      if (uid) {
        const result = await Sdk.dynamic.bridge(
          `cms/v1.0/review/rubric/uid/${uid}/management`,
          null,
          'DELETE'
        );

        await loadTableRubric();

        setMessagesSucess('Rubrica deletada com sucesso');
      }
    } catch (error) {
      setMessagesError('Aconteceu erro ao deletar Rubrica');
    }

    setDeleting(false);
  };

  return (
    <>
      {true ? (
        <div className="rdp-admin-edit-tree">
          <Header />
          <Sidebar
            currentRoute={consumer ? consumer.currentRoute : null}
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb
            currentRoute={consumer ? consumer.currentRoute : null}
            currentGroup={consumer ? consumer.currentGroup : null}
            options={breadcrumb.selectOptions}
            newOption={breadcrumb.routeNew ? redirectToNew : null}
            label={breadcrumb.label}
            customNewOptionTitle={breadcrumb.customNewOptionTitle}
          />

          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {loadingSubmit && <Loading msg="Atualizando revisão..." />}
            {!loadingSubmit && messagesSucess && (
              <NotifyBox
                type="success"
                onClose={() => setMessagesSucess('')}
                message={messagesSucess}
              />
            )}
            {!loadingSubmit && messagesError && (
              <NotifyBox
                type="error"
                onClose={() => setMessagesError('')}
                message={messagesError}
              />
            )}

            {dataRubric ? (
              <ListRubric
                ItemRubric={dataRubric}
                editRubric
                handleDelete={(uid) => handleDelete(uid)}
              />
            ) : (
              <Loading msg="Carregando Rubricas..." />
            )}
          </div>
        </div>
      ) : (
        <Loading msg="Montando Rubricas..." />
      )}
      <Footer />
    </>
  );
}
