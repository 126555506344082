import React, { useState, useEffect, useMemo } from 'react';
import Components from 'rdp-builder-components';
import Sdk from 'api.digitalpages.module.sdk.api';
import { Redirect } from 'react-router-dom';

import {
  Breadcrumb,
  Header,
  Loading,
  MessageNoItemInTable,
  PageContainer,
  Sidebar,
  TableDynamic,
} from '../../components';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';
import { toast } from 'react-toastify';

import { Container, ContainerTableAccessExternal } from './style';

export default function ViewAccessExternalList({ consumer, match, history }) {
  const [redirectTo, setRedirectTo] = useState(null);
  const [accessExternal, setAccessExternal] = useState(null);

  useEffect(() => {
    getAccessExternal();
  }, []);

  const getAccessExternal = async () => {
    const { activeProject } = Sdk.Api.authorization;

    let connector = activeProject.connectors;

    let connectorDirectRegisterUid = await connector.filter(
      (connector) => connector.type === 'DirectRegister_1'
    )[0].uid;

    let response = await Sdk.dynamic.bridge(
      `auth/v1.1/direct/register/connector/uid/${connectorDirectRegisterUid}/management/context/access`,
      null,
      'GET'
    );

    console.log('response =>', response);

    setAccessExternal(response.result);
  };

  const deletAccessExternal = async (accessExternalUid) => {
    try {
      await toast.promise(
        Sdk.dynamic.bridge(
          `auth/v1.1/direct/register/management/context/access/uid/${accessExternalUid}`,
          null,
          'DELETE'
        )
      );
    } catch (error) {}
  };

  const handleDeleteAccessExternal = async (accessExternalUid) => {
    await toast.promise(deletAccessExternal(accessExternalUid), {
      pending: textFromUid('pending_message_access_external_delete'),
      success: textFromUid('success_message_access_external_delete'),
      error: textFromUid('error_message_access_external_delete'),
    });

    var accesExternalUpdated = accessExternal.filter(
      (acces) => acces.uid !== accessExternalUid
    );

    setAccessExternal(accesExternalUpdated);
  };

  //Todo funcao de ir para a tela de Atualização
  // const handleUpdatedAccessExternal = (uidAccessExternal) => {
  //   if (uidAccessExternal) {
  //     let redirectTo = `/external/${uidAccessExternal}`;

  //     setRedirectTo(redirectTo);
  //   }
  // };

  const accessExternalFields = useMemo(() => {
    if (!accessExternal)
      return <Loading msg={textFromUid('loading_message')} />;

    const orderFields = [
      'uid',
      'type',
      'ip_range_filter',
      'created_at',
      'updated_at',
    ];

    const paths = objectPaths(accessExternal[0], {
      order: orderFields,
      namePrefix: 'access_external_',
    });

    return (
      <>
        {accessExternal == 0 ? (
          <MessageNoItemInTable message="table_access_external_no_item" />
        ) : (
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={accessExternal}
            hasCheckbox={false}
            hasView={false}
            hasEdit={false}
            hasDelete={true}
            onDelete={(accesExternalUid) => {
              handleDeleteAccessExternal(accesExternalUid);
            }}
            // onUpdate={(accessExternalUid) => {
            //   handleUpdatedAccessExternal(accessExternalUid);
            // }}
          />
        )}
      </>
    );
  }, [accessExternal]);

  const handleViewNewAccessExternal = () => {
    let redirectTo = `/external/new`;
    setRedirectTo(redirectTo);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div>
      <Header />

      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />

      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        newOption={handleViewNewAccessExternal}
        customNewOptionTitle={textFromUid('button_new_user')}
      />

      <PageContainer>
        <Container>
          <Components.Headers.TitleAndDescription
            title={textFromUid('access_external_title')}
            description={textFromUid('access_external_description')}
            padding="0px 0px 20px 0px"
          />

          {accessExternalFields}
        </Container>
      </PageContainer>
    </div>
  );
}
