import styled from 'styled-components';

export const LoginContainer = styled.div`
    padding-left:20px;
    padding-right:20px;
`;

export const LoginMessage = styled.div`
    text-align: center;
    padding-bottom: 10px;
    color: #764ab0;
`
