import React, { Component } from 'react';
import { IconArrowLeft, IconArrowRight } from '../icon';
import './pagination.scss';

export default class Pagination extends Component {
  getPages(pages) {
    const buttons = [];
    for (let i = 0; i < pages; i++) {
      buttons.push(i + 1);
    }

    return buttons;
  }

  getPreviousPage(page) {
    if (page > 1) {
      return page - 1;
    }

    return 1;
  }

  getNextPage(page, totalPages) {
    const nextPage = page + 1;

    if (nextPage >= totalPages) {
      return totalPages;
    }

    return nextPage;
  }

  render() {
    const {
      currentPage,
      registerCount,
      totalPages,
      limit,
      onPaginationButtonClick,
      onPreviousButtonClick,
      onNextButtonClick,
    } = this.props;

    // const totalPages = Math.ceil(registerCount / limit);
    // let pages = this.getPages(totalPages);

    // if (currentPage > 2 && currentPage < totalPages - 10) {
    //   pages = pages.slice(currentPage - 2, 10 + (currentPage - 2));
    // } else if (currentPage >= totalPages - 10) {
    //   pages = pages.slice(totalPages - 10, totalPages);
    // } else {
    //   pages = pages.slice(0, 10);
    // }

    let length = totalPages - 1 + 1;

    var buttons = Array.from({ length }, (_, idx) => idx + 1);

    return (
      <div className="rdp-pagination">
        <ul>
          <li
            className={currentPage === 1 ? 'disabled' : null}
            onClick={() => onPreviousButtonClick()}
            disabled={currentPage === 1}
          >
            <IconArrowLeft />
          </li>

          {buttons.map((page, index) => {
            if (page > currentPage + 2 || page < currentPage - 2) {
              return;
            }

            var color = page == currentPage ? '#764ab0' : 'white';
            var colorText = page == currentPage ? 'white' : 'black';

            return (
              <li
                key={index}
                className="active"
                onClick={() => onPaginationButtonClick(index+1)}
                style={{
                  background: color,
                  color: colorText,
                }}
              >
                {page}
              </li>
            );
          })}

          <li
            className={currentPage === totalPages ? 'disabled' : null}
            onClick={() => onNextButtonClick()}
            disabled={currentPage === totalPages}
          >
            <IconArrowRight />
          </li>
        </ul>
      </div>
    );
  }
}
