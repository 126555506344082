import React, { useState, useEffect, useRef, useCallback } from 'react';
import { format, compareAsc } from 'date-fns';
import { WithContext as ReactTags } from 'react-tag-input';
import Switch from 'react-switch';
import Select from 'react-select';
import Components from 'rdp-builder-components';
import RDP_UTILS from '../../config/util';
import fileLogo from '../../assets/images/icons/disket-white.png';
import collapseArrow from '../../assets/images/icons/collapse-arrow.png';
import publishDisabled from '../../assets/images/icons/publish-disabled.png';
import Properties from '../properties/properties';
import './form-dynamic-v2.scss';
import parseEntityName from '../../utils/parseEntityName';
import { ButtonDefault, IconCheck } from '../';
import { textFromUid } from '../../utils/textUtilities';

// KeyCodes para o componente de tags
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const MultiCheckbox = ({ field, handleChange }) => {
  useEffect(() => {
    if (
      field.defaultValues &&
      field.defaultValues.length > 0 &&
      !!field.options
    ) {
      const targetOptions = field.options.filter((option) =>
        field.defaultValues.includes(option.uid)
      );
      targetOptions.forEach((option) => {
        if (typeof handleChange === 'function') handleChange(true, option.uid);
      });
    }
  }, []);

  const [open, setOpen] = useState(true);

  return (
    <div key={field.id} className="row">
      <div style={{ color: 'black' }}>
        <div className="row-header" onClick={(e) => setOpen(!open)}>
          <img
            style={{
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
            src={collapseArrow}
          />
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </div>
      </div>
      <div
        style={{ visibility: open ? 'visible' : 'visible' }}
        className={`checkbox-row ${
          !open ? 'checkbox-row-hide' : 'checkbox-row-show'
        }`}
      >
        {field.options.map((option) => (
          <Components.Buttons.Checkbox
            label={option.label}
            checkedColor="#764AB0"
            defaultChecked={
              field.defaultValues
                ? field.defaultValues.includes(option.uid)
                : false
            }
            onChange={(value, label, checked) =>
              handleChange(checked, option.uid)
            }
          />
        ))}
      </div>
    </div>
  );
};

const TreeCheckbox = ({ field, handleChange }) => {
  useEffect(() => {
    if (
      field.defaultValues &&
      field.defaultValues.length > 0 &&
      !!field.options
    ) {
      const targetOptions = field.options.filter((option) =>
        field.defaultValues.includes(option.uid)
      );
      targetOptions.forEach((option) => {
        if (typeof handleChange === 'function') handleChange(true, option.uid);
      });
    }
  }, []);

  const [open, setOpen] = useState(true);

  function renderChildren(array, schema) {
    let children = [];

    array.map((i) => {
      children.push(
        <li>
          <ul style={{ marginLeft: '15px' }}>
            <li style={{ marginTop: '5px' }}>
              <Components.Buttons.Checkbox
                label={
                  i.data[
                    schema.schema_properties && schema.schema_properties[0].name
                  ]
                }
                checkedColor="#764AB0"
                defaultChecked={
                  field.defaultValues
                    ? field.defaultValues.includes(i.uid)
                    : false
                }
                onChange={(value, label, checked) =>
                  handleChange(checked, i.uid)
                }
              />
            </li>
          </ul>
        </li>
      );
      if (i.childs && i.childs.length > 0) {
        children.push(
          <ul style={{ marginLeft: '15px' }}>
            {renderChildren(i.childs, schema)}
          </ul>
        );
      }
    });

    return <ul>{children}</ul>;
  }

  return (
    <div key={field.id} className="row">
      <div style={{ color: 'black' }}>
        <div className="row-header" onClick={(e) => setOpen(!open)}>
          <img
            style={{
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
            src={collapseArrow}
          />
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </div>
      </div>
      {open && (
        <div
          style={{ visibility: open ? 'visible' : 'visible' }}
          className={`checkbox-row ${
            !open ? 'checkbox-row-hide' : 'checkbox-row-show'
          }`}
        >
          <ul>
            {field.array
              ? field.array.map((item) => (
                  <>
                    <li style={{ marginTop: '5px' }}>
                      <Components.Buttons.Checkbox
                        label={
                          item.data[
                            field.schema_properties &&
                              field.schema_properties[0].name
                          ]
                        }
                        checkedColor="#764AB0"
                        defaultChecked={
                          field.defaultValues
                            ? field.defaultValues.includes(item.uid)
                            : false
                        }
                        onChange={(value, label, checked) =>
                          handleChange(checked, item.uid)
                        }
                      />
                    </li>
                    <li>
                      {item.childs &&
                        item.childs.length > 0 &&
                        renderChildren(item.childs, field)}
                    </li>
                  </>
                ))
              : field.options.map((item) => (
                  <>
                    <li style={{ marginTop: '5px' }}>
                      <Components.Buttons.Checkbox
                        label={
                          item.data[
                            field.schema_properties &&
                              field.schema_properties[0].name
                          ] ?? item.data.name
                        }
                        checkedColor="#764AB0"
                        defaultChecked={
                          field.defaultValues
                            ? field.defaultValues.includes(item.uid)
                            : false
                        }
                        onChange={(value, label, checked) =>
                          handleChange(checked, item.uid)
                        }
                      />
                    </li>
                    <li>
                      {item.childs &&
                        item.childs.length > 0 &&
                        renderChildren(item.childs, field)}
                    </li>
                  </>
                ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const dataFormmatted = (value) => {
  const date = new Date(value);
  const result = date.toDatetimeLocal();

  return result;
};
// (value, label, checked) => (
//   setInputs({ ...inputs, [field.id]: {...inputs[field.id], [option]: checked }})
//   )
export default function FormDynamicV2({
  handleChanges,
  handleSubmit,
  disabledButtonSubmit,
  handleSave,
  form,
  defaultValues,
  submitText,
  containerStyle = 'border',
  useRef,
}) {
  const [inputs, setInputs] = useState(defaultValues ? defaultValues : {});
  useEffect(() => {
    // const url = window.URL.createObjectURL(inputs[form.image.id])
    // console.log(url)
  }, []);

  useEffect(() => {
    if (!handleChanges) return;
    handleChanges(inputs);
  }, [inputs]);

  useEffect(() => {
    if (defaultValues === inputs) return;
    // const data = { ...defaultValues, ...inputs}
    setInputs(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    const formFields = [...form.fields];
    const rangeFields = [];
    formFields.forEach((field) => {
      if (field.id.includes('range')) {
        const today = new Date();
        const defaultStartDate = new Date();
        defaultStartDate.setDate(defaultStartDate.getDate() - 1);
        const defaultEndDate = new Date();
        defaultEndDate.setDate(defaultEndDate.getDate() + 1825);
        // console.log(
        //   format(defaultStartDate, 'yyyy-MM-dd') + 'T15:00Z',
        //   format(defaultStartDate, 'yyyy-MM-dd') + 'T15:00Z'
        // );
        const startDateId = field.start_field.id;
        const endDateId = field.end_field.id;

        if (!inputs[startDateId]) {
          setInputs((oldSt) => ({
            ...oldSt,
            [startDateId]: format(defaultStartDate, 'yyyy-MM-dd') + 'T15:00Z',
          }));
        }
        if (!inputs[endDateId]) {
          setInputs((oldSt) => ({
            ...oldSt,
            [endDateId]: format(defaultEndDate, 'yyyy-MM-dd') + 'T15:00Z',
          }));
        }
        // [endDateId]: format(defaultEndDate, 'yyyy-MM-dd') +'T15:00Z'
      }
    });
  }, []);

  const handleTagDelete = (tagId, index) => {
    setInputs((oldSt) => ({
      ...oldSt,
      [tagId]: oldSt[tagId].filter((tag, i) => i !== index),
    }));
  };

  const handleTagAddition = (tagId, tag) => {
    const tags = Array.isArray(inputs[tagId]) ? inputs[tagId] : [];
    setInputs((oldSt) => ({ ...oldSt, [tagId]: [...tags, tag] }));
  };

  const handleTagDrag = (tagId, tag, currPos, newPos) => {
    const tags = Array.isArray(inputs[tagId]) ? inputs[tagId] : [];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setInputs((oldSt) => ({ ...oldSt, [tagId]: newTags }));
  };

  const parseItemAccessStatus = (status) => {
    const statuses = {
      Undefined: 'Não definido',
      PendingPayment: 'Pagamento pendente',
      PaymentFailed: 'Pagamento falhou',
      Active: 'Ativo',
      Blocked: 'Bloqueado',
      Inactive: 'Inativo',
    };
    return statuses[status];
  };

  const getEntityPropertyFieldType = (type) => {
    const types = {
      Text: 'text',
      NumberInteger: 'number',
      NumberDecimal: 'number',
      DateTime: 'datetime-local',
    };
    return types[type];
  };

  const getInputTemplate = {
    'input-text': (field) => (
      <Components.Forms.InputText
        key={`input-text-${field.id}`}
        label={field.group}
        informationText={field.informationText}
        required={field.required}
        type={field.inputType ? field.inputType : 'text'}
        disabled={field.disabled}
        defaultValue={inputs && inputs[field.id] ? inputs[field.id] : ''}
        placeholder={field.placeholder}
        onChange={(evt) => {
          var value = evt.target ? evt.target.value : '';
          setInputs((old) => ({ ...old, [field.id]: value }));
        }}
        border="1px solid #d2d5dd"
        height="44px"
        fontColor="#000"
      />
    ),
    'input-timespan': (field) => {
      var currentValue =
        inputs && inputs[field.id] ? inputs[field.id] : '0.00:00:00';
      var components = currentValue.split(':');
      var values = [0, 0, 0, 0];

      if (components[0] != undefined) {
        var subcomponents = components[0].split('.');

        if (subcomponents.length > 1) {
          if (subcomponents[0] != undefined)
            values[0] = Math.max(0, Number.parseInt(subcomponents[0]));
          if (subcomponents[1] != undefined)
            values[1] = Math.max(0, Number.parseInt(subcomponents[1]));
        } else {
          if (subcomponents[0] != undefined)
            values[1] = Math.max(0, Number.parseInt(subcomponents[0]));
        }
      }

      values[2] =
        components[1] != undefined
          ? Math.max(0, Number.parseInt(components[1]))
          : 0;
      values[3] =
        components[2] != undefined
          ? Math.max(0, Number.parseInt(components[2]))
          : 0;

      function tostring(value) {
        if (value < 10) return `0${value}`;
        return value.toString();
      }

      function updateInput(index, value) {
        var currentValue = values[index];
        if (currentValue == value) return;

        values[index] = Math.max(value, 0);

        var timespam = `${tostring(values[0])}.${tostring(
          values[1]
        )}:${tostring(values[2])}:${tostring(values[3])}`;
        setInputs((old) => ({ ...old, [field.id]: timespam }));
      }

      return (
        <div>
          <div className="input-timespam-title">{field.group}</div>
          <div className="input-timespam-container">
            <Components.Forms.InputText
              key={`input-text-0-${field.id}`}
              label={textFromUid('default_days')}
              required={field.required}
              type="number"
              disabled={field.disabled}
              defaultValue={values[0]}
              placeholder={field.placeholder}
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : '0';
                updateInput(0, value);
              }}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
            />
            <div className="input-timespam-divider">:</div>
            <Components.Forms.InputText
              key={`input-text-1-${field.id}`}
              label={textFromUid('default_hours')}
              required={field.required}
              type="number"
              disabled={field.disabled}
              defaultValue={values[1]}
              placeholder={field.placeholder}
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : '0';
                updateInput(1, value);
              }}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
            />
            <div className="input-timespam-divider">:</div>
            <Components.Forms.InputText
              key={`input-text-2-${field.id}`}
              label={textFromUid('default_minutes')}
              required={field.required}
              type="number"
              disabled={field.disabled}
              defaultValue={values[2]}
              placeholder={field.placeholder}
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : '0';
                updateInput(2, value);
              }}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
            />
            <div className="input-timespam-divider">:</div>
            <Components.Forms.InputText
              key={`input-text-3-${field.id}`}
              label={textFromUid('default_seconds')}
              required={field.required}
              type="number"
              disabled={field.disabled}
              defaultValue={values[3]}
              placeholder={field.placeholder}
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : '0';
                updateInput(3, value);
              }}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
            />
          </div>
        </div>
      );
    },
    'input-number': (field) => (
      <Components.Forms.InputText
        key={`input-text-${field.id}`}
        label={field.group}
        informationText={field.informationText}
        required={field.required}
        type="number"
        disabled={field.disabled}
        defaultValue={
          inputs && inputs[field.id] != undefined ? inputs[field.id] : ''
        }
        placeholder={field.placeholder}
        onChange={(evt) => {
          var value = evt.target ? evt.target.value : 0;
          setInputs((old) => ({ ...old, [field.id]: value }));
        }}
        border="1px solid #d2d5dd"
        height="44px"
        fontColor="#000"
      />
    ),
    'input-installments': (field) => {
      var installments = parseInt(
        inputs && inputs[field.id] != undefined ? inputs[field.id] : 0
      );

      return (
        <div style={{ marginTop: '40px' }}>
          <div style={{ marginBottom: 12 }}>
            <Components.Forms.InputText
              key={`input-text-${field.id}`}
              label={field.group}
              informationText={field.informationText}
              required={field.required}
              type="number"
              disabled={field.disabled}
              defaultValue={
                inputs && inputs[field.id] != undefined ? inputs[field.id] : ''
              }
              placeholder={field.placeholder}
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : 0;
                setInputs((old) => {
                  var result = { ...old, [field.id]: value };
                  console.log(result);
                  return result;
                });
              }}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
            />
          </div>

          <div>
            {installments > 0 &&
              Array.from(Array(installments).keys()).map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: 20,
                    width: '25%',
                    display: 'inline-block',
                    paddingRight: '10px',
                  }}
                >
                  <label>{`Juros Parcela ${index + 1}`}</label>
                  <input
                    type="number"
                    className="text-input"
                    required={true}
                    value={
                      inputs[`installment-${index + 1}`] !== undefined
                        ? inputs[`installment-${index + 1}`]
                        : ''
                    }
                    onChange={(evt) => {
                      var value = evt.target ? evt.target.value : 0;
                      setInputs((oldSt) => ({
                        ...oldSt,
                        [`installment-${index + 1}`]: value,
                      }));
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      );
    },
    'input-timer': (field) => (
      <Components.Forms.InputText
        key={`input-text-${field.id}`}
        label={field.group}
        informationText={field.informationText}
        required={field.required}
        type={'time'}
        disabled={field.disabled}
        defaultValue={inputs && inputs[field.id] ? inputs[field.id] : ''}
        placeholder={field.placeholder}
        onChange={(evt) => {
          var value = evt.target ? evt.target.value : '';
          setInputs((old) => ({ ...old, [field.id]: value }));
        }}
        border="1px solid #d2d5dd"
        height="44px"
        fontColor="#000"
      />
    ),

    'input-select-small': (field) => (
      <div key={field.id} className="row">
        <Components.Buttons.Select
          key={`input-select-small-${field.id}`}
          title={field.group}
          items={field.options.map((option) => ({
            value: option,
            label: option,
          }))}
          onChange={(evt) => {
            var value = evt ? evt : '';
            setInputs((old) => ({ ...old, [field.id]: value }));
          }}
          width="298px"
        />
      </div>
    ),
    'input-select': (field) => (
      <div key={`input-select-${field.id}`} className="row">
        <p style={{ marginBottom: '0.7rem' }}>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </p>
        <Components.Buttons.Select
          borderColor="#d3d5dd"
          width="100%"
          size="small"
          title={field.group}
          visibleOptions={6}
          defaultValue={field.defaultValue}
          items={field.options.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          onChange={(evt) => {
            var value = evt ? evt : '';
            setInputs((old) => ({ ...old, [field.id]: value }));
          }}
        />
      </div>
    ),
    'input-option': (field) => (
      <div key={`input-option-${field.id}`}>
        <Components.Buttons.Option
          defaultValue={inputs && inputs[field.id] ? inputs[field.id] : ''}
          title={field.group}
          checkedColor="#764AB0"
          onChange={(evt) => {
            var value = evt.target ? evt.target.value : '';
            setInputs((old) => ({ ...old, [field.id]: value }));
          }}
          items={field.options.map((option) => ({
            value: option,
            label: option,
          }))}
        />
      </div>
    ),
    'input-option-v2': (field) => (
      <div style={{ marginTop: '20px' }} key={`input-options-v2-${field.id}`}>
        <Components.Buttons.Option
          defaultValue={inputs && inputs[field.id] ? inputs[field.id] : ''}
          title={field.group}
          informationText={field?.informationText}
          checkedColor="#764AB0"
          onChange={(evt) => {
            var value = evt.currentTarget ? evt.currentTarget.value : '';
            setInputs((old) => ({ ...old, [field.id]: value }));
          }}
          items={field.options.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
        />
      </div>
    ),
    'input-checkbox': (field) => (
      <div
        key={`input-checkbox-${field.id}`}
        style={{
          marginTop: '40px',
        }}
      >
        <Components.Buttons.Checkbox
          label={field.group}
          checkedColor="#764AB0"
          defaultChecked={
            field.defaultValues
              ? field.defaultValues.includes(field.uid)
              : false
          }
          onChange={(value, label, checked) =>
            setInputs((old) => ({ ...old, [field.id]: checked }))
          }
        />
      </div>
    ),
    'input-multi-checkbox': (field) => {
      //todo: corrigir render do componente quando estiver vazio
      if (field.data.length == 0) return null;
      return (
        <Components.Forms.InputMultiCheckbox
          key={`input-multi-checkbox-${field.id}`}
          name={field.group}
          data={field.data}
          informationText={field?.informationText}
          defaultValues={field.defaultValues ?? {}}
          icon={collapseArrow}
          handleChange={(status) => {
            setInputs((oldSt) => ({
              ...oldSt,
              [field.id]: status,
            }));
          }}
        />
      );
    },
    'input-tree-checkbox': (field, defaultValues = []) => (
      <TreeCheckbox
        key={`input-tree-checkbox-${field.id}`}
        field={field}
        defaultValues={defaultValues}
        handleChange={(checked, option) => {
          setInputs((oldSt) => ({
            ...oldSt,
            [field.id]: { ...oldSt[field.id], [option]: checked },
          }));
        }}
      />
    ),
    'input-schema-checkbox': (field) => {
      return (
        <Components.Forms.SchemaCheckbox
          key={`input-schema_checkbox-${field.id}`}
          schema={field.schema}
          defaultValues={defaultValues[field.schema.uid]}
          icon={collapseArrow}
          handleChange={(status) => {
            setInputs((oldSt) => ({
              ...oldSt,
              [field.schema.uid]: { ...status },
            }));
          }}
        />
      );
    },
    'input-textarea': (field) => (
      <div key={`input-textarea-${field.id}`} className="row">
        <label>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <textarea
            required={field.required}
            className="text-input"
            style={{ height: '200px', resize: 'none' }}
            value={
              inputs && inputs[field.id] != undefined ? inputs[field.id] : ''
            }
            onChange={(evt) => {
              var value = evt.target ? evt.target.value : '';
              setInputs((old) => ({ ...old, [field.id]: value }));
            }}
          >
            {field.placeholder}
          </textarea>
        </label>
      </div>
    ),
    'input-color-picker': (field) => (
      <div key={`input-color-picker-${field.id}`} className="row">
        <label>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <div className="color-picker-row">
            <span>
              {inputs[field.id] ? inputs[field.id] : 'Select a color'}
            </span>
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <input
                type="color"
                style={{
                  position: 'absolute',
                  width: '50px',
                  height: '50px',
                  border: 'none',
                  left: -15,
                  top: -15,
                  right: 0,
                  bottom: 0,
                }}
                value={
                  inputs && inputs[field.id] ? inputs[field.id] : '#BFBFBF'
                }
                onChange={(evt) =>
                  setInputs({ ...inputs, [field.id]: evt.target.value })
                }
              />
            </div>
          </div>
        </label>
      </div>
    ),
    'input-tree': (field) => (
      <div key={`input-tree-${field.id}`} style={{ marginTop: '120px' }}>
        <Components.Recursive.TreeEdit
          labelNode="Create node"
          labelActivity="Create activity"
          labelColor="#764ab0"
          nodeTitle="Title"
          nodeDescription="Description"
          nodeThumbnail="Thumbnail"
          activityTitle="Title"
          activityDescription="Description"
          items={[]}
          onSaveNode={(data) => console.log(data)}
          onSaveActivity={(data) => console.log(data)}
          handleDamClick={() => {}}
        />
      </div>
    ),
    'input-datetime': (field) => {
      return (
        <Components.Forms.InputText
          key={`input-datetime-${field.id}`}
          label={field.group}
          informationText={field?.informationText}
          required={field.required}
          type={field.inputType ? field.inputType : 'datetime-local'}
          disabled={field.disabled}
          defaultValue={inputs ? dataFormmatted(inputs[field.id]) : null}
          placeholder={field.placeholder}
          onChange={(evt) => {
            var value = evt.target ? evt.target.value : '';
            setInputs((old) => ({ ...old, [field.id]: value }));
          }}
          border="1px solid #d2d5dd"
          height="44px"
          fontColor="#000"
        />
      );
    },
    'input-datetime-range': (field) => (
      <div
        key={`input-datetime-range-${field.id}`}
        className="row date-time-range-container"
      >
        <label>
          {field.start_field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <input
            type="datetime-local"
            className="text-input date-time-range"
            required={field.start_field.required}
            value={
              inputs[field.start_field.id]
                ? inputs[field.start_field.id].replace('Z', '')
                : null
            }
            placeholder={field.start_field.placeholder}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.start_field.id]: evt.target.value })
            }
          />
        </label>
        <label>
          {field.end_field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <input
            type="datetime-local"
            className="text-input date-time-range"
            required={field.end_field.required}
            value={
              inputs[field.end_field.id]
                ? inputs[field.end_field.id].replace('Z', '')
                : null
            }
            placeholder={field.end_field.placeholder}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.end_field.id]: evt.target.value })
            }
          />
        </label>
      </div>
    ),

    'input-row': (row) => (
      <div
        key={`input-row-${row.id}`}
        className="row date-time-range-container"
      >
        {row.fields.map((field) => {
          if (!field.__id)
            field.__id = `${Math.random().toString(36).substr(2, 9)}`;

          return (
            <label key={field.__id}>
              {field.group}
              {field.required && <span style={{ color: 'red' }}>*</span>}
              <input
                type={field.inputType}
                className="text-input"
                required={field.required}
                value={inputs && inputs[field.id] ? inputs[field.id] : ''}
                placeholder={field.placeholder}
                onChange={(evt) => {
                  var value = evt.target ? evt.target.value : '';
                  setInputs((old) => ({ ...old, [field.id]: value }));
                }}
              />
            </label>
          );
        })}
      </div>
    ),
    switch: (field) => (
      // <div className="row">
      <div key={`input-switch-${field.id}`}>
        <p className="switch">{field.group}</p>
        <Switch
          checked={inputs && inputs[field.id] ? inputs[field.id] : false}
          onChange={(e) => setInputs({ ...inputs, [field.id]: e })}
          onColor="#4cbf69"
          onHandleColor="#FFF"
          handleDiameter={23}
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={48}
          className="react-switch"
          key={'field-' + field.identifier}
          id={'field-' + field.identifier}
        />
      </div>
    ),
    'input-tags': (field) => (
      <div key={`input-tags-${field.id}`} className="tag-input-container">
        <div style={{ marginBottom: 12 }}>
          <label>
            {field.group}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </label>
        </div>
        <ReactTags
          tags={inputs && inputs[field.id] ? inputs[field.id] : []}
          handleDelete={(index) => {
            handleTagDelete(field.id, index);
          }}
          placeholder="Pressione Enter para adicionar novas tags"
          handleAddition={(tag) => handleTagAddition(field.id, tag)}
          handleDrag={(tag, currPos, newPos) =>
            handleTagDrag(field.id, tag, currPos, newPos)
          }
          delimiters={delimiters}
        />
      </div>
    ),
    'input-properties': (field) => {
      return (
        <div style={{ marginTop: '40px' }} key={`input-properties-${field.id}`}>
          <div style={{ marginBottom: 12 }}>
            <label>
              {field.group}
              {field.required && <span style={{ color: 'red' }}>*</span>}
            </label>
          </div>
          <Properties
            onChange={(properties) =>
              setInputs((oldSt) => ({ ...oldSt, [field.id]: properties }))
            }
          />
        </div>
      );
    },

    'input-entity-properties': (field) => {
      return (
        <div key={`input-entity-properties-${field.id}`}>
          {field.options &&
            field.options.map((option) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '20px',
                }}
              >
                <label>
                  {parseEntityName(option.name) || option.name}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  key={option.name}
                  value={
                    inputs[`property-${option.name}`]
                      ? inputs[`property-${option.name}`]
                      : ''
                  }
                  onChange={(e) => {
                    const textValue = e.target.value;
                    setInputs((oldSt) => ({
                      ...oldSt,
                      [`property-${option.name}`]: textValue,
                    }));
                  }}
                  type={getEntityPropertyFieldType(option.type)}
                  className="text-input"
                  style={{ width: '30%' }}
                />
              </div>
            ))}
        </div>
      );
    },
    'input-tree-list': (field) => (
      <div style={{ marginTop: '40px' }} key={`input-tree-list-${field.id}`}>
        <div style={{ marginBottom: 12 }}>
          <label>
            {field.group}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </label>
        </div>
        <table className="edit-user-registers-table" style={{ padding: '8px' }}>
          <tr>
            <th>ID</th>
            <th>Nome da Oferta</th>
            <th>Nome do Curso</th>
            <th>Status Matrícula</th>
            <th>Descrição</th>
            <th>Criado em</th>
            <th>Matrícula</th>
          </tr>
          {field.items &&
            field.items.map((item) => (
              <tr key={item.uid}>
                <td>{item.uid}</td>
                <td>{item.name || item.register.name}</td>
                <td>{item.course.title}</td>
                <td
                  style={{
                    background: !item.access_status ? '#ddd' : 'inherit',
                  }}
                >
                  {parseItemAccessStatus(item.access_status) || ''}
                </td>
                <td>{item.description || item.register.description}</td>
                <td>{item.created_at}</td>
                <td>
                  <select
                    onChange={(e) => {
                      if (!e.target.value) return;
                      setInputs({
                        ...inputs,
                        'container-registers': {
                          ...inputs['container-registers'],
                          [item.register ? item.register.uid : item.uid]:
                            e.target.value,
                        },
                      });
                    }}
                    value={
                      inputs['container-registers']
                        ? inputs['container-registers'][item.uid]
                        : item.access_status
                    }
                    style={{ paddingRight: 12 }}
                  >
                    <option value="-">Sem Matrícula</option>
                    {item.access_status && (
                      <option value="Blocked">Cancelado (admin)</option>
                    )}
                    <option value="Active">Inscrito</option>
                  </select>
                </td>
              </tr>
            ))}
        </table>
      </div>
    ),
  };

  const renderSaveIcon = () => (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4155 6.57524H12.0291C11.9535 6.57086 11.8797 6.55032 11.8127 6.51501C11.7457 6.4797 11.687 6.43044 11.6407 6.37055C11.5943 6.31066 11.5613 6.24153 11.5439 6.16781C11.5265 6.0941 11.5251 6.01751 11.5398 5.9432V2.88495C11.5342 2.74872 11.5824 2.61575 11.6739 2.51469C11.7654 2.41363 11.893 2.35257 12.0291 2.34466H13.4155C13.4875 2.34698 13.5583 2.36424 13.6233 2.39535C13.6883 2.42646 13.7461 2.47074 13.793 2.52538C13.84 2.58001 13.8751 2.64381 13.8961 2.71273C13.9171 2.78165 13.9236 2.85419 13.915 2.92573V6.03495C13.9179 6.17215 13.8675 6.30512 13.7743 6.40588C13.6811 6.50665 13.5525 6.56736 13.4155 6.57524Z"
        fill="#764AB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.065 0.224272L20.7859 3.93495C20.9212 4.08265 20.9974 4.27497 21 4.47524V18.8388C20.9973 19.4112 20.7688 19.9593 20.364 20.364C19.9593 20.7688 19.4112 20.9973 18.8388 21H2.16117C1.58799 21 1.03829 20.7723 0.632991 20.367C0.227694 19.9617 0 19.412 0 18.8388V2.16117C0 1.58799 0.227694 1.03829 0.632991 0.632991C1.03829 0.227694 1.58799 0 2.16117 0H16.5248C16.7275 0.000178337 16.9218 0.0808477 17.065 0.224272ZM16.7083 19.4607V11.7335H4.32233V19.4607H16.7083ZM17.483 10.1942C17.6857 10.1944 17.8801 10.275 18.0233 10.4184H17.9927C18.1202 10.5728 18.1856 10.769 18.1762 10.9689V19.4607H18.7981C18.963 19.4607 19.1211 19.3952 19.2378 19.2785C19.3544 19.1619 19.4199 19.0038 19.4199 18.8388V4.75049L16.3616 1.69223V6.78932C16.363 7.07265 16.3083 7.35345 16.2009 7.61559C16.0934 7.87774 15.9351 8.11606 15.7353 8.31688C15.5354 8.5177 15.2978 8.67705 15.0362 8.78578C14.7745 8.89452 14.494 8.95049 14.2107 8.95049H5.86165C5.57832 8.95049 5.29778 8.89452 5.03615 8.78578C4.77451 8.67705 4.53694 8.5177 4.33707 8.31688C4.1372 8.11606 3.97897 7.87774 3.87148 7.61559C3.76398 7.35345 3.70934 7.07265 3.71068 6.78932V1.53932H2.16117C1.99624 1.53932 1.83807 1.60484 1.72145 1.72145C1.60484 1.83807 1.53932 1.99624 1.53932 2.16117V18.8388C1.53932 19.0038 1.60484 19.1619 1.72145 19.2785C1.83807 19.3952 1.99624 19.4607 2.16117 19.4607H2.78301V10.9587C2.78301 10.756 2.86356 10.5615 3.00694 10.4181C3.15033 10.2747 3.3448 10.1942 3.54757 10.1942H17.483ZM14.681 7.22903C14.7976 7.11241 14.8631 6.95424 14.8631 6.78932L14.8325 1.53932H5.25V6.78932C5.24865 6.8705 5.26347 6.95113 5.2936 7.02652C5.32373 7.10191 5.36857 7.17055 5.4255 7.22843C5.48244 7.28631 5.55032 7.33228 5.6252 7.36365C5.70008 7.39502 5.78046 7.41118 5.86165 7.41117H14.2413C14.4062 7.41117 14.5644 7.34565 14.681 7.22903Z"
        fill="#764AB0"
      />
    </svg>
  );

  const renderCheckIcon = () => {
    return (
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM15.768 9.64C15.8558 9.53964 15.9226 9.42274 15.9646 9.29617C16.0065 9.1696 16.0227 9.03591 16.0123 8.90298C16.0018 8.77005 15.9648 8.64056 15.9036 8.52213C15.8423 8.40369 15.758 8.29871 15.6555 8.21334C15.5531 8.12798 15.4346 8.06396 15.3071 8.02506C15.1796 7.98616 15.0455 7.97316 14.9129 7.98683C14.7802 8.00049 14.6517 8.04055 14.5347 8.10463C14.4178 8.16872 14.3149 8.25554 14.232 8.36L9.932 13.519L7.707 11.293C7.5184 11.1108 7.2658 11.01 7.0036 11.0123C6.7414 11.0146 6.49059 11.1198 6.30518 11.3052C6.11977 11.4906 6.0146 11.7414 6.01233 12.0036C6.01005 12.2658 6.11084 12.5184 6.293 12.707L9.293 15.707C9.39126 15.8052 9.50889 15.8818 9.63842 15.9321C9.76794 15.9823 9.9065 16.005 10.0453 15.9986C10.184 15.9923 10.32 15.9572 10.4444 15.8954C10.5688 15.8337 10.6791 15.7467 10.768 15.64L15.768 9.64Z"
          fill="white"
        />
      </svg>
    );
  };

  const renderUpperSection = () => {
    const formUpperSection = form.fields.slice(0, 3);

    return (
      <div className="upper-section-wrapper">
        <section className="upper-section-input-wrapper">
          {formUpperSection.map((field) => {
            if (field.__id == undefined)
              field.__id = `${Math.random().toString(36).substr(2, 9)}`;
            if (typeof getInputTemplate[field.type] === 'function') {
              const renderField = getInputTemplate[field.type];
              const fieldResult = renderField(field);
              return fieldResult;
            }
          })}
        </section>
        {form.image && (
          <section className="photo-container">
            <p>{form.image.title}</p>
            <Components.Forms.Dropzone
              title="Image Upload"
              titleSize="small1"
              width="300px"
              placeholderBorderType="dashed"
              placeholderIconWidth="25px"
              iconColor="#5F6368"
              buttonText={form.image.label || 'Select a File'}
              buttonFontSize="small1"
              buttonBorderRadius="5px"
              buttonFontColor="#764AB0"
              buttonColor="#764AB0"
              // defaultImage={
              //   inputs[form.image.id] ? inputs[form.image.id] : null
              // }
              onChange={(e) => {
                // onThumbSelect(e.target.files[0]);
                setInputs({ ...inputs, [form.image.id]: e.target.files[0] });
              }} //(e)=>{console.log(e.target.files[0])}
              bordered
            />
          </section>
        )}
      </div>
    );
  };

  const typeComponent = useCallback(
    (field) => {
      const test = getInputTemplate[field.type];

      if (typeof test === 'function') {
        const renderField = getInputTemplate[field.type];
        return renderField(field);
      }

      return null;
    },
    [defaultValues, inputs]
  );

  const renderLowerSection = () => {
    const formLowerSection = form.fields.slice(3);

    if (formLowerSection.length == 0) return null;

    return (
      <div className="row lower-section-wrapper">
        {formLowerSection.map((field) => typeComponent(field))}
      </div>
    );
  };

  return (
    <form
      className="dynamic-form-root"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(inputs, setInputs);
      }}
    >
      <main className={containerStyle}>
        {renderUpperSection()}
        {renderLowerSection()}
      </main>
      <footer className="dynamic-form-footer">
        {/* <button 
          onClick={(e) => {
            e.preventDefault();
            handleSave(inputs, form.image);
          }}
          className="save-button"
        >
          {renderSaveIcon()}
        </button> */}

        {handleSubmit && (
          <ButtonDefault
            text={submitText}
            icon={<IconCheck />}
            disabled={disabledButtonSubmit}
          />
        )}
      </footer>
    </form>
  );
}
