import React, { useState, useEffect, useMemo } from 'react';
import { Header, Sidebar, Breadcrumb, Loading, PageContainer } from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import Sdk from 'api.digitalpages.module.sdk.api';
import { objectToForm, toTreeObject } from '../../utils/formUtilities';
import { textFromUid } from '../../utils/textUtilities';
import { toast } from 'react-toastify';
import './new-schema-project.scss';
import Components from 'rdp-builder-components/lib/components';
import { Redirect } from 'react-router-dom';

export default function NewSchemaProject({ consumer, match, history }) {
  const [redirectTo, setRedirectTo] = useState(null);
  const [schema, setSchema] = useState({
    name: '',
    type: '',
    limit_depth_in_hierarchy: null,
    properties: [],
    tags: [],
  });

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_new_project'),
      path: 'schema/new/project',
    });
  }, []);

  const handleSubmit = async (data) => {
   
    let url =  `auth/v1.1/entity/schema/directory/management`; 

    if (data.scope == 'Project')
    { 
      const projectUid = Sdk.authorization.activeProject.uid;
      url = `auth/v1.1/entity/schema/project/uid/${projectUid}/management`;
    }

    const res = await toast.promise(
      Sdk.dynamic.bridge(url, data, 'POST'),
      {
        pending: textFromUid('success_message_schema_create'),
        success: textFromUid('pending_message_schema_create'),
        error: textFromUid('error_message_schema_create'),
      }
    );

    if (!res) return;

    setRedirectTo(`/group/${res.uid}`);
  };

  const schemaOptions = useMemo(() => {
    if (!schema) return <Loading msg={textFromUid('loading_message')} />;

    var scopeOptions = {
      id: 'scope',
      group: textFromUid('schema_scope'),
      required: true,
      type: 'input-select',
      config: {},
      options: [
        {
          value: 'Project',
          label: textFromUid('schema_scope_project'),
        },
      ],
      defaultValue: 'Category',
      field: 'type',
      placeholder: 'Digite o tipo',
    };

    if (Sdk.hasRole(['AdministratorGlobal']))
    {
      scopeOptions.options.push({
        value: 'Directory',
        label: textFromUid('schema_scope_directory'),
      });
    }

    var schemaObj = [
      {
        id: 'name',
        group: textFromUid('schema_name'),
        required: true,
        type: 'input-text',
        config: {},
        field: 'name',
        placeholder: 'Digite o nome',
      },
      scopeOptions,
      {
        id: 'type',
        group: textFromUid('schema_type'),
        required: true,
        type: 'input-select',
        config: {},
        options: [
          {
            value: 'Category',
            label: textFromUid('schema_option_category'),
          },
          {
            value: 'Security',
            label: textFromUid('schema_option_security'),
          },
        ],
        defaultValue: 'Category',
        field: 'type',
        placeholder: 'Digite o tipo',
      },
      {
        id: 'limit_depth_in_hierarchy',
        group: textFromUid('schema_limit_depth_in_hierarchy'),
        type: 'input-text',
        inputType: 'number',
        config: {},
        field: 'limit_depth_in_hierarchy',
      },
      {
        id: 'public',
        group: textFromUid('schema_public'),
        type: 'input-option-v2',
        inputType: 'boolean',
        options: [
          {
            value: 'true',
            label: textFromUid('true'),
          },
          {
            value: 'false',
            label: textFromUid('false'),
          },
        ],
        config: {},
        field: 'public',
      },
      {
        id: 'properties',
        group: textFromUid('schema_properties'),
        required: true,
        type: 'input-properties',
        field: 'properties',
      },
      // {
      //   id: 'tags',
      //   group: textFromUid('schema_tags'),
      //   required: true,
      //   type: 'input-tags',
      //   field: 'tags',
      // },
      // {
      //   id: 'schema-public',
      //   group: 'Esquema público',
      //   required: true,
      //   type: 'input-checkbox',
      //   config: {},
      //   field: 'public-entity',
      //   placeholder: 'Digite o tipo da entidade',
      // },
    ];

    return (
      <DynamicForm
        handleSubmit={handleSubmit}
        form={{ fields: schemaObj }}
        submitText={textFromUid('button_new_schema')}
      />
    );
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <div className="rdp-admin-edit-user">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          customNewOptionTitle="asds"
          currentRoute={consumer ? consumer.currentRoute : null}
          // newOption={handleOpenModal}
          // customNewOptionTitle={textFromUid('button_update_password')}
        />

        <PageContainer>
          <Components.Headers.TitleAndDescription
            title={textFromUid('schema_new_title')}
            description={textFromUid('schema_new_description')}
            padding="0px 0px 20px 0px"
          />
          {schemaOptions}
        </PageContainer>

      </div>
    </>
  );
}
