import { useState, useEffect } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';

const useAuthToken = () => {
  const [authToken, setAuthToken] = useState();

  const fetchData = async () => 
  {
    if (Sdk.authorization && Sdk.authorization.credential) {
      const token = await Sdk.authorization.credential.accessToken;
      setAuthToken(token);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return authToken;
};

export default useAuthToken;
