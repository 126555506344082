import styled from 'styled-components';

export const ContainerListUser = styled.div``;

export const Container = styled.div`
  //   display: flex;
  width: 100%;
  height: calc(100% - 9%);
  justify-content: space-between;
  // padding: 0px 10px;
  //   overflow-y: hidden;
`;

export const Mask = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000000bf;
  z-index: 10000;
`;

export const ContentIframe = styled.iframe`
  width: 100%;
  height: 100%;
  background: #ededed;
  margin: 0px;
  padding: 10px;
  //   margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow-y: hidden;
`;

export const ContainerWithBorder = styled.div`
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #e8eaee;
`;

export const ContainerContentModal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const ConteinerModalContent = styled.div`
  height: 100%;
`;

export const ContainerContentModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
