import styled from 'styled-components';

export const ContainerListSchema = styled.div``;

export const ContainerTableSchema = styled.div`
  background: #f5f7f8;
  position: absolute;
  top: 135px;
  left: 230px;
  bottom: 0px;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  width: auto;
`;

export const Container = styled.div`
  // display: flex;
  width: 100%;
  //height: 100%;
  //justify-content: space-between;
  //padding: 0px 10px;
  //overflow-y: hidden;
`;
