import React, { useState, useEffect, useMemo } from 'react';
import { Breadcrumb, Footer, Header, MessageNoItemInTable, NoResult, PageContainer, Sidebar, TableDynamic } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import JSZip from 'jszip';
import $ from 'jquery';
import './list-certificates.scss';
// import JSZipUtils from 'jszip-utils';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';
import { toast } from 'react-toastify';
import Components from 'rdp-builder-components/lib/components';

export default function ViewCertificatesList({ consumer, entity, history }) {
  const [preloader, setPreloader] = useState(true);
  const [fullPreloader, setFullPreloader] = useState(false);
  const [templates, setTemplates] = useState(null);

  async function getCertificates() 
  {
    setPreloader(true);
    var response = await Sdk.dynamic.bridge(
      `storage/v1.0/search?filter=(content_type eq 'digitalpages/template') and (project_uid eq '${Sdk.Api.authorization.activeProject.uid}')&top=1000&orderBy=last_modified desc&(content_info/any(info: info eq 'type=certificate'))`
    );

    response.results.forEach(result=>{
      var info = result.document.content_info.find(info=> info.indexOf("title=") != -1);
      var title = '-';

      if (info) title = info.split('=')[1];

      result.uid = result.document.content_uid;
      result.title = title;
    });

    setPreloader(true);
    setTemplates(response.results);
  }

  useEffect(() => {
    getCertificates();

    consumer.defineRoute({
      name: textFromUid('screen_certificate_title'),
      uri: '/certificates',
    });
  }, []);

  async function onDelete(uid) 
  {
    var filter = templates.filter(template=> template.uid != uid);
    setTemplates(filter);

    var result = await toast.promise(
      Sdk.dynamic.bridge(`storage/v1.0/content?uid=${uid}`,null,'DELETE'),
      {
        pending: textFromUid(`pending_message_certificate_delete`),
        success: textFromUid(`success_message_certificate_delete`),
        error: textFromUid(`error_message_certificate_delete`),
      }
    );

    setTimeout(getCertificates, 2000);
  }

  function onUpdate(uid) {
    history.push(`certificates/${uid}`);
  }

  function createCertificate() {
    setFullPreloader(true);
    var zip = new JSZip();
    zip.file(
      'manifest.json',
      JSON.stringify({
        name: 'Template de certificado',
        version: '1.3',
        metadata: {
          title: 'Template de certificado',
          content_type: 'json',
          type : 'certificate'
        },
        assets: [],
        type: 'certificate',
        default_target: 'index.html',
        app_version: '1.0',
      })
    );
    zip.file(
      'structure.json',
      JSON.stringify({
        name: 'Template de certificado',
        size: 'a4',
        orientation: 'landscape',
        background_image: null,
        items: [],
      })
    );
    zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
      onSaveClick(zipBlob);
    });
  }

  function onSaveClick(zipBlob) {
    var connectorUid = Sdk.Api.authorization.activeProject.connectors.filter(connector => connector.type === "Learning_1")[0].uid;
    var url = `${Sdk.Api.domain}/storage/v1.0/upload/connector/uid/${connectorUid}?manual_project_uid=${Sdk.Api.authorization.activeProject.uid}`;
    var method = 'POST';
    var fd = new FormData();

    fd.append('file', zipBlob, 'Certificado.rdptemplate');

    $.ajax({
      url: url,
      type: method,
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        Authorization: 'Bearer ' + Sdk.Api.authorization.credential.accessToken,
        'Project-Key': Sdk.Api.authorization.projectKey,
        'Api-Env': Sdk.Api.enviroment,
      },
      success: function (response) {
        var newId = response[0].uid;
        history.push(`certificates/${newId}`);
      },
      error: function (error) {
        console.log(error);
      },
    });
  }

  const listCertificates = useMemo(()=>
  {
    if (templates == null) return <Loading msg={textFromUid('loading_message')} />;
    if (templates.length == 0) return <NoResult />;

    const orderFields = [
      'title',
      'document.created_at',
      'document.last_modified'
    ];

    const paths = objectPaths(templates[0], {
      order: orderFields,
      namePrefix: 'table_header_certificate_',
    });

    return <TableDynamic
      headers={paths}
      defaultFields={[]}
      data={templates}
      hasCheckbox={false}
      hasEdit={true}
      hasDelete={true}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />

  }, [templates])

  return (
    <div>
      <Header />
      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        newOption={createCertificate}
        customNewOptionTitle={textFromUid('button_new_certificate')}
      />
      
      <PageContainer>
          <Components.Headers.TitleAndDescription
              title={textFromUid('certificates_detail_title')}
              description={textFromUid('certificates_detail_description')}
              padding="0px 0px 20px 0px"
            />
          {listCertificates}
      </PageContainer>

    </div>
  )
}
