import React, { Component } from 'react';
import './dashboard.scss';

import { Header, Sidebar, Breadcrumb, Footer, PageContainer } from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import PowerbiEmbedded from 'react-powerbi';
import Components from 'rdp-builder-components';

export default class ViewDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      dashboards: null,
      dashboard: null,
      dashboardUid: null,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
      currentProject: null,
    };
  }

  componentDidMount() {
    const project = window.localStorage.getItem('rdp-manager-active-project');
    this.setState({ ready: true, loading: false, currentProject: project });

    this.reportAvailable();
  }

  async componentDidUpdate(prevProps, prevState) 
  {  
    const dashboardUid = this.props.match.params.dashboardUid;

    if (dashboardUid != this.state.dashboardUid)
    {
      const _this = this;
      this.setState({dashboardUid, dashboard:null}, _this.selectDashboard);
      ;
    }
  }

  async reportAvailable() {
    const response = await Sdk.dynamic.bridge(
      `insights/v1.1/report/authorized`,
      null,
      'GET'
    );

    const _this = this;
    this.setState({dashboards: response}, _this.selectDashboard);
  }

  selectDashboard()
  {
    const { dashboards, dashboardUid, dashboard } = this.state;
    const currentDashboard = dashboards?.find(p=> p.id == dashboardUid);
    
    this.setState({dashboard:currentDashboard});
  }

  async mountSelect(items) {
    const data = await items.map((item, idx) => {
      return { label: item.name, value: idx };
    });

    this.setState({
      selectItems: data,
    });
  }

  handleSelect(data) {
    if (!data && data !== 0) return;
    this.setState({ dashboardId: data });
  }

  render() {
    const { dashboard } = this.state;
    const { consumer } = this.props;
    const componentHeight = `100%`;

    return (
      <>
        <div className="rdp-admin-tex">
          <Header />
          <Sidebar
            currentRoute={consumer ? consumer.currentRoute : null}
            defineGroup={
              this.props.consumer ? this.props.consumer.defineGroup : null
            }
            defineRoute={
              this.props.consumer ? this.props.consumer.defineRoute : null
            }
            groups={this.props.consumer ? this.props.consumer.groups : null}
          />
          <Breadcrumb
            currentRoute={consumer ? consumer.currentRoute : null}
            defineRoute={consumer ? consumer.defineRoute : null}
          />
            <PageContainer full={true}>
            {dashboard && (
              <PowerbiEmbedded
                  id={dashboard.id}
                  embedUrl={dashboard.url}
                  accessToken={dashboard.token}
                  filterPaneEnabled={false}
                  navContentPaneEnabled={true}
                  width="100%"
                  height={componentHeight}
                />
            )}
            </PageContainer>
          <Footer version="01" />
        </div>
      </>
    );
  }
}
