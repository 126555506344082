import React, { Component, useEffect, useState } from 'react';
import { Options, Option } from './style';

export default function TabNavigation({
    options, 
    handleIndexSelected,
    initialOptions = 0
}) 
{
    const [formIndex, setFormIndex] = useState(initialOptions);

    useEffect(()=>
    {
        if (!handleIndexSelected) return;
        handleIndexSelected(formIndex);
    }, [formIndex]);

    if (!options) options = [];

    return (
        <Options>
            
            {options.map((option, index)=> 
            {
                return (
                    <Option 
                        key={`option-${index}`} 
                        active={formIndex === index} 
                        onClick={() => setFormIndex(index)}>
                    {option}
                    </Option>
                )
            })}

        </Options>
    )
}