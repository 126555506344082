import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Sdk from 'api.digitalpages.module.sdk.api';
import { GroupService } from '../services/group';
import Loading from '../components/loading';
import { UserService } from '../services/user';
import RDP_UTILS from '../config/util';

const ContextRoute = ({ contextComponent, component, ...rest }) => {
  const history = useHistory()
  const { Provider, Consumer } = contextComponent;
  const Component = component;
  const [currentConnector, setConnector] = useState(null);
  const [currentRoute, setRoute] = useState(null);
  const [currentGroup, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    Sdk.authorization.addActiveProjectListen(async () => {
      const groupsUpdated = await GroupService.getGroups();
      console.log(groupsUpdated);
      updateGroups(groupsUpdated);
    });

    GroupService.getGroups().then((data) =>{
      updateGroups(data)
    });
  }, []);

  const updateGroups = async (groupsUpdated) => 
  {
    setLoading(true);
    const url = window.location.hash;

    if (typeof groupsUpdated === 'object' && groupsUpdated.length > 0) {
      setGroups(groupsUpdated);
      groupsUpdated.filter((group) => {
        if (!group.subItems) return;
        group.subItems.filter((route) => {
          if (url.indexOf(`${route.uri}`) > -1) {
            setGroup(group);
            setRoute(route);
          }
        });
      });
    }
    setLoading(false);
  };

  const defineGroup = (group) => {
    setGroup(group);
  };

  const defineRoute = (route) => {
    if (!Sdk.authorization.authenticated) {
      RDP_UTILS.redirectTo('login');
      return;
    }

    if(route && route.uri) {
      const embed = window.location.hash.includes("embed");
      if (embed) route.uri = `/embed${route.uri}`
    }

    setRoute(route);
    if (route) history.push(route.uri)
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <Provider
          value={{
            currentRoute,
            currentGroup,
            groups,
            defineGroup,
            defineRoute,
          }}
        >
          {loading ? (
            <Loading
              globalLoading
              msg="RDP Manager - Preparando a Plataforma ..."
            />
          ) : (
            <Consumer>
              {(consumer) => <Component {...props} consumer={consumer} />}
            </Consumer>
          )}
        </Provider>
      )}
    />
  );
};

export default ContextRoute;
