import React, { useState, useEffect } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  NotifyBox,
  Footer,
  Loading,
  TableDynamic,
  ListRubric,
} from '../../components';
import { cloneDeep } from 'lodash';
import './new-rubric.scss';

import Sdk from 'api.digitalpages.module.sdk.api';
import { textFromUid } from '../../utils/textUtilities';

const CheckIcon = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM15.768 9.64C15.8558 9.53964 15.9226 9.42274 15.9646 9.29617C16.0065 9.1696 16.0227 9.03591 16.0123 8.90298C16.0018 8.77005 15.9648 8.64056 15.9036 8.52213C15.8423 8.40369 15.758 8.29871 15.6555 8.21334C15.5531 8.12798 15.4346 8.06396 15.3071 8.02506C15.1796 7.98616 15.0455 7.97316 14.9129 7.98683C14.7802 8.00049 14.6517 8.04055 14.5347 8.10463C14.4178 8.16872 14.3149 8.25554 14.232 8.36L9.932 13.519L7.707 11.293C7.5184 11.1108 7.2658 11.01 7.0036 11.0123C6.7414 11.0146 6.49059 11.1198 6.30518 11.3052C6.11977 11.4906 6.0146 11.7414 6.01233 12.0036C6.01005 12.2658 6.11084 12.5184 6.293 12.707L9.293 15.707C9.39126 15.8052 9.50889 15.8818 9.63842 15.9321C9.76794 15.9823 9.9065 16.005 10.0453 15.9986C10.184 15.9923 10.32 15.9572 10.4444 15.8954C10.5688 15.8337 10.6791 15.7467 10.768 15.64L15.768 9.64Z"
        fill="white"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      fill="#764AB0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30px"
      height="30px"
    >
      <path d="M25.707,6.293c-0.195-0.195-1.805-1.805-2-2c-0.391-0.391-1.024-0.391-1.414,0c-0.195,0.195-17.805,17.805-18,18c-0.391,0.391-0.391,1.024,0,1.414c0.279,0.279,1.721,1.721,2,2c0.391,0.391,1.024,0.391,1.414,0c0.195-0.195,17.805-17.805,18-18C26.098,7.317,26.098,6.683,25.707,6.293z" />
      <path d="M23.707,25.707c0.195-0.195,1.805-1.805,2-2c0.391-0.391,0.391-1.024,0-1.414c-0.195-0.195-17.805-17.805-18-18c-0.391-0.391-1.024-0.391-1.414,0c-0.279,0.279-1.721,1.721-2,2c-0.391,0.391-0.391,1.024,0,1.414c0.195,0.195,17.805,17.805,18,18C22.683,26.098,23.317,26.098,23.707,25.707z" />
    </svg>
  );
};

const AddIcon = () => {
  return (
    <svg
      fill="#764AB0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30px"
      height="30px"
    >
      <path d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2  s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z" />
    </svg>
  );
};

export default function ViewNewRubric({ consumer, history, match }) {
  const [entity, setEntity] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [criterions, setCriterions] = useState([
    {
      title: '',
      description: '',
      value: 0,
      value_description: '',
    },
  ]);

  const [indexCriterion, setIndexCriterion] = useState(0);

  const handleSubmit = async () => {
    const { connectors } = Sdk.Api.authorization.activeProject;
    const reviewConnector = connectors.find(
      (connector) => connector.type === 'Review_1'
    );
    if (!reviewConnector) {
      setMsgError('Erro ao carregar conector');
      return;
    }
    try {
      const reviewDto = {
        title,
        description,
      };

      const res = await Sdk.dynamic.bridge(
        `/cms/v1.0/review/connector/uid/${reviewConnector.uid}/management/rubric`,
        reviewDto,
        'POST'
      );

      try {
        if (res) {
          for (let criterion of criterions) {
            const dataCriterion = await Sdk.dynamic.bridge(
              `cms/v1.0/review/rubric/uid/${res.uid}/management/criterion`,
              criterion,
              'POST'
            );
          }
        }
      } catch (error) {
        setMsgError('Houve erro ao adicionar um critério');
      }

      setMsgSuccess('Fluxo de rubrica criado com sucesso');
    } catch (error) {
      setMsgError('Algo deu errado, tente novamente mais tarde');
    }
  };

  const onClickAddCriterion = () => {
    const newCriterio = {
      title: '',
      description: '',
      value: 0,
      value_description: '',
    };

    const newCriterions = cloneDeep(criterions);
    newCriterions.push(newCriterio);
    setCriterions(newCriterions);
  };

  const onClickRemoveCriterion = (index) => {
    const newCriterions = cloneDeep(criterions);

    if (index > -1) {
      newCriterions.splice(index, 1);

      setCriterions(newCriterions);
    }
  };

  const onChangeCriterion = (index) => (event) => {
    let newArr = criterions.map((item, i) => {
      if (index == i) {
        return { ...item, [event.target.name]: event.target.value };
      } else {
        return item;
      }
    });

    setCriterions(newArr);
  };

  return (
    <>
      <div className="rdp-admin-list-content">
        <Header />
        <Sidebar
          currentRoute="/review/new"
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loadingSubmit && <Loading msg="Atualizando revisão..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={() => setMsgSuccess('')}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={() => setMsgError('')}
              message={msgError}
            />
          )}

          <div className="test">
            <main className="new-review-main-content-area">
              <div className="new-review-fields-wrapper-new-rubric">
                <div className="row">
                  <label>
                    <span>{textFromUid('rubric_title')}</span>
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      placeholder={textFromUid('rubric_title_placeholder')}
                      className="text-input"
                      required={true}
                      value={title}
                      onChange={(evt) => setTitle(evt.target.value)}
                    />
                  </label>
                </div>

                <div className="row">
                  <label>
                    <span>{textFromUid('rubric_description')}</span>
                    <span style={{ color: 'red' }}>*</span>
                    <textarea
                      type="text"
                      placeholder={textFromUid(
                        'rubric_description_placeholder'
                      )}
                      className="text-input-rubric"
                      required={true}
                      value={description}
                      onChange={(evt) => setDescription(evt.target.value)}
                    />
                  </label>
                </div>

                <div className="rdp-admin-container-table-new-rubric">
                  <div className="rdp-admin-container-criterion">
                    <div className="row">
                      <label>
                        <span>{textFromUid('rubric_criterion')}</span>
                      </label>
                    </div>

                    <div>
                      <span
                        onClick={onClickAddCriterion}
                        className="rdp-admin-button-new-rubric"
                        style={{ paddingRight: '15px' }}
                      >
                        <AddIcon />
                      </span>
                    </div>
                  </div>

                  <table className="rdp-admin-table-new-rubric">
                    <thead>
                      <tr>
                        <th>
                          <div>
                            <span>{textFromUid('rubric_title')}</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span>{textFromUid('rubric_description')}</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span>{textFromUid('rubric_value')}</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span>
                              {textFromUid('rubric_value_description')}
                            </span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span>{textFromUid('rubric_delete')}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    {criterions.map((criterion, index) => (
                      <>
                        <tbody key={index}>
                          <tr>
                            <td>
                              <div>
                                <input
                                  type="text"
                                  required={true}
                                  className="rdp-admin-input-table-new-rubric"
                                  value={criterion.title}
                                  name="title"
                                  onChange={onChangeCriterion(index)}
                                />
                              </div>
                            </td>
                            <td>
                              <div>
                                <textarea
                                  className="rpd-admin-input-description-new-rubric"
                                  value={criterion.description}
                                  name="description"
                                  onChange={onChangeCriterion(index)}
                                  rows={5}
                                  cols={100}
                                />
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  type="number"
                                  required={true}
                                  className="rdp-admin-input-table-new-rubric"
                                  value={criterion.value}
                                  name="value"
                                  onChange={onChangeCriterion(index)}
                                />
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  type="text"
                                  required={true}
                                  className="rdp-admin-input-table-new-rubric"
                                  value={criterion.value_description}
                                  name="value_description"
                                  onChange={onChangeCriterion(index)}
                                />
                              </div>
                            </td>
                            <td>
                              <div>
                                <span
                                  onClick={() => onClickRemoveCriterion(index)}
                                  className="rdp-admin-button-new-rubric"
                                  style={{ left: '7px', position: 'relative' }}
                                >
                                  <CloseIcon />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    ))}
                  </table>
                </div>
              </div>
            </main>

            <footer className="new-review-footer">
              <button className="register-button" onClick={handleSubmit}>
                <span>
                  <CheckIcon />
                </span>
                <div>{textFromUid('rubric_create')}</div>
              </button>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
}
