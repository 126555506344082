import React, { useState, useEffect, useMemo } from 'react';
import {
  Header,
  Breadcrumb,
  Footer,
  Sidebar,
  Loading,
  TableDynamic,
  MessageNoItemInTable,
} from '../../components';

import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';

import { toast } from 'react-toastify';
import _ from 'underscore';
import Sdk from 'api.digitalpages.module.sdk.api';
import Components from 'rdp-builder-components/lib/components';

import { ContainerListTransaction, ContainerTableTransaction } from './style';

export default function ListTransactions({ consumer }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [loadMoreBtnLabel, setLoadMoreBtnLabel] = useState(
    textFromUid('load_more')
  );

  const [transactions, setTransactions] = useState([]);
  const [transactionUpdate, setTransactionUpdate] = useState(0);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [selectTansaction, setSelectTransaction] = useState('');

  useEffect(() => {
    getTransactions();

    consumer.defineRoute({
      name: textFromUid('screen_transactions_title'),
      uri: '/transactions',
    });
  }, []);

  useEffect(() => {
    populateUsers(transactions);
  }, [transactions]);

  const populateUsers = async (data) => {
    if (!data) return;

    let init = transactionUpdate;

    var tempUsers = _.uniq(transactions, function (item) {
      return item.user_uid;
    });

    for (const user of tempUsers) {
      let userName = textFromUid('user_notfound');

      try {
        var tempUser = await Sdk.dynamic.bridge(
          `auth/v1.0/user/uid/${user.user_uid}`
        );
        userName = tempUser.detail.name;
      } catch {
        // tempUsers[i].user_email = 'Usuário não existente';
      }

      init++;

      data
        .filter((transaction) => transaction.user_uid === user.user_uid)
        .forEach((transaction) => (transaction.user_name = userName));

      setTransactionUpdate(init);
    }
  };

  function onLoadMoreClick() {
    getTransactions();
    console.log('onLoadMoreClick');
  }

  const updatedTransactions = async (transactionUid, data) => {
    const result = await Sdk.dynamic.bridge(
      `v1.0/transaction/uid/${transactionUid}/management`,
      data,
      'PUT'
    );
  };

  const updateActiveTransaction = (transactionUid) => {
    var transaction = transactions.find(
      (transaction) => transaction.uid === transactionUid
    );

    if (!transaction) return;

    setActiveTransaction(transaction);
  };

  const handleUpdateTransaction = async () => {
    const acessStatusTransaction = {
      status: selectTansaction,
    };

    const transactionUpdate = await toast.promise(
      updatedTransactions(activeTransaction.uid, acessStatusTransaction),
      {
        pending: textFromUid('pending_message_status_transaction'),
        success: textFromUid('success_message_status_transaction'),
        error: textFromUid('error_message_status_transaction'),
      }
    );
  };

  const getTransactions = async () => {
    var connectors = await Sdk.authorization.activeProject.connectors.filter(
      (connector) => connector.type == 'Payment_1'
    );
    var params = nextQuery ? `?${nextQuery}` : '';
    let result = [...transactions];

    for (const connector of connectors) {
      const data = await Sdk.dynamic.bridge(
        `store/v1.0/transaction/connector/uid/${connector.uid}/management/available?page=${currentPage}`
      );

      result = [...result, ...data.result];
    }
    result.forEach((transaction) => {
      transaction.user_name = textFromUid('loading_message');
      transaction.status = textFromUid(
        `transaction_status_${transaction.status.toLowerCase()}`
      );
      transaction.payment_type = textFromUid(
        `transaction_payment_${transaction.payment_type.toLowerCase()}`
      );
    });

    setTransactions(result);
  };

  const transactionsFields = useMemo(() => {
    if (transactions <= 0)
      return <Loading msg={textFromUid('loading_message')} />;

    if (transactions > 0)
      return <MessageNoItemInTable message="table_user_no_item" />;

    const orderFields = ['uid', 'user_uid', 'user_name'];

    const paths = objectPaths(transactions[0], {
      order: orderFields,
      namePrefix: 'table_header_transaction_',
    });

    return (
      <TableDynamic
        headers={paths}
        defaultFields={[]}
        data={transactions}
        hasCheckbox={false}
        hasView={false}
        hasEdit={true}
        onUpdate={(transactionsUid) => {
          updateActiveTransaction(transactionsUid);
        }}
      />
    );
  }, [transactions, transactionUpdate]);

  const closeModalTransaction = () => {
    setActiveTransaction(null);
  };

  const modalStatusTransactions = useMemo(() => {
    if (!activeTransaction) return null;

    const adminHasRole = Sdk.hasRole(['AdministratorGlobal']);

    const allOptionsStatus = [
      'None',
      'Chargedback',
      'Refused',
      'Authorized',
      'WaitingPayment',
      'Paid',
      'PendingRefund',
      'Refunded',
      'Processing',
      'PendingReview',
      'Analyzing',
      'Renewed',
    ];

    const items = allOptionsStatus.map((option) => ({
      label: textFromUid(`transaction_status_${option.toLowerCase()}`),
      value: option,
    }));

    return (
      <Components.Alerts.Modal
        onCloseClick={closeModalTransaction}
        actionLabel={textFromUid('modal_edit_transaction_button')}
        onActionClick={() => handleUpdateTransaction()}
        title={textFromUid('modal_edit_transaction_title')}
        closeIconColor="#764AB0"
        boxHeight="300px"
      >
        <div>
          {adminHasRole ? (
            <>
              <div> {textFromUid('modal_edit_transaction_message')} ?</div>

              <div>
                <Components.Buttons.Select
                  maxOptionsHeight={"90px"}
                  onChange={(event) => setSelectTransaction(event)}
                  defaultValue={activeTransaction.status}
                  items={items}
                />
              </div>
            </>
          ) : (
            <div> {textFromUid('modal_transaction_message_not_user')} </div>
          )}
        </div>
      </Components.Alerts.Modal>
    );
  }, [activeTransaction, selectTansaction]);

  const isFrame = window.location.hash.includes('embed/');

  return (
    <>
      {modalStatusTransactions}
      <ContainerListTransaction isFrame={isFrame}>
        <Header />

        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />

        <Breadcrumb
          customNewOptionTitle="asds"
          currentRoute={consumer ? consumer.currentRoute : null}
        />

        <ContainerTableTransaction>
          {transactionsFields}
        </ContainerTableTransaction>
      </ContainerListTransaction>
    </>
  );
}
