import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import './modal-review-task.scss';
import Modal from 'react-responsive-modal';
import ListRubric from '../list-rubric';

import { IconTrash, IconCloseModal } from '../icon';

import Sdk from 'api.digitalpages.module.sdk.api';

export default function ModalReviewTask({
  title = 'Download',
  open,
  onClose,
  reviewTask,
}) {
  const [reviewDetail, setReviewDetail] = useState(null);

  useEffect(() => {
    if (!reviewTask) return;

    requestContent(reviewTask.uid);
  }, [reviewTask]);

  async function requestContent(task) {
    const result = await Sdk.dynamic.bridge(
      `cms/v1.0/review/task/uid/${task}/contents`
    );

    if (!reviewTask || reviewTask.uid !== task) return;

    setReviewDetail(result);
  }

  function downloadFile(contentUid) {
    let result = Sdk.dynamic.bridgeUrl(`storage/v1.0/content`);

    result.indexOf('&') == -1 ? (result += '?') : (result += '&');
    result += `uid=${contentUid}`;

    window.open(result);
  }

  // console.log('reviewDetail', reviewDetail);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      styles={{
        overlay: { backgroundColor: 'rgba(125, 125, 125, 0.2)' },
        modal: {
          borderRadius: '5px',
          padding: '0px',
          maxWidth: '1500px',
        },
      }}
      showCloseIcon={false}
      focusTrapped={false}
      center
    >
      <div className="rdp-modal-container-review">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <span>{title}</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={() => onClose()}
          >
            <IconCloseModal />
          </button>
        </div>

        <div className="rdp-modal-review-container-footer">
          <div className="rdp-modal-container-buttons">
            <p>
              Deseja fazer download da atividade{' '}
              <strong>{reviewTask?.activity_name} ? </strong>
            </p>
          </div>
          <div className="rdp-modal-container-butons-rubric">
            <button
              className="rdp-modal-button-review"
              onClick={() => downloadFile(reviewDetail.review_content_uid)}
              disabled={reviewDetail?.review_content_uid ? false : true}
            >
              Revisor
            </button>

            <button
              className="rdp-modal-button-review"
              onClick={() => downloadFile(reviewDetail.target_content_uid)}
              disabled={reviewDetail?.target_content_uid ? false : true}
            >
              Usuário
            </button>
          </div>

          <div className="rdp-modal-container-tile-rubric">
            <span>Rubricas</span>
          </div>

          <div className="rdp-modal-container-rubrics">
            <ListRubric ItemRubric={reviewDetail?.flow?.rubrics} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
