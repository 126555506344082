import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import Components from 'rdp-builder-components';
import Sdk from 'api.digitalpages.module.sdk.api';

import DynamicForm from '../../components/form-dynamic-v2';
import {
  Breadcrumb,
  Header,
  Loading,
  PageContainer,
  Sidebar,
} from '../../components';
import { textFromUid } from '../../utils/textUtilities';

export default function ViewNewAccessExternal({ consumer, match, history }) {
  const [schemas, setSchemas] = useState(null);
  const [projects, setProjects] = useState(null);
  const [external, setExternal] = useState({
    ip_range_filter: '',
    target_uid: '',
    type: '',
  });

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_new_access_external'),
      path: 'external/new',
    });
  }, []);

  useEffect(() => {
    loadEntities();
    loadProjects();
  }, []);

  const loadEntities = async () => {
    const res = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/management/schema/available?type=security`,
      null,
      'GET'
    );

    const result = [];

    for (const [idx, entity] of res.entries()) {
      const schemaInfo = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}`,
        null,
        'GET'
      );
      const items = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}/items`,
        null,
        'GET'
      );

      items.sort((a, b) => a.row - b.row);
      schemaInfo.items = items;
      result.push(schemaInfo);
    }

    setSchemas(result);
  };

  const loadProjects = async () => {
    const { projects } = Sdk.authorization;

    setProjects(projects);
  };

  const handleSubmit = async (data) => {
    var project = [];
    var entity = [];

    for (let infoProject in data.project_uids) {
      var infoProjectDetail = data.project_uids[infoProject];
      if (!infoProjectDetail) continue;

      project.push(infoProject);
    }

    schemas.forEach((schema) => {
      var entities = data[schema.uid];
      for (let entityData in entities) {
        var infoEntityDetail = entities[entityData];
        if (!infoEntityDetail) continue;

        entity.push(entityData);
      }
    });

    var requests = project.map((uidProject) => {
      return {
        ip_range_filter: data.ip,
        target_uid: uidProject,
        type: 'project',
      };
    });

    requests = requests.concat(
      entity.map((uidProject) => {
        return {
          ip_range_filter: data.ip,
          target_uid: uidProject,
          type: 'entity',
        };
      })
    );

    let connectorDirectRegisterUid =
      await Sdk.Api.authorization.activeProject.connectors.filter(
        (connector) => connector.type === 'DirectRegister_1'
      )[0].uid;

    for (let dataAccess of requests) {
      await toast.promise(
        Sdk.dynamic.bridge(
          `auth/v1.1/direct/register/connector/uid/${connectorDirectRegisterUid}/management/context/access`,
          dataAccess,
          'POST'
        ),
        {
          pending: textFromUid('success_message_access_external_create'),
          success: textFromUid('pending_message_access_external_create'),
          error: textFromUid('error_message_access_external_create'),
        }
      );
    }
  };

  const accessExternalOptions = useMemo(() => {
    if (!external) return <Loading msg={textFromUid('loading_message')} />;
    if (!schemas) return <Loading msg={textFromUid('loading_message')} />;
    if (!projects) return <Loading msg={textFromUid('loading_message')} />;

    const currentEntities = {};

    var schemaObj = [
      {
        id: 'ip',
        group: textFromUid('access_external_ip_range'),
        required: true,
        type: 'input-text',
        config: {},
        field: 'ip',
        placeholder: '192.168.10.10-20',
      },
    ];

    for (const schmea of schemas) {
      schemaObj.push({
        id: `entity-${schmea.uid}`,
        required: true,
        schema: schmea,
        type: 'input-schema-checkbox',
      });
    }

    schemaObj.push({
      id: `project_uids`,
      type: 'input-multi-checkbox',
      group: 'Projeto',
      defaultValues: [],
      data: projects.map((product) => {
        return {
          name: product.name,
          value: product.uid,
          information: product.uid,
        };
      }),
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('access_external_title')}
          description={textFromUid('access_external_description')}
          padding="0px 0px 20px 0px"
        />

        <DynamicForm
          handleSubmit={handleSubmit}
          form={{ fields: schemaObj }}
          submitText={textFromUid('access_external_new_create')}
          defaultValues={currentEntities}
        />
      </>
    );
  }, [schemas, projects]);

  return (
    <div className="rdp-admin-edit-user">
      <Header />

      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />

      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />

      <PageContainer>{accessExternalOptions}</PageContainer>
    </div>
  );
}
