import React, { useEffect, useState } from 'react';
// import './new-course.scss';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  Loading,
  NotifyBox,
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import DynamicForm from '../../components/form-dynamic-v2';
import { DAMService } from '../../services/dam';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';

export default function NewOffer({ consumer, history, match }) {
  const [entity, setEntity] = useState();
  const [entityUpd, setEntityUpd] = useState();
  const [ids, setIds] = useState();
  const [types, setTypes] = useState([]);
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  const getOptions = async (list) => {
    const options = await list.map((item) => {
      return { uid: item.uid, label: item.data[Object.keys(item.data)[0]] };
    });

    return options;
  };

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    setEntity(forms['new-offer']);
  };

  const loadEntities = async () => {
    if (entity) {
      const entitiesCp = { ...entity };
      let idList = [];

      const res = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/available?type=security`,
        null,
        'GET'
      );

      for (const [idx, entity] of res.entries()) {
        const tree = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/management/schema/uid/${entity.uid}/items`,
          null,
          'GET'
        );

        entitiesCp.fields.push({
          id: `container-category-${idx}`,
          group: entity.name,
          required: true,
          type: 'input-multi-checkbox',
          config: {},
          field: `entities-${idx}`,
          options: await getOptions(tree),
        });

        idList.push(`container-category-${idx}`);
      }

      // if (Array.isArray(field.options) && field.options.length > 0) {
      //   return;
      // }

      // const options = res.map((item) => ({
      //   uid: item.uid,
      //   label: item.data.name,
      // }));
      // field.options = options;

      setEntityUpd(entitiesCp);
      setIds(idList);
    }
  };

  useEffect(() => {
    getForm();
    const rawDraft = localStorage.getItem('new-course-draft');
    if (rawDraft) {
      const parsedDraft = JSON.parse(rawDraft);
      setDefaultValues(parsedDraft);
    }
  }, []);

  useEffect(() => {
    loadEntities();
  }, [entity]);

  const handleSave = async (inputs, image) => {
    let inputsCp = { ...inputs };
    if (image) {
      const base64Image = await toBase64(inputs[image.id]);
      console.log(base64Image);
      inputsCp[image.id] = base64Image;
    }
    localStorage.setItem('new-course-draft', JSON.stringify(inputsCp));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (inputs, setInputs) => {
    const uids = await ids.map((item) => {
      if (inputs[item]) {
        return Object.keys(inputs[item]).filter((key) => inputs[item][key]);
      }
    });

    const auths = adjustAuthorizations(uids, false);

    setLoadingSubmit(true);
    try {
      let {
        name,
        description,
        register_start,
        register_end,
        visibility_start,
        visibility_end,
        interaction_start,
        interaction_end,
      } = { ...inputs };

      const obj = {
        name,
        description,
        register_start,
        register_end,
        visibility_start,
        visibility_end,
        interaction_start,
        interaction_end,
        authorizations: auths,
      };

      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${match.params.treeUid}/management/registers`,
        obj,
        'POST'
      );
      setMsgSuccess('Oferta criada com sucesso');
      setInputs({});
      history.push(`/tree/${match.params.treeUid}`);
    } catch (error) {
      setMsgError('Algo deu errado ... Tente novamente mais tarde');
    }
    // setLoadingSubmit(false);
  };

  function adjustAuthorizations(uids, combined) {
    let groups = [];

    if (combined) {
      let uidflat = uids.flat().filter((el) => el !== undefined);
      groups.push(uidflat);
    } else {
      var result = [[]];
      uids
        .filter((group) => group != undefined)
        .forEach(
          (group, index) =>
            (result = group.flatMap((g) => result.map((r) => r.concat(g))))
        );
      groups = result;
    }

    let authorizations = groups.map((entities) => {
      return {
        role: 'Viewer',
        references: entities.map((item) => {
          return { reference_uid: item, type: 'Entity' };
        }),
      };
    });

    return authorizations;
  }

  async function getThumbUrl(file) {
    const response = await DAMService.uploadFileAxios(
      file,
      null,
      'ContentManager'
    );

    const publicUrl = await DAMService.getPublicUrl(response.data[0].uid);

    return publicUrl;
  }

  return (
    <div>
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content">
        {loadingSubmit && <Loading msg="Criando curso..." />}
        {!loadingSubmit && msgSuccess && (
          <NotifyBox
            type="success"
            onClose={() => setMsgSuccess('')}
            message={msgSuccess}
          />
        )}
        {!loadingSubmit && msgError && (
          <NotifyBox
            type="error"
            onClose={() => setMsgError('')}
            message={msgError}
          />
        )}
        {entity ? (
          <DynamicForm
            handleSubmit={handleSubmit}
            handleSave={handleSave}
            form={entityUpd ? entityUpd : entity}
            submitText="SALVAR OFERTA"
            defaultValues={defaultValues}
          />
        ) : (
          <Loading msg="Configurando formulário ..." />
        )}
      </div>
      <Footer version="01" />
    </div>
  );
}
