import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import DynamicForm from '../../components/form-dynamic-v2';
import {
  Sidebar,
  Breadcrumb,
  Header,
  TableDynamic,
  MessageNoItemInTable,
  Pagination,
  Iframe,
  PageContainer,
  NoResult,
  TabNavigation,
  ButtonDefault,
  IconCheck,
  IconUpload,
} from '../../components';
import Components from 'rdp-builder-components';
import Loading from '../../components/loading';
import { toast } from 'react-toastify';
import { textFromUid } from '../../utils/textUtilities';
import {
  dynamicPropertiesToForm,
  objectPaths,
  objectToForm,
  formToDynamicProperties,
} from '../../utils/formUtilities';

import { Redirect } from 'react-router-dom';
import Sdk from 'api.digitalpages.module.sdk.api';

import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import {
  Container,
  ContentIframe,
  Mask,
  ContainerWithBorder,
  ContainerContentModal,
  ContainerContentModalButton,
  ContainerListUser,
  ConteinerModalContent,
} from './style';
import axios from 'axios';

const TabInformations = textFromUid('tab_managed_content_informations');
const TabCategories = textFromUid('tab_managed_content_categories');
const TabContents = textFromUid('tab_managed_content_contents');
const TabPermissions = textFromUid('tab_managed_content_authorizations');
const TabProducts = textFromUid('tab_managed_content_products');
const TabPreview = textFromUid('tab_managed_content_preview');
const TabReferenceInfo = textFromUid('tab_managed_content_reference_info');

export default function ManagedContentEdit({ consumer, match, history }) {
  const [content, setContent] = useState(null);
  const [contentUpdated, setContentUpdated] = useState(null);
  const [categoriesUpdated, setCategoriesUpdated] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [tabOptions] = useState([TabInformations, TabCategories, TabContents]);
  const [referenceTabOptions] = useState([
    TabReferenceInfo,
    TabPreview,
    // TabProducts,
    TabPermissions,
  ]);
  const [activeTab, setActiveTab] = useState(null);
  const [referenceActiveTab, setReferenceActiveTab] = useState(TabReferenceInfo);
  const [products, setProducts] = useState([]);
  const [schemas, setSchemas] = useState(null);
  const [projects, setProjects] = useState(null);
  const [newReference, setNewReference] = useState(null);
  const [newReferenceUpdated, setNewReferenceUpdated] = useState(null);
  const [iframe, setIframe] = useState(null);
  const [damCallback, setDamCallback] = useState(null);
  const [newAuthorization, setNewAuthorization] = useState(null);
  const [authorizationConfg, setAuthorizationConfg] = useState(null);
  const [newAuthorizationOption, setNewAuthorizationOption] = useState(0);
  const [roles, setRoles] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);
  const [errorLoadingContent, setErrorLoadingContent] = useState(false);

  const [statusCodeReturnIframe, setStatusCodeReturnIframe] = useState(null);

  const contentInfoRef = useRef('');
  const authorizationRef = useRef('');
  const categoriesRef = useRef('');

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_managed_content_edit'),
    });

    loadContent();
    loadProducts();
    loadSchemas();
    loadProjects();
    loadRoles();
  }, []);

  useEffect(() => {
    if (!damCallback) {
      window.removeEventListener('message', handlerDamFileSelected);
      return;
    }
    var route = damCallback.route;
    var env = Sdk.enviroment == 'homolog' ? 'h' : 'p';
    const url = `${Sdk.domain}/product/dam/current/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/?access_token=${Sdk.authorization.credential.accessToken}&language=pt_br#${route}`;

    window.addEventListener('message', handlerDamFileSelected);
    setIframe(url);
  }, [damCallback]);

  useEffect(() => {
    authorizationRef.current = authorizationConfg;
  }, [authorizationConfg]);

  useEffect(() => {
    contentInfoRef.current = contentUpdated;
  }, [contentUpdated]);

  useEffect(() => {
    categoriesRef.current = categoriesUpdated;
  }, [categoriesUpdated]);

  useEffect(() => {
    syncReference();
  }, [newReference]);

  ///loads

  const loadContent = async () => {
    const contentGroupUid = match.params.contentGroupUid;
    const content = await Sdk.dynamic.bridge(
      `cms/v1.1/managed/content/group/uid/${contentGroupUid}/management`,
      null,
      'GET'
    );
    setContent(content);
  };

  const loadProducts = async () => {
    const products = await Sdk.dynamic.bridge(
      `store/v1.0/product/available`,
      null,
      'GET'
    );
    setProducts(products);
  };

  const loadSchemas = async () => {
    const schemas = await Sdk.dynamic.bridge(
      'auth/v1.0/entity/management/schema/available'
    );
    if (!schemas) return;

    for (const schema of schemas) {
      const items = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/uid/${schema.uid}/items`
      );
      schema.items = items.sort((a, b) => {
        if (a.row > b.row) return 1;
        if (a.row < b.row) return -1;
        return 0;
      });
    }

    setSchemas(schemas);
  };

  const loadProjects = async () => {
    const result = await Sdk.dynamic.bridge('auth/v1.0/projects');
    if (!result) return;
    setProjects(result);
  };

  const loadRoles = async () => {
    const response = await Sdk.dynamic.bridge(`auth/v1.0/roles`, null, 'GET');

    setRoles(response);
  };

  /// Updates

  const updateProductState = (productUid) => {
    const products = newReference.products;
    let product = products.find((p) => p.product_uid == productUid);

    if (!product) {
      product = { product_uid: productUid, state: 'add' };
      products.push(product);
      return;
    }

    product.state = !product.state || product.state == 'add' ? 'remove' : 'add';
  };

  /// syncs

  const syncReference = async () => {
    const reference = newReference;
    if (!reference || reference.state == undefined) return;

    var referenceResult = null;

    switch (reference.state) {
      case 'add': {
        const contentGroupUid = match.params.contentGroupUid;
        referenceResult = await toast.promise(
          Sdk.dynamic.bridge(
            `cms/v1.1/managed/content/group/uid/${contentGroupUid}/management/reference`,
            reference,
            'POST'
          ),
          {
            pending: textFromUid(`pending_message_create_managed_content_reference`),
            success: textFromUid(`success_message_create_managed_content_reference`),
            error: textFromUid(`error_message_create_managed_content_reference`),
          }
        );

        content.references.push(referenceResult);
        break;
      }
      case 'update': {

        var dataToUpdated = {...reference};
        delete dataToUpdated.authorizations;

        referenceResult = await toast.promise(
          Sdk.dynamic.bridge(
            `cms/v1.1/managed/content/reference/uid/${dataToUpdated.uid}/management`,
            dataToUpdated,
            'PUT'
          ),
          {
            pending: textFromUid(`pending_message_update_managed_content_reference`),
            success: textFromUid(`success_message_update_managed_content_reference`),
            error: textFromUid(`error_message_update_managed_content_reference`),
          }
        );

        referenceResult.authorizations = reference.authorizations;
        content.references = content.references.filter(
          (p) => p.uid != referenceResult.uid
        );
        content.references.push(referenceResult);
        break;
      }
    }

    setContent({ ...content });

    if (reference == newReference && referenceResult) {
      setNewReference(referenceResult);
    }

  };

  const syncProducts = async (data) => {
    var removeProducts = newReference.products.filter(
      (p) => p.state == 'remove'
    );
    var addProducts = newReference.products.filter((p) => p.state == 'add');

    for (const product of removeProducts) {
      await toast.promise(
        Sdk.dynamic.bridge(
          `cms/v1.1/managed/content/product/uid/${product.uid}/management`,
          null,
          'DELETE'
        ),
        {
          pending: textFromUid(`pending_message_deteted_node`),
          success: textFromUid(`success_message_deteted_node`),
          error: textFromUid(`error_message_deteted_node`),
        }
      );

      delete product.state;
    }

    for (const product of addProducts) {
      var result = await toast.promise(
        Sdk.dynamic.bridge(
          `cms/v1.1/managed/content/reference/uid/${newReference.uid}/management/product`,
          {
            product_uid: product.product_uid,
          },
          'post'
        ),
        {
          pending: textFromUid(`pending_message_deteted_node`),
          success: textFromUid(`success_message_deteted_node`),
          error: textFromUid(`error_message_deteted_node`),
        }
      );

      product.uid = result.uid;
      delete product.state;
    }
  };

  const updateInformation = async () => {
    var data = contentInfoRef.current;
    const connectorUid = match.params.connectorUid;
    const connector = Sdk.Api.authorization.activeProject.connectors.find(
      (p) => p.uid == connectorUid
    );
    const properties = JSON.parse(connector.configuration).properties;
    const result = formToDynamicProperties(data, properties);

    var postData = {
      name: data.name,
      description: data.description,
      properties: result,
    };

    const group = await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.1/managed/content/group/uid/${data.uid}/management`,
        postData,
        'PUT'
      ),
      {
        pending: textFromUid(`pending_message_update_new_content`),
        success: textFromUid(`success_message_update_new_content`),
        error: textFromUid(`error_message_update_new_content`),
      }
    );
  };

  const handlerDamFileSelected = useCallback(
    (event) => {
      if (!damCallback) return;

      const data = event.data;
      if (data && data.type != 'damfile' && !data.uid && !data.content_type)
        return;

      damCallback.result(data);
    },
    [damCallback]
  );

  const createAuthorization = () => {
    setNewAuthorization({
      role: 'Viewer',
      references: [],
    });
  };

  const newContentReference = () => {
    setNewReference({ state: 'add', products: [], authorizations: [] });
  };

  const handleTabSelected = async (index) => {
    setActiveTab(tabOptions[index]);
  };

  const handleReferenceTabSelected = async (index) => {
    setReferenceActiveTab(referenceTabOptions[index]);
  };

  const handleChangeContentReference = async () => {
    const callback = { result: null, route: '/embed/files' };

    new Promise(function (resolve, reject) {
      callback.result = resolve;
    }).then(async (data) => {
      newReference.internal_content_uid = data.uid;
      if (!newReference.state) newReference.state = 'update';

      setDamCallback(null);
      setIframe(null);
      setNewReference({ ...newReference });
    });

    setDamCallback(callback);
  };

  const deleteReference = async (contentUid) => {
    var reference = content.references.find((p) => p.uid == contentUid);
    content.references = content.references.filter((p) => p.uid != contentUid);

    setContent({ ...content });

    toast
      .promise(
        Sdk.dynamic.bridge(
          `cms/v1.1/managed/content/reference/uid/${contentUid}/management`,
          null,
          'DELETE'
        ),
        {
          pending: textFromUid(`pending_message_access_delete_content`),
          success: textFromUid(`success_message_access_delete_content`),
          error: textFromUid(`error_message_access_delete_content`),
        }
      )
      .catch(() => {
        content.references.push(reference);
        setContent({ ...content });
      });
  };

  const editReference = async (uid) => {
    var reference = content.references.find((p) => p.uid == uid);
    setNewReference(reference);
  };

  const deleteAuthorization = async (authorizationUid) => {
    var authorization = newReference.authorizations.find(
      (p) => p.uid == authorizationUid
    );
    newReference.authorizations = newReference.authorizations.filter(
      (p) => p.uid != authorizationUid
    );

    setNewReference({ ...newReference });

    toast
      .promise(
        Sdk.dynamic.bridge(
          `cms/v1.1/managed/content/authorization/uid/${authorizationUid}/management`,
          null,
          'DELETE'
        ),
        {
          pending: textFromUid(`pending_message_deteted_authorization`),
          success: textFromUid(`success_message_deteted_authorization`),
          error: textFromUid(`error_message_deteted_authorization`),
        }
      )
      .catch(() => {
        newReference.authorizations.push(authorization);
        setNewReference({ ...newReference });
      });
  };

  const updateCategories = async () => {
    var form = categoriesRef.current;
    var data = { entities: [] };
    schemas.forEach((schema) => {
      var info = form[schema.uid];
      if (!info) return;

      Object.keys(info).forEach((key) => {
        if (info[key] != true) return;
        data.entities.push({ entity_uid: key });
      });
    });

    var result = await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.1/managed/content/group/uid/${content.uid}/management`,
        data,
        'PUT'
      ),
      {
        pending: textFromUid(`pending_message_update_new_category`),
        success: textFromUid(`success_message_update_new_category`),
        error: textFromUid(`error_message_update_new_category`),
      }
    );
  };

  const changeReference = async () => {
     const referenteUpdated = await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.1/managed/content/reference/uid/${newReference.uid}/management`,
        newReference,
        'PUT'
      ),
      {
        pending: textFromUid(`pending_message_updated_managed_content_reference`),
        success: textFromUid(`success_message_updated_managed_content_reference`),
        error: textFromUid(`error_message_updated_managed_content_reference`),
      }
    );

    setNewReference(referenteUpdated);
  };

  const changeAuthorization = async () => {
    var formData = authorizationRef.current;

    newAuthorization.references = [];
    newAuthorization.authorize_anonymous_users = formData.authorize_anonymous_users;
    newAuthorization.skip_required_products = formData.skip_required_products;

    if (formData.user_uid && formData.user_uid.length > 0) {
      newAuthorization.references.push({
        reference_uid: formData.user_uid,
        type: 'User',
      });
    }

    schemas.forEach((schema) => {
      var info = formData[schema.uid];
      if (!info) return;

      Object.keys(info).forEach((key) => {
        if (info[key] != true) return;
        newAuthorization.references.push({
          reference_uid: key,
          type: 'Entity',
        });
      });
    });

    if(formData.projects) {
      Object.keys(formData.projects).forEach((key) => {
        if (formData.projects[key] != true) return;
        newAuthorization.references.push({
          reference_uid: key,
          type: 'Project',
        });
      });
    }

    if(formData.products) {
      Object.keys(formData.products).forEach((key) => {
        if (formData.products[key] != true) return;
        newAuthorization.references.push({
          reference_uid: key,
          type: 'Product',
        });
      });
    }

    var authorizationUpdated = null;

    if (!newAuthorization.uid) {
      authorizationUpdated = await toast.promise(
        Sdk.dynamic.bridge(
          `cms/v1.1/managed/content/reference/uid/${newReference.uid}/management/authorization`,
          newAuthorization,
          'POST'
        ),
        {
          pending: textFromUid(`pending_message_create_authorization`),
          success: textFromUid(`success_message_create_authorization`),
          error: textFromUid(`error_message_create_authorization`),
        }
      );
    } else {
      authorizationUpdated = await toast.promise(
        Sdk.dynamic.bridge(
          `cms/v1.1/managed/content/authorization/uid/${newAuthorization.uid}/management`,
          newAuthorization,
          'PUT'
        ),
        {
          pending: textFromUid(`pending_message_updated_authorization`),
          success: textFromUid(`success_message_updated_authorization`),
          error: textFromUid(`error_message_updated_authorization`),
        }
      );
    }

    newReference.authorizations = newReference.authorizations.filter(
      (p) => p.uid != authorizationUpdated.uid
    );
    newReference.authorizations.push(authorizationUpdated);

    setNewReference({ ...newReference });
    setNewAuthorization(null);
  };

  const editAuthorization = async (authorizationUid) => {
    const authorization = newReference.authorizations.find(
      (p) => p.uid == authorizationUid
    );
    setNewAuthorization(authorization);
  };

  // Interface

  const tabInformation = useMemo(() => {
    if (content == null)
      return <Loading msg={textFromUid('loading_message')} />;

    const requiredFields = [
      'uid',
      'name',
      'description',
      'created_at',
      'updated_at',
    ];
    const order = ['uid', 'name', 'description', 'created_at', 'updated_at'];
    const disable = ['uid', 'created_at', 'updated_at'];

    var dynamicProperties = dynamicPropertiesToForm(content.properties);
    var defaulfProperties = objectToForm(content, {
      required: requiredFields,
      disabled: disable,
      order: order,
      namePrefix: 'managed_content_',
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('managed_content_detail_title')}
          description={textFromUid('managed_content_detail_description')}
          padding="20px 0px 20px 0px"
        />

        <DynamicForm
          form={{ fields: [...defaulfProperties, ...dynamicProperties.fields] }}
          defaultValues={{ ...content, ...dynamicProperties.values }}
          handleChanges={(data) => setContentUpdated(data)}
        />
      </>
    );
  }, [content]);

  const tabContents = useMemo(() => {
    if (content == null)
      return <Loading msg={textFromUid('loading_message')} />;

    let childElement = <NoResult />;

    if (content.references.length > 0) {
      const order = [
        'uid',
        'name',
        'description',
        'mime_type',
        'created_at',
        'updated_at',
      ];
      const paths = objectPaths(content.references[0], {
        namePrefix: 'table_header_managed_content_content_',
        order: order,
      });

      childElement = (
        <>
          <TableDynamic
            title={'Contents'}
            headers={paths}
            data={content.references}
            defaultFields={[]}
            hasEdit={true}
            hasDelete={true}
            onDelete={deleteReference}
            onUpdate={editReference}
          />
        </>
      );
    }

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('managed_content_content_title')}
          description={textFromUid('managed_content_content_description')}
          padding="20px 0px 20px 0px"
        />

        {childElement}
      </>
    );
  }, [content, loadingContent]);

  const tabCategories = useMemo(() => {
    if (!schemas || !content) return <NoResult />;

    const categories = { fields: [] };

    for (const schmea of schemas.filter((s) => s.type == 'Category')) {
      categories.fields.push({
        id: `category-${schmea.uid}`,
        required: true,
        schema: schmea,
        type: 'input-schema-checkbox',
      });
    }

    var defaultValues = {};
    schemas.forEach((schema) => {
      if (!schema.items) return;

      var values = [];

      schema.items.forEach((entity) => {
        if (
          content.entities?.find(
            (dataEntity) => dataEntity.entity_uid == entity.uid
          )
        ) {
          values.push(entity.uid);
        }
      });
      defaultValues[schema.uid] = values;
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('managed_content_categories_title')}
          description={textFromUid('managed_content_categories_description')}
          padding="20px 0px 20px 0px"
        />
        <DynamicForm
          form={categories}
          handleChanges={(data) => setCategoriesUpdated(data)}
          defaultValues={defaultValues}
        />
      </>
    );
  }, [schemas, content]);

  const breadcrumb = useMemo(() => {
    switch (activeTab) {
      case TabInformations: {
        return (
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={updateInformation}
              iconDiv={true}
            >
              {textFromUid('button_update_managed_content')}
            </ButtonDynamic>
          </Breadcrumb>
        );
        break;
      }
      case TabProducts:
      case TabCategories: {
        return (
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={updateCategories}
              iconDiv={true}
            >
              {textFromUid('update')}
            </ButtonDynamic>
          </Breadcrumb>
        );
      }
      case TabContents: {
        return (
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={newContentReference}
              iconDiv={true}
            >
              {textFromUid('managed_content_button_link_content')}
            </ButtonDynamic>
          </Breadcrumb>
        );
      }
      case TabPermissions: {
        return (
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
            <ButtonDynamic
              color="blue"
              icon="plus"
              actionClick={createAuthorization}
              iconDiv={true}
            >
              {textFromUid('managed_content_button_add_permission')}
            </ButtonDynamic>
          </Breadcrumb>
        );
      }
    }

    return null;
  }, [activeTab]);

  const tabProducts = useMemo(() => {
    if (products == null || newReference == null)
      return <Loading msg={textFromUid('loading_message')} />;

    let childElement = <NoResult />;

    if (products.length > 0) {
      const order = [
        'uid',
        'name',
        'unique_name',
        'currency',
        'amount',
        'installments_max',
        'created_at',
      ];
      const productsSelected = newReference.products
        .filter((p) => !p.state || p.state == 'add')
        .map((p) => p.product_uid);

      const paths = objectPaths(products[0], {
        namePrefix: 'table_header_product_',
        order: order,
      });

      childElement = (
        <>
          <TableDynamic
            title={'Offers'}
            headers={paths}
            data={products}
            hasCheckbox={true}
            onCheckboxClick={updateProductState}
            defaultFields={productsSelected}
          />
        </>
      );
    }

    return (
      <ContainerContentModal>
        <div>
          <Components.Headers.TitleAndDescription
            title={textFromUid('managed_content_products_title')}
            description={textFromUid('managed_content_products_description')}
            padding="20px 0px 20px 0px"
          />
          <ContainerWithBorder>{childElement}</ContainerWithBorder>
        </div>
      </ContainerContentModal>
    );
  }, [newReference, products]);

  const tabPermissions = useMemo(() => {
    if (newReference == null)
      return <Loading msg={textFromUid('loading_message')} />;

    let childElement = <NoResult />;

    if (newReference.authorizations.length > 0) {
      const order = [
        'uid',
        'role',
        'authorize_anonymous_users',
        'skip_required_products',
        'created_at',
        'updated_at',
      ];
      const paths = objectPaths(newReference.authorizations[0], {
        namePrefix: 'table_header_managed_content_authorization_',
        order: order,
      });

      childElement = (
        <>
          <TableDynamic
            title={'Authoriztions'}
            headers={paths}
            data={newReference.authorizations}
            defaultFields={[]}
            hasEdit={true}
            hasDelete={true}
            onDelete={deleteAuthorization}
            onUpdate={editAuthorization}
          />
        </>
      );
    }

    return (
      <ContainerContentModal>
        <ConteinerModalContent>
          <Components.Headers.TitleAndDescription
            title={textFromUid('managed_content_content_title')}
            description={textFromUid('managed_content_content_description')}
            padding="20px 0px 20px 0px"
          />

          <ContainerWithBorder>{childElement}</ContainerWithBorder>
        </ConteinerModalContent>
      </ContainerContentModal>
    );
  }, [newReference, schemas]);

  async function consumeIframe() {
    await axios
      .get(
        `${Sdk.Api.domain}/cms/v1.1/managed/content/reference/uid/${newReference.uid}/management/content?project_key=${Sdk.Api.projectKey}&access_token=${Sdk.authorization.credential.accessToken}&api_env=${Sdk.Api.enviroment}`
      )
      .then((response) => {
        setStatusCodeReturnIframe(Boolean(response.data));
        setLoadingContent(false);
      })
      .catch((err) => setStatusCodeReturnIframe(null));
  }

  const tabPreview = () => {
    if (newReference == null)
      return <Loading msg={textFromUid('loading_message')} />;

    const referenceContent = `${Sdk.Api.domain}/cms/v1.1/managed/content/reference/uid/${newReference.uid}/management/content?project_key=${Sdk.Api.projectKey}&access_token=${Sdk.authorization.credential.accessToken}&api_env=${Sdk.Api.enviroment}`;

    consumeIframe();

    const handleIframeLoad = () => {
      setLoadingContent(true);
    };

    const handleIframeError = () => {
      setErrorLoadingContent(true);
    };

    return (
      <ContainerContentModal>
        <ConteinerModalContent>
          {statusCodeReturnIframe ? (
            <ContentIframe
              allow="camera; microphone; display-capture; autoplay; clipboard-write; encrypted-media"
              sandbox="allow-scripts allow-forms allow-same-origin allow-pointer-lock allow-presentation allow-modals allow-popups allow-popups-to-escape-sandbox"
              src={referenceContent}
              onLoad={handleIframeLoad}
              onError={handleIframeError}
            />
          ) : (
            <NoResult />
          )}
        </ConteinerModalContent>
      </ContainerContentModal>
    );
  };

  const tabReferenceInformation = useCallback(()=>{
    if (newReference == null)
      return <Loading msg={textFromUid('loading_message')} />;

    const order = ['uid', 'name', 'description', 'created_at', 'updated_at'];
    const disable = ['uid', 'created_at', 'updated_at'];

    var defaulfProperties = objectToForm(newReference, {
      required: [],
      disabled: disable,
      order: order,
      namePrefix: 'managed_content_reference_',
    });

    return (
      <>
        {/* <Components.Headers.TitleAndDescription
          title={textFromUid('managed_content_authorization_detail_title')}
          description={textFromUid('managed_content_authorization_detail_description')}
          padding="20px 0px 20px 0px"
        /> */}

        <DynamicForm
          form={{ fields: defaulfProperties}}
          defaultValues={newReference}
          handleChanges={(data) => setNewReference(data)}
        />
      </>
    );
  }, [newReference]);

  const modalNewReference = useMemo(() => {
    if (!newReference) return null;

    var buttonHeader = null;

    if (referenceActiveTab == TabPermissions) {
      buttonHeader = (
        <ButtonDynamic
          icon="plus"
          color="blue"
          actionClick={createAuthorization}
        >
          {textFromUid('button_insert_content_managed_autorization')}
        </ButtonDynamic>
      );
    } 
    else if (referenceActiveTab == TabProducts) {
      buttonHeader = (
        <ButtonDynamic
          icon="plus"
          color="blue"
          actionClick={syncProducts}
        >
          {textFromUid('button_change_product')}
        </ButtonDynamic>
      );
    }
    else if (referenceActiveTab == TabPreview) {
      buttonHeader = (
        <ButtonDynamic
          icon="plus"
          color="blue"
          actionClick={handleChangeContentReference}
        >
          {textFromUid('button_change_activity')}
        </ButtonDynamic>
      );
    }
    else if (referenceActiveTab == TabReferenceInfo) {
      buttonHeader = (
        <ButtonDynamic
          icon="plus"
          color="blue"
          actionClick={()=>{
            setNewReference({...newReference, state:"update"});
            syncReference();
          }}
        >
          {textFromUid('button_update_managed_content_reference')}
        </ButtonDynamic>
      );
    }

    return (
      <Components.Alerts.Modal
        title={textFromUid('managed_content_reference_modal_title_new')}
        boxWidth={'90%'}
        boxHeight={'80%'}
        onCloseClick={() => setNewReference(null)}
        closeIconColor="#764AB0"
        buttons={buttonHeader}
      >
        <TabNavigation
          options={referenceTabOptions}
          initialOptions={referenceTabOptions.indexOf(referenceActiveTab)}
          handleIndexSelected={handleReferenceTabSelected}
        />
        <Container>
        {referenceActiveTab == TabReferenceInfo && tabReferenceInformation()}
          {referenceActiveTab == TabPreview && tabPreview()}
          {referenceActiveTab == TabProducts && tabProducts}
          {referenceActiveTab == TabPermissions && tabPermissions}
        </Container>
      </Components.Alerts.Modal>
    );
  }, [
    newReference,
    referenceActiveTab,
    loadingContent,
    statusCodeReturnIframe,
  ]);

  const modalNewAuthorization = useMemo(() => 
  {
    if (!newAuthorization) return null;
    if (!schemas || !projects|| !products)
      return <Loading msg={textFromUid('loading_message')} />;

    const anonymous = newAuthorization.authorize_anonymous_users;
    const requiredProducts = newAuthorization.skip_required_products;
    const entitiesFields = { fields: [] };
    const currentEntities = {
      authorize_anonymous_users:
        anonymous != undefined ? anonymous.toString() : 'false',
      skip_required_products:
        requiredProducts != undefined ? requiredProducts.toString() : 'false',
      role: newAuthorization.role,
    };

    entitiesFields.fields.push({
      id: 'user_uid',
      group: textFromUid('user_uid_option'),
      required: true,
      type: 'input-text',
      config: {},
      field: 'user_uid',
      placeholder: '',
    });

    // entitiesFields.fields.push({
    //   id: 'role',
    //   group: textFromUid('managed_content_authorization_roles'),
    //   required: true,
    //   type: 'input-select',
    //   config: {},
    //   options: roles.map((r) => {
    //     var data = {
    //       value: r.uid,
    //       label: textFromUid(`role_type_${r.type.toLowerCase()}`),
    //     };
    //     return data;
    //   }),
    //   field: 'type',
    //   placeholder: 'Permissão',
    // });

    entitiesFields.fields.push({
      id: 'authorize_anonymous_users',
      originalName: 'skip_required_products',
      group: textFromUid('managed_content_authorize_anonymous_users'),
      type: 'input-option-v2',
      options: [
        { label: 'Sim', value: 'true' },
        { label: 'Não', value: 'false' },
      ],
      required: true,
    });

    entitiesFields.fields.push({
      id: 'skip_required_products',
      originalName: 'skip_required_products',
      group: textFromUid('managed_content_skip_required_products'),
      type: 'input-option-v2',
      options: [
        { label: 'Sim', value: 'true' },
        { label: 'Não', value: 'false' },
      ],
      required: true,
    });

    entitiesFields.fields.push({
      id: `projects`,
      type: 'input-multi-checkbox',
      group: textFromUid('managed_content_authorization_projects'),
      defaultValues: newAuthorization.references
        .filter((p) => p.type == 'Project')
        .map((p) => p.reference_uid),
      data: projects.map((project) => {
        return {
          name: project.name,
          value: project.uid,
          information: project.uid,
        };
      }),
    });

    entitiesFields.fields.push({
      id: `products`,
      type: 'input-multi-checkbox',
      group: textFromUid('managed_content_authorization_products'),
      defaultValues: newAuthorization.references
        .filter((p) => p.type == 'Product')
        .map((p) => p.reference_uid),
      data: products.map((product) => {
        return {
          name: product.name,
          value: product.uid,
          information: product.uid,
        };
      }),
    });

    for (const schmea of schemas.filter((p) => p.type == 'Security')) {
      entitiesFields.fields.push({
        id: `category-${schmea.uid}`,
        required: true,
        schema: schmea,
        type: 'input-schema-checkbox',
      });
    }

    schemas.forEach((schema) => {
      if (!schema.items) return;

      var values = [];
      newAuthorization.references.forEach((authorization) => {
        var found = schema.items.find(
          (item) => item.uid == authorization.reference_uid
        );
        if (!found) return;
        values.push(found.uid);
      });

      currentEntities[schema.uid] = values;
    });

    var userAuth = newAuthorization.references.find((p) => p.type == 'User');
    if (userAuth) currentEntities['user_uid'] = userAuth.reference_uid;

    return (
      <Components.Alerts.Modal
        title={textFromUid('managed_content_authorization_modal_header_title')}
        boxWidth={'90%'}
        boxHeight={'80%'}
        onCloseClick={() => setNewAuthorization(null)}
        closeIconColor="#764AB0"
        buttons={
          <ButtonDynamic
            color="blue"
            icon="plus"
            actionClick={changeAuthorization}
          >
            {textFromUid('update')}
          </ButtonDynamic>
        }
      >
        <ContainerContentModal>
          <ConteinerModalContent>
            <Components.Headers.TitleAndDescription
              title={textFromUid('managed_content_authorization_modal_title')}
              description={textFromUid(
                'managed_content_authorization_modal_description'
              )}
              padding="20px 0px 20px 0px"
            />

            <div class="padding-30-readjust">
              <DynamicForm
                form={entitiesFields}
                // submitText={textFromUid('update')}
                // handleSubmit={changeAuthorization}
                defaultValues={currentEntities}
                handleChanges={(data) => setAuthorizationConfg(data)}
              />
            </div>
          </ConteinerModalContent>
        </ContainerContentModal>
      </Components.Alerts.Modal>
    );
  }, [newAuthorization, schemas, projects, products]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <ContainerListUser>
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />

        {breadcrumb}

        <PageContainer>
          <TabNavigation
            options={tabOptions}
            handleIndexSelected={handleTabSelected}
          />

          {activeTab == TabInformations && tabInformation}
          {activeTab == TabCategories && tabCategories}
          {activeTab == TabContents && content && tabContents}
        </PageContainer>

        {!newAuthorization && modalNewReference}
        {modalNewAuthorization}

        {iframe && (
          <>
            <Mask />
            <Iframe
              onClickOutside={() => setIframe(null)}
              className="iframe-content"
              src={iframe}
            />
          </>
        )}
      </ContainerListUser>
    </>
  );
}
