import React, { useEffect, useState } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  ButtonDefault,
  IconCheck,
} from '../../components';
import { textFromUid } from '../../utils/textUtilities';

import { toast } from 'react-toastify';
import Components from 'rdp-builder-components';
import Sdk from 'api.digitalpages.module.sdk.api';

import './new-user.scss';

export default function EditUser({ consumer, match, history }) {
  const [firtName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [name, setName] = useState();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [processing, setProcessing] = useState(false);
  const [dynamicProperty, setDynamicProperty] = useState({});

  const [userRequiredProperties, setUserRequiredProperties] = useState();

  useEffect(() => {
    consumer.defineRoute({ name: 'Cadastrar Usuário', path: 'users/new' });
  }, []);

  useEffect(() => {
    getDirectory();
  }, []);

  const changeUserData = (key, value) => {
    switch (key) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'login':
        setLogin(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const handleInputChangeDynamicProperty = (event) => {
    const { name, value } = event.target;
    setDynamicProperty({
      ...dynamicProperty,
      [name]: value,
    });
  };

  const getDirectory = async () => {
    const directory = await Sdk.dynamic.bridge(
      `auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`,
      null,
      'GET'
    );

    setUserRequiredProperties(directory?.config?.user_required_properties);
  };

  const updateUser = async () => {
    setProcessing(true);

    var bodyData = {
      login: login,
      password: password,
      detail: {
        name: name,
        first_name: firtName,
        last_name: lastName,
      },
      user_properties: [],
    };

    for (var key in dynamicProperty) {
      bodyData.user_properties.push({
        name: key,
        value: dynamicProperty[key],
        type: 'text',
      });
    }

    toast
      .promise(
        Sdk.dynamic.bridge(
          `auth/v1.0/user/project/uid/${Sdk.authorization.activeProject.uid}`,
          bodyData,
          'POST'
        ),
        {
          pending: textFromUid(`pending_message_new_user`),
          success: textFromUid(`success_message_new_user`),
          error: textFromUid(`error_message_new_user`),
        }
      )
      .then((result) => history.push(`/users/${result.uid}`))
      .finally(() => setProcessing(false));
  };

  const allData =
    firtName?.length > 1 &&
    lastName?.length > 1 &&
    name?.length > 1 &&
    login?.length > 1 &&
    password?.length > 1;

  return (
    <>
      <div className="rdp-admin-edit-user">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          customNewOptionTitle="asds"
          currentRoute={consumer ? consumer.currentRoute : null}
        />

        <div className="rdp-admin-content rdp-admin-component-form-dynamic">
          <Components.Containers.ContainerUserNew
            onChangeData={changeUserData}
            processing={processing}
          />

          {userRequiredProperties?.map((field, index) => (
            <div key={index}>
              <div className="rdp-admin-new-user"> Propriedades dinâmica</div>

              <div className="rdp-admin-new-user-form">
                <div>
                  <span>{field.name}</span>

                  <div className="rdp-admin-new-user-input">
                    <input
                      value={dynamicProperty[field.name] || ''}
                      onChange={handleInputChangeDynamicProperty}
                      name={field.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="rdp-admin-button-new-user">
            <ButtonDefault
              text={textFromUid('button_new_user')}
              icon={<IconCheck />}
              disabled={!allData || processing}
              onClick={updateUser}
            />
          </div>

        </div>
      </div>
    </>
  );
}
