import React, { useState, useEffect, useMemo } from 'react';
import { Header, Sidebar, Breadcrumb, Loading } from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import Sdk from 'api.digitalpages.module.sdk.api';
import { objectToForm, toTreeObject } from '../../utils/formUtilities';
import { textFromUid } from '../../utils/textUtilities';
import { toast } from 'react-toastify';
import './new-schema.scss';

export default function NewSchema({ consumer, match, history }) {
  const [schema, setSchema] = useState({
    name: '',
    type: '',
    limit_depth_in_hierarchy: null,
    properties: [],
    tags: [],
  });

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_new_directory'),
      path: 'schema/new',
    });
  }, []);

  const handleSubmit = async (data) => {
    const res = await toast.promise(
      Sdk.dynamic.bridge('auth/v1.0/entity/schema/directory', data, 'POST'),
      {
        pending: textFromUid('success_message_schema_create'),
        success: textFromUid('pending_message_schema_create'),
        error: textFromUid('error_message_schema_create'),
      }
    );
  };

  const schemaOptions = useMemo(() => {
    if (!schema) return <Loading msg={textFromUid('loading_message')} />;

    var schemaObj = [
      {
        id: 'name',
        group: textFromUid('schema_name'),
        required: true,
        type: 'input-text',
        config: {},
        field: 'name',
        placeholder: 'Digite o nome',
      },
      {
        id: 'type',
        group: textFromUid('schema_type'),
        required: true,
        type: 'input-select',
        config: {},
        options: [
          {
            value: 'Category',
            label: textFromUid('schema_option_category'),
          },
          {
            value: 'Security',
            label: textFromUid('schema_option_security'),
          },
        ],
        defaultValue: 'Category',
        field: 'type',
        placeholder: 'Digite o tipo',
      },
      {
        id: 'limit_depth_in_hierarchy',
        group: textFromUid('schema_limit_depth_in_hierarchy'),
        type: 'input-text',
        inputType: 'number',
        config: {},
        field: 'limit_depth_in_hierarchy',
      },
      {
        id: 'properties',
        group: textFromUid('schema_properties'),
        required: true,
        type: 'input-properties',
        field: 'properties',
      },
      // {
      //   id: 'tags',
      //   group: textFromUid('schema_tags'),
      //   required: true,
      //   type: 'input-tags',
      //   field: 'tags',
      // },
      // {
      //   id: 'schema-public',
      //   group: 'Esquema público',
      //   required: true,
      //   type: 'input-checkbox',
      //   config: {},
      //   field: 'public-entity',
      //   placeholder: 'Digite o tipo da entidade',
      // },
    ];

    var options = objectToForm(schemaObj, {
      informations: [],
      namePrefix: 'schema_',
      handleType: (key) => {
        console.log('KEY ==>', key);

        if (key === 'properties') return 'input-properties';
        if (key === 'tags') return 'input-tags';
      },
    });

    const currentValues = {};
    options.forEach((field) => (currentValues[field.id] = field.currentValue));
    return (
      <DynamicForm
        handleSubmit={handleSubmit}
        form={{ fields: schemaObj }}
        submitText={textFromUid('button_new_schema')}
      />
    );
  }, []);

  return (
    <>
      <div className="rdp-admin-edit-user">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          customNewOptionTitle="asds"
          currentRoute={consumer ? consumer.currentRoute : null}
          // newOption={handleOpenModal}
          // customNewOptionTitle={textFromUid('button_update_password')}
        />

        <div className="rdp-admin-content rdp-admin-component-form-dynamic">
          {schemaOptions}
        </div>
      </div>
    </>
  );
}
