import React, { Component } from 'react';
import './breadcrumb.scss';
import ButtonDynamic from '../button-dynamic/button-dynamic';
import ButtonGrey from '../button-grey/button-grey';
import history from '../../services/history';
import { Link } from 'react-router-dom';
import { IconTrash } from '../icon';
import RDP_UTILS from '../../config/util';
import Select from 'react-select';
import { textFromUid } from '../../utils/textUtilities';

const BD_TITLES = {
  dashboard: 'Dashboard',
  users: textFromUid('breadcrumb_users'),
};

export default class Breadcrumb extends Component 
{

  constructor(props) {
    super(props);

    this.state = {
      emmbedMode: window.location.hash.lastIndexOf("/embed/") != -1 ? true : false
    };
  }

  componentDidUpdate() {}

  redirectToDashboard = () => {
    window.location = window.location.origin + window.location.pathname;
    // if (this.props.defineRoute) this.props.defineRoute(null);
    // RDP_UTILS.redirectTo();
  };

  redirectBack = () => {
    if (this.props.backRoute) history.push(this.props.backRoute);
  };

  renderTitle() {
    let title = 'Página';
    let rota = window.location.hash;
    // if (rota === '#/') {
    //   title = BD_TITLES.dashboard;
    // } else if (rota.indexOf('users') !== -1) {
    //   title = BD_TITLES.users;
    // }
    title = BD_TITLES.dashboard;
    return title;
  }

  render() {
    const {
      newOption,
      disabledNewOption,
      deleteOption,
      currentBreadcrumbTitle,
      currentRoute,
      currentGroup,
      backRoute,
      updateMode,
      options,
      optionSelected,
      customNewOptionTitle,
      importCsvEnabled = false,
      importCsvOnClick = function () {},
      buttonLoginAs,
      customTitleLoginAs,
      children,
      newMember,
      customNewMemberTitle
    } = this.props;
    const menuOptions =
      options &&
      options.map((option) => {
        let activeItem = option && option.find((v) => v.active == true);

        return (
          <Select
            placeholder={activeItem && activeItem.label}
            defaultValue={activeItem}
            options={option}
            styles={RDP_UTILS.MultipleSelect.styleFilter}
            isSearchable={false}
            onChange={(value) => optionSelected(value)}
            value={activeItem && activeItem.label}
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#fff',
              colors: {
                ...theme.colors,
                primary25: '#c7a5f5',
                primary: '#cccccc',
              },
            })}
          />
        );
      });

    let buttonNewTitle = `${textFromUid('breadcrumb_create')} ${
      currentRoute ? currentRoute.name : textFromUid('breadcrumb_entity')
    }`;
    if (customNewOptionTitle) buttonNewTitle = customNewOptionTitle;

    return (
      <div className={`rdp-admin-component-breadcrumb ${this.state.emmbedMode?"embed_breadcrumb": ""}`}>
        {/* <div className="breadcrumb-dashboard-button">
          <ButtonDynamic icon="home" actionClick={this.redirectToDashboard}>
            Dashboard
          </ButtonDynamic>
        </div> */}
        <div className="breadcrumb-content">
          <div className="breadcrumb-content-title">
            {backRoute ? (
              <Link to={backRoute} className="breadcrumb-btn-back">
                {'<'} {textFromUid('breadcrumb_back')}
              </Link>
            ) : (
              <span
                className={`rdp-admin-component-breadcrumb-icon ${
                  currentGroup && currentGroup.icon ? currentGroup.icon : 'home'
                }`}
              ></span>
            )}

            <span className="rdp-admin-component-breadcrumb-label">
              {currentBreadcrumbTitle ||
                (currentRoute ? currentRoute.name : this.renderTitle())}
              {updateMode && (
                <strong>{textFromUid('breadcrumb_edition')}</strong>
              )}
            </span>
          </div>

          <div className="breadcrumb-content-options">
            {buttonLoginAs && currentRoute && (
              <div style={{ paddingRight: '10px' }}>
                <ButtonDynamic
                  disabled={false}
                  color="blue"
                  icon="plus"
                  actionClick={buttonLoginAs}
                  iconDiv={true}
                >
                  {customTitleLoginAs}
                </ButtonDynamic>
              </div>
            )}
            {menuOptions}
            {importCsvEnabled && (
              <ButtonGrey
                label={textFromUid('breadcrumb_import_users')}
                icon="upload"
                onClick={() => importCsvOnClick()}
              />
            )}
            {newOption && currentRoute && (
              <ButtonDynamic
                disabled={disabledNewOption}
                color="blue"
                icon="plus"
                actionClick={newOption}
                iconDiv={true}
              >
                {buttonNewTitle}
              </ButtonDynamic>
            )}
            {newMember && currentRoute && (
              <ButtonDynamic
                color="blue"
                icon="plus"
                actionClick={newMember}
                iconDiv={true}
              >
                {customNewMemberTitle}
              </ButtonDynamic>
            )}
            {deleteOption && (
              <button
                data-tip="Excluir"
                onClick={deleteOption}
                className="btn-delete-notice"
              >
                <IconTrash />
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
}
