import React, { useRef } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';
import { TableDynamic } from '../../components';
import { RegisterItemDownload } from './register-item-download';

export const UserRegisterList = ({ paths, models, onUpdate }) => {
  const { projectKey, domain } = Sdk.Api.authorization;

  const createUrlFromRegister = (registerUid) => {
    const route = 'cms/v1.1/learning/user/register/uid';
    const final = `certificate?project_key=${projectKey}&api_env=${Sdk.Api.enviroment}`;
    const urlParts = [domain, route, registerUid, final];

    return urlParts.join('/');
  };

  return (
    <TableDynamic
      headers={paths}
      defaultFields={[]}
      data={models}
      hasCheckbox={false}
      hasEdit={true}
      renderCustom={(item) => {
        if (item.register_status !== 'Passou') return null;

        return <RegisterItemDownload register={item} url={createUrlFromRegister(item.uid)} />;
      }}
      onUpdate={onUpdate}
    />
  );
};
