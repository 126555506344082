import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  // padding: 0px 10px;
  overflow-y: hidden;
`;

export const ContainerNode = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 0px 10px;
`;

export const ContainerNodeTree = styled.div`
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px #e8ecf3;
`;

export const Mask = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000000bf;
  z-index: 10000;
`;

export const TabContainer = styled.div`
  width: 100%;
  padding: 20px;
`;
export const OverrideContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ActivityIframe = styled.iframe`
  width: 100%;
  height: calc(100% - 60px);
  background: #ededed;
  margin: 0px;
  padding: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow-y: hidden;
`;
