import React, { useState, useEffect, useMemo } from 'react';
import {
  Sidebar,
  Breadcrumb,
  Header,
  Footer,
  TableDynamic,
  MessageNoItemInTable,
  PageContainer,
  NoResult
} from '../../components';
import Loading from '../../components/loading';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import Sdk from 'api.digitalpages.module.sdk.api';
import Components from 'rdp-builder-components';
import { useTheme } from 'styled-components';

import { ContainerListSchema, ContainerTableSchema, Container } from './style';
import './styles.scss';

export default function ViewGroupList({ consumer }) {
  const [schemas, setSchemas] = useState(null);
  const [activeSchema, setActiveSchema] = useState(null);
  const [formIndex, setFormIndex] = useState(0);
  const [redirectTo, setRedirectTo] = useState(null);
  const [entity, setEntity] = useState(null);
  const [ authorizeCreateSchemas, setAuthorizeCreateSchemas ] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_schema_title'),
      path: '/schema',
    });

    getSchemas();
    
    var hasRoles = Sdk.hasRole([
      'AdministratorGlobal',
      'ContributorGlobal',
      'SchemaAdmin'
    ]);

    setAuthorizeCreateSchemas(hasRoles);
  }, []);

  const getSchemas = async () => {
    const project = Sdk.authorization.activeProject;
    
    if (!project) return;
    const directoryUid = project.directory.uid;

    const dataProject = await Sdk.dynamic.bridge(`auth/v1.1/entity/schema/available/management/project/uid/${project.uid}`,null,'GET');
    const dataDirectory = await Sdk.dynamic.bridge(`auth/v1.1/entity/schema/available/management/directory/uid/${directoryUid}`, null, 'GET');

    dataProject.forEach((data) => (data.scope = textFromUid('tab_group_schema_scope_project')));
    dataDirectory.forEach((data) => (data.scope = textFromUid('tab_group_schema_scope_directory')));

    var data = [...dataProject, ...dataDirectory];

    setSchemas(data);
  };

  const newSchemaProject = () => {
    let redirectTo = `/group/new`;
    setRedirectTo(redirectTo);
  };

  const handleEditSchema = (schemaUid) => {
    var schema = schemas.find((schema) => schema.uid == schemaUid);
    if (!schema) return;

    setActiveSchema(schema);
  };

  const handleDeleteSchema = async (schemaUid) => {
    await toast.promise(
      Sdk.dynamic.bridge(`auth/v1.1/entity/schema/uid/${schemaUid}/management`,null,
        'DELETE'
      ),
      {
        pending: textFromUid('pending_message_schema_delete'),
        success: textFromUid('success_message_schema_delete'),
        error: textFromUid('error_message_schema_delete'),
      }
    );

    var schemaUpdated = schemas.filter((schema) => schema.uid != schemaUid);
    setSchemas(schemaUpdated);
  };

  const handleViewLisEntity = async (schemaUid) => {
    let redirectTo = `/group/${schemaUid}`;
    setRedirectTo(redirectTo);
  };

  const schemaFields = useMemo(() => {
    if (!schemas) return <Loading msg={textFromUid('loading_message')} />;
    if (schemas == 0) return <NoResult/>;

    const orderFields = [
      'uid',
      'name',
      'type',
      'scope',
      'public',
      'self_register',
      'limit_depth_in_hierarchy',
      'created_at',
      'updated_at',
    ];

    const paths = objectPaths(schemas[0], {
      order: orderFields,
      namePrefix: 'table_header_schema_',
    });

    schemas.forEach((data) =>
      data.type === 'Category'
        ? (data.type = textFromUid('tab_group_schema_category'))
        : (data.type = textFromUid('tab_group_schema_security'))
    );

    const canEdit = Sdk.hasRole([
      'AdministratorGlobal',
      'SchemaAdmin',
      'ContributorGlobal',
      'EntityAdmin',
      'SchemaContributor',
      'EntityContributor'
    ]);

    const canRemove = Sdk.hasRole([
      'AdministratorGlobal',
      'ContributorGlobal',
      'SchemaAdmin',
      'EntityAdmin',
      'SchemaAdmin',
      'SchemaContributor'
    ]);

    return (
      
        <TableDynamic
          headers={paths}
          defaultFields={[]}
          data={schemas}
          hasCheckbox={false}
          hasView={false}
          hasEdit={canEdit}
          hasDelete={canRemove}
          onUpdate={handleViewLisEntity}
          onDelete={handleDeleteSchema}
        />
      
    );
  }, [schemas]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <ContainerListSchema>
      <Header />
      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
          { authorizeCreateSchemas && <ButtonDynamic color="blue" icon="plus" actionClick={newSchemaProject}>
            {textFromUid("button_new_schema")}
          </ButtonDynamic> }
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Components.Headers.TitleAndDescription
            title={textFromUid('schema_detail_title')}
            description={textFromUid('schema_detail_description')}
            padding="0px 0px 20px 0px"
          />

          {schemaFields}
        </Container>
      </PageContainer>

      {/* <ContainerTableSchema>{schemaFields}</ContainerTableSchema> */}
    </ContainerListSchema>
  );
}
