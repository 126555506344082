import React from 'react';
import Components from 'rdp-builder-components';

import './styles.scss';

export default function InputDefault({
  title,
  required,
  onChange,
  placeholder,
  type,
  name,
}) {
  return (
    <div className="row">
      <Components.Forms.InputText
        onChange={onChange}
        label={title}
        required={required}
        border="1px solid #d2d5dd;"
        fontColor="#000"
        borderRadius="4px"
        height="44px"
        placeholder={placeholder}
        type={type}
        name={name}
        colorIcon="#764AB0"
      />
    </div>
  );
}
