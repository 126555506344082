import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';
import {
  ContainerListCourse,
  ContainerTableCourse,
  Container,
  Mask,
} from './style';
import Components from 'rdp-builder-components';
import {
  Sidebar,
  Breadcrumb,
  Header,
  TableDynamic,
  MessageNoItemInTable,
  Iframe,
  NoResult,
  Pagination,
  PageContainer,
  Filter,
} from '../../components';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DAMService } from '../../services/dam';

export default function ListTree({ consumer, entity, history }) {
  const [courses, setCourses] = useState(null);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [damCallback, setDamCallback] = useState(null);
  const [iframe, setIframe] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [totalResults, setTotalResults] = useState(null);
  const [previousPageQuery, setPreviousPageQuery] = useState(null);

  const [coursesFilter, setCoursesFilter] = useState(null);
  const [coursesSearch, setCoursesSearch] = useState(null);
  const [lastRequest, setLastRequest] = useState(null);

  useEffect(() => {
    // // getTree();

    consumer.defineRoute({
      name: textFromUid('screen_course_title'),
      uri: '/tree',
    });
  }, []);

  useEffect(() => {
    getTree();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [coursesFilter, coursesSearch]);

  useEffect(() => {
    if (lastRequest) clearTimeout(lastRequest);
    setLastRequest(setTimeout(getTree, 1000));
  }, [currentPage, coursesFilter, coursesSearch]);

  useEffect(() => {
    if (!damCallback) {
      window.removeEventListener('message', handler);
      return;
    }
    var route = damCallback.route;
    if (!route) route = '/embed/files';

    var env = Sdk.enviroment == 'homolog' ? 'h' : 'p';
    const url = `${Sdk.domain}/product/dam/current/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/?access_token=${Sdk.authorization.credential.accessToken}&language=pt_br&file_type=package/scorm#${route}`;

    window.addEventListener('message', handler);

    setIframe(url);
  }, [damCallback]);

  const getTree = async () => {
    const { connectors } = await Sdk.authorization.activeProject;
    // setPreloader(true);
    const conectorUid = getConnectorFromArray(
      connectors,
      'Learning_1',
      'ContentManager'
    ).uid;

    let url = `cms/v1.0/learning/connector/uid/${conectorUid}/courses/management?page=${currentPage}`;

    if (coursesFilter) {
      for (const filter in coursesFilter) {
        const values = coursesFilter[filter].map((fv) => `${filter}=${fv}`);
        url += `&${values.join('&')}`;
      }
    }

    if (coursesSearch) url += `&search=${coursesSearch}`;
    const response = await Sdk.dynamic.bridge(url);

    setCurrentPage(response.current_page);
    setTotalPages(response.total_pages);
    setTotalResults(response.total_results);
    setCourses(response.result);
    setPreloader(false);
    setNextQuery(response.next_page_query);
  };

  const handler = useCallback(
    (event) => {
      if (!damCallback) return;

      const data = event.data;
      if (data && data.type != 'damfile' && !data.uid && !data.content_type)
        return;

      damCallback.result(data);
    },
    [damCallback]
  );

  function onUpdate(uid) {
    history.push(`tree/${uid}`);
  }

  const onClone = async (courseUid) => {
    await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${courseUid}/management/clone`,
        null,
        'POST'
      ),
      {
        pending: textFromUid(`pending_message_course_clone`),
        success: textFromUid(`success_message_course_clone`),
        error: textFromUid(`error_message_course_clone`),
      }
    );

    // // getTree();
  };

  const onDelete = async (courseUid) => {
    console.log("courseUid:", courseUid);
    var coursesUpdated = courses.filter((c) => c.uid != courseUid);

    setCourses([...coursesUpdated ]);

    await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${courseUid}/management`,
        null,
        'DELETE'
      ),
      {
        pending: textFromUid(`pending_message_course_delete`),
        success: textFromUid(`success_message_course_delete`),
        error: textFromUid(`error_message_course_delete`),
      }
    );

    getTree();
  };

  const onPreviousButtonClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextButtonClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  const filterOptions = useMemo(() => {
    var filters = [
      {
        uid: 'blocked_access',
        group: 'blocked_access',
        label: textFromUid('user_field_blocked_access'),
        children: [
          {
            uid: 'blocked',
            group: 'true',
            label: textFromUid('default_true'),
          },
          {
            uid: 'nblocked',
            group: 'false',
            label: textFromUid('default_false'),
          },
        ],
      },
      {
        uid: 'pending_confirm',
        group: 'pending_confirm',
        label: textFromUid('user_field_pending_confirm'),
        children: [
          {
            uid: 'blocked',
            group: 'true',
            label: textFromUid('default_true'),
          },
          {
            uid: 'nblocked',
            group: 'false',
            label: textFromUid('default_false'),
          },
        ],
      },
    ];

    return (
      <Filter
        //filtersValues={filters}
        onChange={(filterIds) => setCoursesFilter(filterIds)}
        onSearch={(text) => setCoursesSearch(text)}
      />
    );
  }, []);

  const courseFields = useMemo(() => {
    if (!courses) return <Loading msg={textFromUid('loading_message')} />;
    if (courses == 0) return <NoResult />;

    const orderFields = [
      'thumb_url',
      'uid',
      'title',
      'internal_name',
      'created_at',
      'updated_at',
    ];

    const paths = objectPaths(courses[0], {
      order: orderFields,
      namePrefix: 'course_field_',
      handleType: (key) => {
        if (key != 'thumb_url') return null;
        return 'image';
      },
    });

    return (
      <>
        {filterOptions}
        {preloader && <Loading msg={textFromUid('loading_message')} />}

        {courses == 0 ? (
          <MessageNoItemInTable message="table_user_no_item" />
        ) : (
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={courses}
            hasCheckbox={false}
            hasView={false}
            hasEdit={true}
            hasClone={true}
            hasDelete={true}
            onUpdate={onUpdate}
            onClone={onClone}
            onDelete={onDelete}
          />
        )}

        <Pagination
          currentPage={currentPage ? currentPage : 1}
          registerCount={totalPages}
          totalPages={totalPages}
          onPaginationButtonClick={(page) => handlePaginationClick(page)}
          onPreviousButtonClick={onPreviousButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      </>
    );
  }, [courses, filterOptions, preloader]);

  const handleNewCourse = () => {
    let redirectTo = `/tree/new`;
    setRedirectTo(redirectTo);
  };

  const handleImportCourse = () => {
    const callback = { result: null };

    new Promise((resolve, reject) => {
      callback.result = resolve;
    }).then(async (data) => {
      const publicUrl = await DAMService.getCourse(data.uid);

      setDamCallback(null);
      setIframe(null);

      window.location.reload();
    });

    setDamCallback(callback);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Container>
      <Header />
      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        newOption={handleNewCourse}
        buttonLoginAs={handleImportCourse}
        customNewOptionTitle={textFromUid('button_new_tree')}
        customTitleLoginAs={textFromUid('button_new_import_curse')}
      />

      <PageContainer>
        <Components.Headers.TitleAndDescription
          title={textFromUid('course_list_title')}
          description={textFromUid('course_list_description')}
          padding={'0 0'}
        />
        {courseFields}
      </PageContainer>

      {iframe && (
        <>
          <Mask />
          <Iframe
            onClickOutside={() => setIframe(null)}
            className="iframe-content"
            src={iframe}
          />
        </>
      )}
    </Container>
  );
}
