import React, { Component } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';
import Routes from './routes';

import './reset.css';
import './App.css';
import RDP_CONFIG from './config/config';
import Loading from './components/loading';
import { Footer } from './components';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';

class App extends Component 
{
  // state = {
  //   ready: false,
  //   loading: false,
  // };

  constructor(props) {
    super(props);

    this.state = 
    {
      ready: false,
      loading: false

    }

    this.loadConfig = this.loadConfig.bind(this);
    this.loadCustomOptions = this.loadCustomOptions.bind(this);
    this.loadCustomOptions();
  }

  componentWillMount() {
    const _this = this;
    const pathComponents = window.location.pathname.split('/');

    for (var i = 0; i < pathComponents.length; i++) {
      var component = pathComponents[i];
      if (component != 'config') continue;

      RDP_CONFIG.configBase64 = pathComponents[i + 1];
      const data = JSON.parse(atob(RDP_CONFIG.configBase64));
      this.loadConfig(data);
      break;
    }

    if (RDP_CONFIG.configBase64) return;

    fetch('./api_context.json').then(async (response) => {
      const config = await response.json();
      this.loadConfig(config);
    });
  }

  async loadConfig(data) {
    RDP_CONFIG.config = data;
    RDP_CONFIG.projectKey = data.project_key || data.pk;
    RDP_CONFIG.api_env = data.api_env;

    if (data.custom_login_background != undefined || data.clb != undefined) {
      RDP_CONFIG.customLoginBackground =
        data.custom_login_background || data.clb;
    }

    if (data.api_env != undefined || data.ae != undefined) {
      RDP_CONFIG.apiEnv = data.api_env || data.ae;
    }

    if (data.project_uid != undefined || data.pu != undefined) {
      RDP_CONFIG.projectUid = data.project_uid || data.pu;
    }

    if (data.domain != undefined) {
      RDP_CONFIG.domain = data.domain;
    }

    // if (RDP_CONFIG.directToken){
    //   UserService.setUser({access_token:RDP_CONFIG.directToken, type:"Bearer"});
    //   await UserService.setActiveProject(RDP_CONFIG.projectUid, "Bearer", RDP_CONFIG.directToken);
    // }

    this.setState({ loading: false });
    this.initialize();
  }

  async initialize() {
    var _this = this;

    if (RDP_CONFIG.config.domain != undefined) {
      Sdk.Api.domain = 'https://' + RDP_CONFIG.config.domain;
      //todo:corrigir sdk
      Sdk.Api.authorization.domain = Sdk.Api.domain;
      Sdk.Api.storage.domain = Sdk.Api.domain;
      Sdk.Api.store.domain = Sdk.Api.domain;
      Sdk.Api.insights.domain = Sdk.Api.domain;
      Sdk.Api.cms.domain = Sdk.Api.domain;
      Sdk.Api.iot.domain = Sdk.Api.domain;
      Sdk.Api.dynamic.domain = Sdk.Api.domain;
      Sdk.Api.dam.domain = Sdk.Api.domain;
      Sdk.Api.guide.domain = Sdk.Api.domain;
    }

    if (RDP_CONFIG.apiEnv != undefined) Sdk.setEnviroment(RDP_CONFIG.apiEnv);
    if (RDP_CONFIG.projectKey != undefined)
      Sdk.setProjectKey(RDP_CONFIG.projectKey);

    if (Sdk.Api.initialized === false) {
      const initializeFn = RDP_CONFIG.ProjectUid
        ? 'initializeFromProject'
        : 'initialize';
      const initializeParams = RDP_CONFIG.ProjectUid
        ? [RDP_CONFIG.ProjectUid, true]
        : [
            {
              initializeConnectors: true,
              autoInitializeProject: true,
              initializeProject: RDP_CONFIG.ProjectUid,
            },
            'News_1',
            'News_1',
          ];

      await Sdk.Api[initializeFn](...initializeParams);

  
      Sdk.Api.request.Request.handleError = async function (response) {
        if (response && response.status) {
          const { credential } = Sdk.Api.authorization;

          let currentTimestamp = new Date().getTime() / 1000;
          if (
            credential &&
            401 == response.status &&
            credential.expire &&
            currentTimestamp > credential.expire
          ) {
            window.dispatchEvent(
              new Event('@rdpdam-admin.event(logout)')
            );
          }
        }
      };
    } 

    if (RDP_CONFIG.directToken)
    {
      await Sdk.authorization.updateUserCredential({access_token : RDP_CONFIG.directToken}, true);
      window.location.search = "";
    }

    if (RDP_CONFIG.projectUid && (!Sdk.authorization.activeProject || Sdk.authorization.activeProject != RDP_CONFIG.projectUid))
    {
      var project = Sdk.authorization.projects.find(p=> p.uid == RDP_CONFIG.projectUid);
      if (project) await Sdk.authorization.setActiveProject(project);
    }

    if (!Sdk.Api.authorization.activeProject && Sdk.authorization.projects.length > 0)
    {
      console.log("project2:",  Sdk.authorization.activeProject);
      await Sdk.authorization.setActiveProject(Sdk.authorization.projects[0]);
    }

    Sdk.authorization.addStatusListen(_this.adjustRoute);

    this.setState({loading :false, ready: true});
    _this.adjustRoute();
  }

  loadCustomOptions()
  {
    if (window.location.search.length <= 1 || RDP_CONFIG.directToken) return;

    var search = window.location.search.substr(1, window.location.search.length);
    var params = search.split('&');
    var data = {};

    for (const index in params) {
      var param = params[index];
      var values = param.split("=");
      if (values.length <2) continue;

      data[values[0]] = values[1];
    }

    if (data["custom_options"])
    {
      var options = JSON.parse(atob(data["custom_options"]));
      if (options.hasOwnProperty("access_token")) RDP_CONFIG.directToken = options["access_token"];
      if (options.hasOwnProperty("mode")) RDP_CONFIG.customExibitionMode = options["mode"];
    }
    
    if (data["access_token"])
    {
      RDP_CONFIG.directToken = data["access_token"];
    }
  }

  adjustRoute() {
    if (Sdk.Api && Sdk.Api.authorization.authenticated) 
    {
      if(window.location.hash.indexOf("login") != -1) window.location = `${window.location.origin}${window.location.pathname}#/`;
    }
    else 
    {
      window.location = `${window.location.origin}${window.location.pathname}#/login`;
      // window.location = `${window.location.origin}${window.location.pathname}#/`;
    }
  }

  render() {
    const { loading } = this.state;

    if (loading) return <div></div>;

    return (
      <ThemeProvider theme={theme}>
        <div id="root" className="rdp-composer-player">
          {!this.state.ready ? (
            <Loading globalLoading={true} msg="Aguarde, carregando..." />
          ) : (
            <>
              <Routes />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                pauseOnHover={false}
                style={{ zIndex: '10000' }}
                theme="colored"
              />
            </>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
