import styled from 'styled-components';

export const ContainerTableAccessExternal = styled.div`
  background: #f5f7f8;
  position: absolute;
  top: 135px;
  left: 230px;
  bottom: 0px;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  width: auto;
`;

export const Container = styled.div`
  width: 100%;
`;
