import React, { useState, useEffect } from 'react';
import './edit-review.scss';
import {
  Header,
  Sidebar,
  Breadcrumb,
  NotifyBox,
  Footer,
  Loading,
  TableDynamic,
  HeaderFilter,
  Pagination,
  Filter,
  ModalReview,
  ListRubric,
} from '../../components';
import { IconNone } from '../../components/icon';
import { GenericList } from '../../builders/list';
import DynamicForm from '../../components/form-dynamic-v2';
import _, { filter } from 'underscore';
import Sdk from 'api.digitalpages.module.sdk.api';

const CheckIcon = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM15.768 9.64C15.8558 9.53964 15.9226 9.42274 15.9646 9.29617C16.0065 9.1696 16.0227 9.03591 16.0123 8.90298C16.0018 8.77005 15.9648 8.64056 15.9036 8.52213C15.8423 8.40369 15.758 8.29871 15.6555 8.21334C15.5531 8.12798 15.4346 8.06396 15.3071 8.02506C15.1796 7.98616 15.0455 7.97316 14.9129 7.98683C14.7802 8.00049 14.6517 8.04055 14.5347 8.10463C14.4178 8.16872 14.3149 8.25554 14.232 8.36L9.932 13.519L7.707 11.293C7.5184 11.1108 7.2658 11.01 7.0036 11.0123C6.7414 11.0146 6.49059 11.1198 6.30518 11.3052C6.11977 11.4906 6.0146 11.7414 6.01233 12.0036C6.01005 12.2658 6.11084 12.5184 6.293 12.707L9.293 15.707C9.39126 15.8052 9.50889 15.8818 9.63842 15.9321C9.76794 15.9823 9.9065 16.005 10.0453 15.9986C10.184 15.9923 10.32 15.9572 10.4444 15.8954C10.5688 15.8337 10.6791 15.7467 10.768 15.64L15.768 9.64Z"
        fill="white"
      />
    </svg>
  );
};

export default function NewReview({ consumer, history, match }) {
  const [entity, setEntity] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [minimumGrade, setMinimumGrade] = useState();
  const [formIndex, setFormIndex] = useState(0);
  const [table, setTable] = useState();
  const [tableData, setTableData] = useState();
  const [tableRubric, setTableRubric] = useState();
  const [dataRubric, setDataRubric] = useState();
  const [filteredTableData, setFilteredTableData] = useState();
  const [filterIds, setFilterIds] = useState();
  const [messages, setMessages] = useState({});
  const [preloader, setPreloader] = useState(true);

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [activedReviewTask, setActivedReviewTask] = useState(null);
  const [filterValues, setFilterValues] = useState([]);
  const [isPaginated, setIsPaginated] = useState(true);
  const [currentPage, setCurrentPage] = useState(false);
  const [totalPages, setTotalPages] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [previousPageQuery, setPreviousPageQuery] = useState(null);
  const [totalResults, setTotalResults] = useState(null);

  useEffect(() => {
    getFormFields();
    loadTable();
    loadReviewFlow();
    loadTableRubric();
  }, []);

  useEffect(() => {
    updateTable();
  }, [filterIds]);

  const updateTable = async () => {
    if (!filterIds) {
      setFilteredTableData(undefined);
      return;
    }
    if (Object.keys(filterIds).length === 0) {
      await loadTable();
    } else {
      const params = new URLSearchParams(filterIds);
      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/review/flow/uid/${
          match.params.flowUid
        }/management/tasks?${params.toString()}`
      );
      await loadTable(res);
    }
  };
  const getFormFields = async () => {
    const response = await fetch('./preferences.json');

    const { forms, tables } = await response.json();

    setTableRubric(tables.rubric);
    setTable(tables.submissions);
    setEntity(forms['new-review']);

    consumer.defineRoute({
      name: 'Editar revisão',
    });
  };

  const loadReviewFlow = async () => {
    const reviewFlow = await Sdk.dynamic.bridge(
      `/cms/v1.0/review/flow/uid/${match.params.flowUid}`
    );
    const { title, description, minimum_note } = reviewFlow;
    setTitle(title);
    setDescription(description);
    setMinimumGrade(minimum_note);
    // cms/v1.0/review/flow/uid/{{ReviewFlowUid}}/
  };

  const getStatus = (status) => {
    const statuses = {
      WaitingUser: 'Aguardando Aluno',
      WaitingReviewer: 'Aguardando Revisor',
      InQueue: 'Na fila, aguardando revisão',
      Passed: 'Aprovado',
      Failed: 'Reprovado',
      UserQuestioning: 'Aluno enviou uma mensagem',
      ReviewerQuestioning: 'Revisor enviou uma mensagem',
    };
    return statuses[status] || status;
  };
  const getState = (state) => {
    const states = {
      Inactive: 'Inativo',
      Active: 'Ativo',
    };
    return states[state] || state;
  };

  // var uniqUsers = _.uniq(submissions, function (submission) {
  //   return submission.target_user_uid;
  // });

  // //VARRE AS SUBMISSÕES UNIFICADAS POR TARGET_USER_UID E ATRIBUI AS INFORMAÇÕES DO USUÁRIO.
  // for (var i = 0; i < uniqUsers.length; i++) {
  //   try {
  //     var tempUser = await Sdk.dynamic.bridge(
  //       `auth/v1.0/user/uid/${uniqUsers[i].target_user_uid}`
  //     );

  //     uniqUsers[i].user_email = tempUser.detail.email_principal;
  //   } catch {
  //     uniqUsers[i].user_email = 'Usuário não existente';
  //   }
  // }

  const mountFilter = async (items) => {
    const res = await Sdk.dynamic.bridge(
      `cms/v1.0/review/flow/uid/${match.params.flowUid}/management/tasks/facets`
    );

    const usersInfo = [];

    for (const user of res.authors) {
      try {
        const userInfo = await Sdk.dynamic.bridge(`auth/v1.0/user/uid/${user}`);
        usersInfo.push({
          uid: userInfo.uid,
          group: userInfo.uid,
          label: userInfo.detail.email_principal,
        });
      } catch (error) {
        // setNoContent(true)
        // setMsgError('Usuário não existe mais')
        // return
      }
    }
    const nodeItems = new Set();
    const courseItems = new Set();
    const offerItems = new Set();
    const activityItems = new Set();
    const addedItemsUids = {};
    for (let i = 0; i < items.length; i++) {
      if (!addedItemsUids[items[i].course_uid]) {
        courseItems.add({
          uid: items[i].course_uid,
          group: items[i].activity_uid,
          label: items[i].course_name,
        });
        addedItemsUids[items[i].course_uid] = true;
      }
      if (!addedItemsUids[items[i].node_uid]) {
        nodeItems.add({
          uid: items[i].node_uid,
          group: items[i].activity_uid,
          label: items[i].node_name,
        });
        addedItemsUids[items[i].node_uid] = true;
      }

      if (!addedItemsUids[items[i].offer_uid]) {
        offerItems.add({
          uid: items[i].offer_uid,
          group: items[i].offer_uid,
          label: items[i].offer_name,
        });
        addedItemsUids[items[i].offer_uid] = true;
      }
      if (!addedItemsUids[items[i].activity_uid]) {
        activityItems.add({
          uid: items[i].activity_uid,
          group: items[i].activity_uid,
          label: items[i].activity_name,
        });
        addedItemsUids[items[i].activity_uid] = true;
      }
    }
    const filterItems = [];

    filterItems.push({
      uid: 'courses',
      group: 'target_uid',
      label: 'Cursos',
      children: [
        ...Array.from(courseItems).map((course) => ({
          uid: course.uid,
          group: course.group,
          label: course.label,
        })),
      ],
    });

    filterItems.push({
      uid: 'offers',
      group: 'context_uid',
      label: 'Ofertas',
      children: Array.from(offerItems).map((offer) => ({
        uid: offer.uid,
        group: offer.group,
        label: offer.label,
      })),
    });

    filterItems.push({
      uid: 'node',
      group: 'target_uid',
      label: 'Nó',
      children: Array.from(nodeItems).map((node) => ({
        uid: node.uid,
        group: node.group,
        label: node.label,
      })),
    });

    filterItems.push({
      uid: 'target_uid',
      label: 'Atividades',
      children: Array.from(activityItems).map((activity) => ({
        uid: activity.uid,
        group: activity.group,
        label: activity.label,
      })),
    });

    filterItems.push({
      uid: 'author_uid',
      group: 'author_uid',
      label: 'E-mail do Usuário',
      children: usersInfo,
    });

    filterItems.push({
      uid: 'status',
      group: 'status',
      label: 'Status',
      children: res.status.map((status) => ({
        uid: status,
        group: status,
        label: getStatus(status),
      })),
    });

    filterItems.push({
      uid: 'state',
      group: 'state',
      label: 'Estado',
      children: res.states.map((state) => ({
        uid: state,
        group: state,
        label: getState(state),
      })),
    });

    setFilterValues(filterItems);
    setLoadingFilter(false);
  };

  // const loadFilter = async () => {
  //   const res = await Sdk.dynamic.bridge(
  //     `cms/v1.0/review/flow/uid/${match.params.flowUid}/management/tasks/facets`
  //   );
  //   const usersInfo = [];
  //   for (const user of res.authors) {
  //     const userInfo = await Sdk.dynamic.bridge(`auth/v1.0/user/uid/${user}`);
  //     usersInfo.push({
  //       uid: userInfo.uid,
  //       label: userInfo.detail.email_principal,
  //     });
  //   }
  // };

  const loadTable = async (tableData) => {
    setLoadingTable(true);
    const { connectors } = Sdk.Api.authorization.activeProject;
    const tmpStructureCp = { ...entity };

    let submission = [];

    const reviewConnector = connectors.find(
      (connector) => connector.type === 'Review_1'
    );

    if (!reviewConnector) {
      setMsgError('Erro ao carregar conector');
      return;
    }

    //RETORNA A LISTA DE SUBMISSÕES DE UMA ATIVIDADE DE REVISÃO.
    let tempSubmissions = tableData;
    if (!tableData) {
      tempSubmissions = await Sdk.dynamic.bridge(
        `cms/v1.0/review/flow/uid/${match.params.flowUid}/management/tasks`
      );
    }

    var submissions = tempSubmissions.result;
    for (var i = 0; i < submissions.length; i++) {
      submissions[i].status = getStatus(submissions[i].status);
      submissions[i].state = submissions[i].state === 'Active' ? 'Sim' : 'Não';
    }

    //UNIFICA AS SUBMISSÕES COM BASE NO TARGET_UID PARA QUE NÃO SEJAM FEITAS REQUISIÇÕES DUPLICADAS NA API.
    var uniqSubmissions = _.uniq(submissions, function (submission) {
      return submission.target_uid;
    });

    //VARRE AS SUBMISSÕES UNIFICADAS POR TARGET_UID E ATRIBUI AS INFORMAÇÕES DE CURSO, NÓ E ATIVIDADE.
    for (var i = 0; i < uniqSubmissions.length; i++) {
      try {
        var tempReview = await Sdk.dynamic.bridge(
          `cms/v1.0/learning/activity/uid/${uniqSubmissions[i].target_uid}/management/references`
        );

        uniqSubmissions[i].author_uid = tempReview.activity.author_uid;
        uniqSubmissions[i].course_name = tempReview.course.title;
        uniqSubmissions[i].course_uid = tempReview.course.uid;
        uniqSubmissions[i].node_name = tempReview.node.data.title;
        uniqSubmissions[i].node_uid = tempReview.node.uid;
        uniqSubmissions[i].activity_name = tempReview.activity.name;
        uniqSubmissions[i].activity_uid = tempReview.activity.uid;
      } catch {
        // tempActivity[i].user_email = 'Usuário não existente';
      }
    }

    //VARRE TODAS AS SUBMISSÕES E ATRIBUI AS INFORMAÇÕES CONTIDAS NAS SUBMISSÕES UNIFICADAS, COM BASE NO TARGET_UID.
    for (var i = 0; i < submissions.length; i++) {
      for (var j = 0; j < uniqSubmissions.length; j++) {
        if (submissions[i].target_uid === uniqSubmissions[j].target_uid) {
          submissions[i].author_uid = uniqSubmissions[j].author_uid;
          submissions[i].course_name = uniqSubmissions[j].course_name;
          submissions[i].course_uid = uniqSubmissions[j].course_uid;
          submissions[i].node_name = uniqSubmissions[j].node_name;
          submissions[i].node_uid = uniqSubmissions[j].node_uid;
          submissions[i].activity_name = uniqSubmissions[j].activity_name;
          submissions[i].activity_uid = uniqSubmissions[j].activity_uid;
        }
      }
    }

    //UNIFICA AS SUBMISSÕES POR USUÁRIO COM BASE NO AUTHOR_UID.
    var uniqUsers = _.uniq(submissions, function (submission) {
      return submission.target_user_uid;
    });

    //VARRE AS SUBMISSÕES UNIFICADAS POR TARGET_USER_UID E ATRIBUI AS INFORMAÇÕES DO USUÁRIO.
    for (var i = 0; i < uniqUsers.length; i++) {
      try {
        var tempUser = await Sdk.dynamic.bridge(
          `auth/v1.0/user/uid/${uniqUsers[i].target_user_uid}`
        );

        uniqUsers[i].user_email = tempUser.detail.email_principal;
      } catch {
        uniqUsers[i].user_email = 'Usuário não existente';
      }
    }

    //VARRE TODAS AS SUBMISSÕES E ATRIBUI AS INFORMAÇÕES CONTIDAS NAS SUBMISSÕES UNIFICADAS, COM BASE NO TARGET_UID.
    for (var i = 0; i < submissions.length; i++) {
      for (var j = 0; j < uniqUsers.length; j++) {
        if (submissions[i].target_user_uid === uniqUsers[j].target_user_uid) {
          submissions[i].user_email = uniqUsers[j].user_email;
        }
      }
    }

    //UNIFICA AS SUBMISSÕES POR OFERTA COM BASE NO CONTEXT_UID.
    var uniqOffers = _.uniq(submissions, function (submission) {
      return submission.context_uid;
    });

    //VARRE AS SUBMISSÕES UNIFICADAS POR CONTEXT_UID E ATRIBUI AS INFORMAÇÕES DA OFERTA.
    for (var i = 0; i < uniqOffers.length; i++) {
      try {
        var tempOffer = await Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${uniqOffers[i].context_uid}/management`
        );
        uniqOffers[i].offer_name = tempOffer.name;
        uniqOffers[i].offer_uid = tempOffer.uid;
      } catch {
        uniqOffers[i].offer_name = 'Nome não encontrado';
      }
    }

    //VARRE TODAS AS SUBMISSÕES E ATRIBUI AS INFORMAÇÕES CONTIDAS NAS SUBMISSÕES UNIFICADAS, COM BASE NO CONTEXT_UID.
    for (var i = 0; i < submissions.length; i++) {
      for (var j = 0; j < uniqOffers.length; j++) {
        if (submissions[i].context_uid === uniqOffers[j].context_uid) {
          submissions[i].offer_name = uniqOffers[j].offer_name;
          submissions[i].offer_uid = uniqOffers[j].offer_uid;
        }
      }
    }
    if (submissions.length == 0) {
      setNoContent(true);
      return;
    }
    submissions.forEach(async (uidSchemas) => {
      tmpStructureCp.result = Array.isArray(tmpStructureCp.result)
        ? [...tmpStructureCp.result, { ...uidSchemas }]
        : [{ ...uidSchemas }];

      if (submission.length == 0) {
        setMessages({ noContent: 'Nenhuma submissão registrada' });
      }
    });

    await mountFilter(submissions);
    setTableData(submissions);

    setCurrentPage(tempSubmissions.current_page);
    setTotalPages(tempSubmissions.total_pages);
    setNextQuery(tempSubmissions.next_page_query);
    setTotalResults(tempSubmissions.total_results);
    setPreloader(false);

    setLoadingTable(false);
  };

  const loadTableRubric = async (tableDataRubric) => {
    setLoadingTable(true);
    let tempRubrics = tableDataRubric;

    const tmpStructureCp = { ...entity };

    if (!tableDataRubric) {
      tempRubrics = await Sdk.dynamic.bridge(
        `cms/v1.0/review/flow/uid/${match.params.flowUid}`
      );
    }

    var rubric = tempRubrics.rubrics;

    rubric.forEach(async (uidSchemas) => {
      tmpStructureCp.result = Array.isArray(tmpStructureCp.result)
        ? [...tmpStructureCp.result, { ...uidSchemas }]
        : [{ ...uidSchemas }];

      if (rubric.length == 0) {
        setMessages({ noContent: 'Nenhuma rubrica registrada' });
      }
    });

    setDataRubric(rubric);

    setPreloader(false);

    setLoadingTable(false);
  };

  const handleSubmit = async () => {
    try {
      const reviewDto = {
        title,
        description,
        minimum_note: minimumGrade,
      };
      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/review/flow/uid/${match.params.flowUid}/management`,
        reviewDto,
        'PUT'
      );
      setMsgSuccess('Fluxo de revisão atualizado com sucesso');
    } catch (error) {
      setMsgError('Algo deu errado, tente novamente');
    }
  };

  // const handleSubmit = async (data) => {};
  const handleUpdate = () => {};

  const handleViewDownload = async (submissionUid) => {
    const list = Array.isArray(filteredTableData)
      ? filteredTableData
      : tableData;

    const reviewTask = list.find((item) => item.uid === submissionUid);

    setActivedReviewTask(reviewTask);
  };

  const handleClose = () => {
    setActivedReviewTask(null);
  };

  const getCounter = (elements, filters) => {
    return (
      (filters.page - 1) * elements.page_size +
      (elements.result ? elements.result.length : 0)
    );
  };
  const onPreviousButtonClick = () => {
    console.log('inside onPreviousButtonClick');
    if (previousPageQuery) {
      loadTable(null, previousPageQuery);
      setPreviousPageQuery(null);
    }
  };
  const onNextButtonClick = () => {
    console.log('inside onNextButtonClick');
    if (nextQuery) {
      loadTable(null, nextQuery);
      setNextQuery(null);
    }
  };

  return (
    <>
      {activedReviewTask && (
        <ModalReview
          open={activedReviewTask}
          onClose={handleClose}
          reviewTask={activedReviewTask}
        />
      )}

      {true ? (
        <div className="rdp-admin-list-content">
          <Header />
          <Sidebar
            currentRoute="/review/new"
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {loadingSubmit && <Loading msg="Atualizando revisão..." />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type="success"
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type="error"
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            <div className="test">
              <main className="new-review-main-content-area">
                <div className="new-review-fields-wrapper">
                  <div className="form-buttons">
                    <button
                      className={`tab-button ${
                        formIndex === 0 ? 'active' : null
                      }`}
                      onClick={() => setFormIndex(0)}
                    >
                      Informações
                    </button>
                    {/* <div className="divider" /> */}
                    <button
                      className={`tab-button ${
                        formIndex === 1 ? 'active' : null
                      }`}
                      onClick={() => setFormIndex(1)}
                    >
                      Submissões
                    </button>
                    <button
                      className={`tab-button ${
                        formIndex === 2 ? 'active' : null
                      }`}
                      onClick={() => setFormIndex(2)}
                    >
                      Rubricas
                    </button>
                  </div>
                  <div className={`item ${formIndex === 0 ? 'open' : null}`}>
                    <div className="row">
                      <label>
                        <span>Título</span>
                        <span style={{ color: 'red' }}>*</span>
                        <input
                          type="text"
                          placeholder="Título"
                          className="text-input"
                          required={true}
                          value={title}
                          onChange={(evt) => setTitle(evt.target.value)}
                        />
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        <span>Descrição</span>
                        <span style={{ color: 'red' }}>*</span>

                        <textarea
                          rows={4}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required={true}
                        >
                          Título
                        </textarea>
                      </label>
                    </div>

                    <div className="row">
                      <label>
                        <span>Nota mínima</span>
                        <span style={{ color: 'red' }}>*</span>
                        <input
                          type="number"
                          placeholder="Nota mínima"
                          className="text-input"
                          required={true}
                          value={minimumGrade}
                          onChange={(evt) => setMinimumGrade(evt.target.value)}
                        />
                      </label>
                    </div>
                  </div>

                  <div className={`item ${formIndex === 1 ? 'open' : null}`}>
                    <>
                      {noContent ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              marginBottom: 20,
                            }}
                          >
                            <span
                              style={{
                                background: '#764ab0',
                                borderRadius: '50%',
                                width: '120px',
                                height: '120px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <IconNone />
                            </span>
                            <span
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: '#764ab0',
                                marginTop: 12,
                              }}
                            >
                              {msgError
                                ? msgError
                                : 'Nenhuma submissão encontrada'}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {!loadingFilter && (
                            <Filter
                              filtersValues={filterValues}
                              onChange={(filterIds) => setFilterIds(filterIds)}
                            />
                          )}
                          {tableData && !loadingTable ? (
                            <>
                              <div
                                className="info-counter"
                                style={{
                                  paddingLeft: 20,
                                  textTransform: 'uppercase',
                                  fontSize: 11,
                                }}
                              >
                                Mostrando <strong>{tableData.length}</strong> de{' '}
                                <strong>{totalResults}</strong>
                              </div>
                              <TableDynamic
                                title={'Submissões'}
                                configuration={table.details}
                                headers={table.fields || []}
                                defaultFields={[]}
                                data={tableData}
                                hasCheckbox={false}
                                hasDelete={false}
                                hasClone={false}
                                hasView={true}
                                onUpdate={(e) => {}}
                                onDelete={(e) => {}}
                                onClone={() => {}}
                                handleView={handleViewDownload}
                              />
                            </>
                          ) : (
                            <Loading msg="Carregando Tabela..." />
                          )}
                        </>
                      )}
                    </>
                    {isPaginated && (
                      <Pagination
                        currentPage={currentPage ? currentPage : 1}
                        registerCount={totalPages}
                        totalPages={totalPages}
                        // onPaginationButtonClick={(page) => handlePaginationClick(page)}
                        onPreviousButtonClick={onPreviousButtonClick}
                        onNextButtonClick={onNextButtonClick}
                      />
                    )}
                  </div>

                  <div className={`item ${formIndex === 2 ? 'open' : null}`}>
                    <>
                      {dataRubric ? (
                        <ListRubric ItemRubric={dataRubric} />
                      ) : (
                        <Loading msg="Carregando Rubrica..." />
                      )}
                    </>
                  </div>
                </div>
              </main>
              <footer className="new-review-footer">
                <button className="register-button" onClick={handleSubmit}>
                  <span>
                    <CheckIcon />
                  </span>
                  <div>Salvar alterações</div>
                </button>
              </footer>
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  );
}
