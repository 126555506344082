import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import Sdk from 'api.digitalpages.module.sdk.api';
import RDP_CONFIG from '../config/config';

const userService = function () {
  this.getUserDecoded = function () {
    console.log("/////////////////////");
    // if (window.localStorage.getItem('rdp-manager-user-data') !== null) {
    //   return jwtDecode(
    //     JSON.parse(window.localStorage.getItem('rdp-manager-user-data'))
    //       .access_token,
    //   );
    // }
    return null;
  };

  this.getUser = function () { return Sdk.Api.authorization.user; };

  this.setUser = function (data) {};

  this.getProjects = function () { return Sdk.Api.authorization.projects; };

  this.setProjects = function (data) { };

  this.getActiveProject = function () { return Sdk.authorization.activeProject; };

  this.getConnector = function () {
    const { activeProject } = Sdk.Api.authorization;

    let connector = null;
    if (activeProject && activeProject.connectors.length > 0) {
      connector = activeProject.connectors.filter(
        (con) => con.type === RDP_CONFIG.connectorType,
      );
    }
    return connector && connector[0] ? connector[0].uid : null;
  };

  this.setActiveProject = function (uid, type, accessToken) 
  {
    var project = Sdk.Api.authorization.projects.find(p=> p.uid == uid);
    return Sdk.Api.authorization.setActiveProject(project);
  };

  return this;
};

const UserService = new userService();

export { UserService };
