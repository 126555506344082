import styled from 'styled-components';

export const ContainerListEntity = styled.div``;

export const ContainerTableEntity = styled.div`
  background: #f5f7f8;
  position: absolute;
  top: 135px;
  left: 230px;
  bottom: 0px;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  width: auto;
`;

export const Container = styled.div`
  // display: flex;
  width: 100%;
  //height: 100%;
  //justify-content: space-between;
  //padding: 0px 10px;
  //overflow-y: hidden;
`;

export const ContainerNodeEntity = styled.div`
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px #e8ecf3;
`;

export const TabContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  background: #fff;
`;

export const ContainerSearch = styled.div`
  ${'' /* padding: 0px 18px; */}
`;

export const ContainerInput = styled.div`
  display: flex;

  input {
    width: 100%;
    outline: none;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 5px;
    border: 1px solid #d2d5dd;
    padding: 15px;
    color: #000;
    height: 44px;
  }
`;

export const ContainerResultSearch = styled.div`
  position: absolute;
  margin-top: 5px;
  width: 70%;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  div {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;

    &:hover {
      background-color: #774aaf;
      color: #fff;
    }

    p {
      margin-left: 10px;
    }
  }
`;

export const ContainerListUser = styled.div`
  height: 85%;
  overflow: hidden;
  overflow-y: auto;
  ${'' /* padding: 0px 6px; */}
  margin: 11px 0px;

  ul {
    div {
      li {
        width: 100%;
        padding: 0.75rem 0px;
      }
    }
  }
`;

export const ContainerItens = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #abb0c0;
  background: #dfe1e7;
`;

export const ContainerButton = styled.div`
  position: absolute;
  margin-top: 727px;
  margin-left: 488px;
`;
