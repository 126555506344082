import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { textFromUid } from '../../utils/textUtilities';
import {
  Breadcrumb,
  Header,
  Loading,
  MessageNoItemInTable,
  PageContainer,
  Sidebar,
  TableDynamic,
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import { objectPaths } from '../../utils/formUtilities';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import Components from 'rdp-builder-components/lib/components';

export default function ViewProjectList({ consumer, match, history }) {
  const [redirectTo, setRedirectTo] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    let projectData = await Sdk.dynamic.bridge(`auth/v1.0/projects`);

    setProject(projectData);
  };

  const updatedProject = (uid) => {
    if (uid) {
      let redirectTo = `/project/${uid}`;

      setRedirectTo(redirectTo);
    }
  };

  const projectFields = useMemo(() => {
    if (!project) return <Loading msg={textFromUid('loading_message')} />;

    const orderFields = ['uid', 'name', 'created_at', 'updated_at'];

    const paths = objectPaths(project[0], {
      order: orderFields,
      namePrefix: 'project_field_',
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('list_projects_title')}
          description={textFromUid('list_projects_description')}
          padding="20px 0px 20px 0px"
        />
        {project == 0 ? (
          <MessageNoItemInTable message="table_user_no_item" />
        ) : (
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={project}
            hasCheckbox={false}
            hasView={false}
            hasEdit={true}
            onUpdate={(uidProject) => {
              updatedProject(uidProject);
            }}
          />
        )}
      </>
    );
  }, [project]);

  const newProject = () => {
    let redirectTo = `/project/new`;
    setRedirectTo(redirectTo);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <div>
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null}>
          <ButtonDynamic actionClick={newProject} color="blue" icon="plus">
            {textFromUid('button_new_project')}
            </ButtonDynamic>
        </Breadcrumb>

        <PageContainer>{projectFields}</PageContainer>
      </div>
    </>
  );
}
