import React from 'react'
import { IconSearch } from '../'
import {Container,ContainerIcon,ContainerText,ContainerSub} from './style';
import { textFromUid } from '../../utils/textUtilities';

export default function NoResult({
    text = textFromUid("default_message_empty_result")
}) {
  return (
    <center>
        <Container>
          <ContainerSub>
              <ContainerIcon>
                  <IconSearch/>
              </ContainerIcon>
              <ContainerText>
                {text}
              </ContainerText>
            </ContainerSub>
        </Container>
    </center>
  )
}