import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  createRef,
} from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  Iframe,
  NotifyBox,
  TableDynamic,
  ButtonDefault,
  IconUpload,
  IconAdd,
  NoResult,
  PageContainer,
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import Components from 'rdp-builder-components';
import useAuthToken from '../../hooks/useAuthToken';
import { useTheme } from 'styled-components';
import {
  objectToForm,
  toTreeObject,
  updateValues,
  objectPaths,
} from '../../utils/formUtilities';
import { textFromUid } from '../../utils/textUtilities';
import { DAMService } from '../../services/dam';
import { toast } from 'react-toastify';

import {
  Container,
  ContainerNode,
  Mask,
  TabContainer,
  OverrideContainer,
  ActivityIframe,
  ContainerNodeTree,
} from './style';

import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import defaultImage from './image/default.png';

export default function EditTreeV2({ consumer, match, history }) {
  const [formIndex, setFormIndex] = useState(0);
  const [preloader, setPreloader] = useState(true);
  const [entity, setEntity] = useState();
  const [tableData, setTableData] = useState();
  const [registers, setRegisters] = useState([]);
  const [reviewFlows, setReviewFlows] = useState(null);
  const [activeRegister, setActiveRegister] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [iframe, setIframe] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState();
  const [msgError, setMsgError] = useState('');
  const token = useAuthToken();
  const [products, setProducts] = useState([]);
  const [nodeOverrides, setNodeOverrides] = useState(null);
  const [schemas, setSchemas] = useState(null);
  const [modalConfirmationConfig, setModalConfirmationConfig] = useState(null);
  const [damCallback, setDamCallback] = useState(null);
  const [certificates, setCertificates] = useState(null);

  const [nodeConfig, setNodeConfig] = useState(null);
  const [activityConfig, setActivityConfig] = useState(null);

  const [registerConfig, setRegisterConfig] = useState(null);
  const [registerCertificate, setRegisterCertificate] = useState(null);
  const [offerUid, setOfferUid] = useState(null);

  const [nodeUserValues, setNodeUserValues] = useState(null);
  const [nodeOverrideUserValues, setNodeOverrideUserValues] = useState(null);
  const theme = useTheme();

  const [defaultValues, setDefaultValues] = useState(null);
  const [refList, setRefList] = useState();
  const [entityUpd, setEntityUpd] = useState();
  const [ids, setIds] = useState();

  useEffect(() => {
    loadSchemas();
    loadProducts();
    loadReviews();
    loadCertificates();

    window.addEventListener('message', handler);
    // clean up
    // return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    const courseUid = match.params.treeUid;
    loadCourse(courseUid);
    loadRegisters(courseUid);
    loadNodes(courseUid);
    loadOffer(offerUid);
  }, [match.params.treeUid]);

  useEffect(() => {
    if (!nodeOverrides) return;

    for (const override of nodeOverrides) {
      if (!override.__internal.updated) continue;
    }
  }, [nodeOverrides]);

  useEffect(() => 
  {
    setRegisterCertificate(null);
    if (!registerConfig || !registerConfig.certificate_internal_content_uid) return;

    Sdk.Api.dynamic.bridge(`/storage/v1.0/content/details?uid=${registerConfig.certificate_internal_content_uid}`, null, "GET")
    .then(response=>
    {
      setRegisterCertificate(response);
    });
    
  }, [registerConfig]);

  useEffect(() => {
    syncNodes();

    for (const node of nodes ?? []) {
      if (nodeConfig && nodeConfig.uid == node.uid) {
        setNodeConfig(node);
      }

      if (node.__internal.state == 'created') {
        delete node.__internal.state;
        editNode(node);
      } else if (node.__internal.state == 'updated') {
        delete node.__internal.state;
      }

      if (!node.activities) node.activities = [];

      for (const activity of node.activities) {
        if (activity.__internal.state == 'created') {
          delete activity.__internal.state;
          editActivity(activity);
        }

        if (activityConfig && activityConfig.uid == activity.uid) {
          setActivityConfig(activity);
        }
      }
    }
  }, [nodes]);

  useEffect(() => {
    syncNodesOverrides();
  }, [registers]);

  useEffect(() => {
    if (!damCallback) {
      window.removeEventListener('message', handler);
      return;
    }
    var route = damCallback.route;
    if (!route) route = '/embed/manager/images';

    var env = Sdk.enviroment == 'homolog' ? 'h' : 'p';
    const url = `${Sdk.domain}/product/dam/current/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/?access_token=${Sdk.authorization.credential.accessToken}&language=pt_br#${route}`;

    window.addEventListener('message', handler);
    setIframe(url);
  }, [damCallback]);

  const handler = useCallback(
    (event) => {
      if (!damCallback) return;

      const data = event.data;
      if (data && data.type != 'damfile' && !data.uid && !data.content_type)
        return;

      damCallback.result(data);
    },
    [damCallback]
  );

  const loadCourse = async (courseUid) => {
    if (!courseUid) {
      setCourse(null);
      return;
    }

    const tempCourse = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${courseUid}/management`,
      null,
      'GET'
    );
    tempCourse.__internal = {};
    if (!tempCourse.entities) tempCourse.entities = [];

    setCourse(tempCourse);
  };

  const loadNodes = async (couserUid) => {
    if (!couserUid) {
      setNodes(null);
      return;
    }

    let nodes = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${couserUid}/management/nodes`
    );
    nodes = nodes.sort((a, b) => a.row - b.row);

    nodes.forEach((node) => {
      node.__internal = {};

      if (node.activities) {
        node.activities.forEach((activity) => (activity.__internal = {}));
      }
    });

    setNodes(nodes);
  };

  const loadProducts = async () => {
    const products = await Sdk.dynamic.bridge(`store/v1.0/product/available`);
    setProducts(products);
  };

  const loadReviews = async () => {
    const reviewsData = await Sdk.dynamic.bridge(`cms/v1.0/review/flows`);
    setReviewFlows(reviewsData);
  };

  const loadCertificates = async () => {
    const certificateTemplates = await Sdk.dynamic.bridge(
      `storage/v1.0/search?filter=(content_type eq 'digitalpages/template') and (project_uid eq '${Sdk.Api.authorization.activeProject.uid}')&top=1000&orderBy=last_modified desc&(content_info/any(info: info eq 'type=certificate'))`
    );

    if (!certificateTemplates) return;

    for (var certificate of certificateTemplates.results) {
      var dynamic = await Sdk.dynamic.bridge(
        `storage/v1.0/content/versions/dynamic?uid=${certificate.document.content_uid}`,
        null,
        'POST'
      );
      certificate.document.content_uid = dynamic.last_uid;
    }

    setCertificates(certificateTemplates);
  };

  const loadSchemas = async () => {
    const schemas = await Sdk.dynamic.bridge(
      'auth/v1.0/entity/management/schema/available'
    );
    if (!schemas) return;

    for (const schema of schemas) {
      const items = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/uid/${schema.uid}/items`
      );
      schema.items = items.sort((a, b) => {
        if (a.row > b.row) return 1;
        if (a.row < b.row) return -1;
        return 0;
      });
    }

    setSchemas(schemas);
  };

  const loadRegisters = async (courseUid) => {
    if (!courseUid) {
      setTableData(null);
      setPreloader(true);
      setRegisters(null);
      return;
    }

    const registers = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${courseUid}/management/registers`
    );

    const tempStructure = {
      result: registers,
      current_page: 1,
      page_count: 1,
      page_size: registers.length,
      row_count: registers.length,
    };

    setTableData(tempStructure);
    setPreloader(false);
    setRegisters(registers);
  };

  const loadRegister = async (registerUid) => {
    if (activeRegister == registerUid || !registerUid) return;
    if (!registerUid || registerUid == 'null') {
      setNodeOverrideUserValues(null);
      setActiveRegister(null);
      return;
    }

    const register = registers.find((p) => p.uid == registerUid);

    if (register && register.node_overrides.length == 0 && registerUid) {
      const info = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${registerUid}/management`
      );

      if (info.node_overrides) {
        info.node_overrides.forEach((node) => (node.__internal = {}));
      }

      register.node_overrides = info.node_overrides;
    }

    setNodeOverrideUserValues(null);
    setActiveRegister(register.uid);
  };

  const loadOffer = async (offerUid) => {
    if (!offerUid) return;

    let offer = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/register/uid/${offerUid}/management`,
      null,
      'GET'
    );

    setRegisterConfig(offer);
  };

  const syncNodes = async () => {
    if (!nodes) return;

    let updated = false;
    let result = [];

    for (const node of nodes) {
      const activitiesUpdated = await syncActivities(node.activities, node);

      if (node.activities != activitiesUpdated) {
        node.activities = activitiesUpdated;
        updated = true;
      }

      if (node.__internal.state != 'processing') {
        result.push(node);
        continue;
      }

      updated = true;

      try {
        switch (node.__internal.action) {
          case 'delete': {
            await toast.promise(
              Sdk.dynamic.bridge(
                `cms/v1.0/learning/node/uid/${node.uid}/management`,
                null,
                'DELETE'
              ),
              {
                pending: textFromUid(`pending_message_deteted_node`),
                success: textFromUid(`success_message_deteted_node`),
                error: textFromUid(`error_message_deteted_node`),
              }
            );
            break;
          }
          case 'create': {
            var nodeResult = await toast.promise(
              Sdk.dynamic.bridge(
                `cms/v1.0/learning/course/uid/${course.uid}/management/node`,
                node,
                'POST'
              ),
              {
                pending: textFromUid(`pending_message_created_node`),
                success: textFromUid(`success_message_created_node`),
                error: textFromUid(`error_message_created_node`),
              }
            );

            nodeResult.__internal = { state: 'created' };
            result.push(nodeResult);
            break;
          }
          case 'update': {
            const activities = node.activities;
            delete node.activities;

            var nodeResult = await toast.promise(
              Sdk.dynamic.bridge(
                `cms/v1.0/learning/node/uid/${node.uid}/management`,
                node,
                'PUT'
              ),
              {
                pending: textFromUid(`pending_message_updated_node`),
                success: textFromUid(`success_message_updated_node`),
                error: textFromUid(`error_message_updated_node`),
              }
            );

            nodeResult.activities = activities;
            nodeResult.__internal = { state: 'updated' };
            result.push(nodeResult);

            break;
          }
        }
      } catch (e) {
        node.__internal.state = 'error';
        node.__internal.stateDetail = 'Falha ao remover';
        result.push(node);
      }
    }

    if (!updated) return;
    setNodes(result);
  };

  const syncActivities = async (activities, node) => {
    if (!activities || activities.length == 0) return activities;

    const result = [];
    var updated = false;

    for (const activity of activities) {
      if (activity.__internal.state != 'processing') {
        result.push(activity);
        continue;
      }

      updated = true;

      try {
        switch (activity.__internal.action) {
          case 'delete': {
            await toast.promise(
              Sdk.dynamic.bridge(
                `cms/v1.0/learning/activity/uid/${activity.uid}/management?force=true`,
                null,
                'DELETE'
              ),
              {
                pending: textFromUid(`pending_message_deleted_activity`),
                success: textFromUid(`success_message_deleted_activity`),
                error: textFromUid(`error_message_deleted_activity`),
              }
            );
            break;
          }
          case 'insert': {
            var data = await toast.promise(
              Sdk.dynamic.bridge(
                `cms/v1.0/learning/node/uid/${node.uid}/management/activities`,
                [activity],
                'POST'
              ),
              {
                pending: textFromUid(`pending_message_created_activity`),
                success: textFromUid(`success_message_created_activity`),
                error: textFromUid(`error_message_created_activity`),
              }
            );

            if (data.length == 1) {
              var newactivity = data[0];
              newactivity.__internal = { state: 'created' };
              result.push(newactivity);
            }

            break;
          }
          case 'update': {
            var data = await toast.promise(
              Sdk.dynamic.bridge(
                `cms/v1.0/learning/activity/uid/${activity.uid}/management`,
                activity,
                'PUT'
              ),
              {
                pending: textFromUid(`pending_message_updated_activity`),
                success: textFromUid(`success_message_updated_activity`),
                error: textFromUid(`error_message_updated_activity`),
              }
            );

            if (data.length == 1) {
              var newactivity = data[0];
              newactivity.__internal = { state: 'updated' };
              result.push(newactivity);
            }

            break;
          }
        }
      } catch (e) {
        activity.__internal.state = 'error';
        activity.__internal.stateDetail = 'Falha ao remover';
        result.push(activity);
      }
    }

    if (!updated) return activities;

    return result;
  };

  const syncCourse = async (courseData) => {
    var result = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${courseData.uid}/management`,
      courseData,
      'PUT'
    );
    setCourse(result);
  };

  const syncNodesOverrides = async () => {
    if (!registers) return;

    var updated = false;

    for (const register of registers) {
      const result = [];

      for (const override of register.node_overrides ?? []) {
        if (!override.__internal || override.__internal.state != 'processing') {
          result.push(override);
          continue;
        }

        updated = true;

        try {
          switch (override.__internal.action) {
            case 'delete': {
              // await Sdk.dynamic.bridge(`cms/v1.0/learning/activity/uid/${activity.uid}/management?force=true`, null, "DELETE");
              break;
            }
            case 'update': {
              var data = await toast.promise(
                Sdk.dynamic.bridge(
                  `cms/v1.0/learning/node/override/uid/${override.uid}/management`,
                  override,
                  'PUT'
                ),
                {
                  pending: textFromUid(`pending_message_updated_node`),
                  success: textFromUid(`success_message_updated_node`),
                  error: textFromUid(`error_message_updated_node`),
                }
              );

              data.__internal = {};
              result.push(data);
              break;
            }
            case 'create': {
              var data = await toast.promise(
                Sdk.dynamic.bridge(
                  `cms/v1.0/learning/register/uid/${activeRegister}/management/override/node`,
                  override,
                  'POST'
                ),
                {
                  pending: textFromUid(`pending_message_created_node`),
                  success: textFromUid(`success_message_created_node`),
                  error: textFromUid(`error_message_created_node`),
                }
              );

              data.__internal = {};
              result.push(data);
              break;
            }
          }
        } catch (e) {
          if (!override.__internal) override.__internal = {};
          override.__internal.state = 'error';
          override.__internal.stateDetail = 'Falha ao remover';
          result.push(override);
        }
      }

      register.node_overrides = result;
    }

    if (!updated) return;

    var info = [...registers];
    setRegisters(info);
  };

  const deleteNode = (node) => {
    var confirmation = {
      title: 'Atenção!',
      description: `Deseja realmente remover o nó "${node.data.title}", todas as atividades atreladas e os históricos dos usuários relacionados a essas atividades?`,
      action: () => {
        node.__internal.state = 'processing';
        node.__internal.action = 'delete';

        node.activities.forEach((act) => {
          act.__internal.state = 'processing';
          act.__internal.action = 'delete';
        });

        const nodesUpdates = [...nodes];
        setModalConfirmationConfig(null);
        setNodes(nodesUpdates);
      },
    };

    setModalConfirmationConfig(confirmation);
  };

  const deleteActivity = (activity) => {
    var confirmation = {
      title: 'Atenção!',
      description: `Deseja realmente remover a atividade "${activity.name}"? Essa operação não pode ser desfeita.`,
      action: () => {
        activity.__internal.state = 'processing';
        activity.__internal.action = 'delete';

        const nodesUpdates = [...nodes];
        setModalConfirmationConfig(null);
        setNodes(nodesUpdates);
      },
    };

    setModalConfirmationConfig(confirmation);
  };

  const editNode = (node) => {
    setNodeUserValues(null);
    setNodeOverrideUserValues(null);
    setNodeConfig(node);
  };

  const createNode = (parent) => {
    var newNode = {
      uid: 'temp',
      row: 0,
      column: 0,
      data: {},
      __internal: { state: 'processing', action: 'create' },
    };

    if (parent) {
      const childColumn = parent.column + 1;
      var childRow = parent.row + 1;

      for (const node of nodes.filter((node) => node.row > parent.row)) {
        if (node.column <= parent.column) break;
        childRow = node.row + 1;
      }

      newNode.row = childRow;
      newNode.column = childColumn;
    } else if (nodes.length > 0) {
      newNode.row = nodes[nodes.length - 1].row + 1;
    }

    nodes.forEach((node) => {
      if (node.row < newNode.row) return;
      node.__internal.state = 'processing';
      node.__internal.action = 'update';
      node.row++;
    });

    for (const property of course.node_properties) {
      newNode.data[property.name] = '';
    }

    nodes.push(newNode);

    var result = [...nodes.sort((a, b) => a.row - b.row)];
    setNodes(result);
  };

  const createActivity = (node) => {
    var nodeReference = nodes.find((ref) => ref.uid == node.uid);

    if (!nodeReference) return;
    if (!nodeReference.activities) nodeReference.activities = [];

    nodeReference.activities.push({
      name: textFromUid('default_new_activity_name'),
      provider_direct_access_url: 'https://blank.page',
      order: nodeReference.activities.length + 1,
      __internal: {
        state: 'processing',
        action: 'insert',
      },
    });

    var result = [...nodes];
    setNodes(result);
  };

  const editActivity = (activity) => {
    setActivityConfig(activity);
  };

  const updateCourseDetail = async (formData) => {
    const data = toTreeObject(formData, null);

    const detailCourseUpdated = await toast.promise(syncCourse(data), {
      pending: textFromUid(`pending_message_information_course`),
      success: textFromUid(`success_message_information_course`),
      error: textFromUid(`error_message_user_information_course`),
    });
  };

  const handleNodeInsert = (node, contentType) => {
    switch (contentType) {
      case 'node': {
        createNode(node);
        break;
      }
      case 'activity': {
        createActivity(node);
        break;
      }
    }
  };

  const updateNodeOverride = (formData) => {
    if (!activeRegister || !nodeConfig) return;

    formData.products = convertToArray(formData.products);

    var register = registers.find((reg) => reg.uid == activeRegister);
    if (!register) return;

    let override = register.node_overrides.find(
      (override) => override.node_uid == nodeConfig.uid
    );

    if (!override) {
      override = { node_uid: nodeConfig.uid, __internal: {} };
      override.__internal.action = 'create';
      register.node_overrides.push(override);
    } else {
      override.__internal.action = 'update';
    }

    override.__internal.state = 'processing';

    const data = toTreeObject(formData, null, override);
    // const registerUpdated = { ... activeRegister };

    setRegisters([...registers]);
  };

  const convertToArray = (object) => {
    var result = [];

    for (const key in object) {
      if (object[key] != true) continue;
      result.push(key);
    }

    return result;
  };

  const updateNode = (formData) => {
    if (!nodeConfig) return;

    formData.products = convertToArray(formData.products);
    const configUpdated = toTreeObject(formData, nodeConfig, nodeConfig);

    configUpdated.__internal.state = 'processing';
    configUpdated.__internal.action = 'update';

    var nodesUpdated = [...nodes];
    setNodes(nodesUpdated);
  };

  const handleUpdateActivity = async (formData) => {
    if (!activityConfig) return;

    const activityUpdated = toTreeObject(
      formData,
      activityConfig,
      activityConfig
    );

    activityUpdated.__internal.state = 'processing';
    activityUpdated.__internal.action = 'update';

    var nodesUpdated = [...nodes];

    setNodes(nodesUpdated);
  };

  const updateCourseCategories = async (formData) => {
    const entities = [];

    for (const schema in formData) {
      for (const entity in formData[schema]) {
        const entityValue = formData[schema][entity];
        if (entityValue === true) entities.push(entity);
      }
    }

    course.entities = entities;

    const categoriesCourseUpdated = await toast.promise(syncCourse(course), {
      pending: textFromUid(`pending_message_categories_course`),
      success: textFromUid(`success_message_categories_course`),
      error: textFromUid(`error_message_user_categories_course`),
    });
  };

  const handleUpdateNodeThumb = () => {
    const callback = { result: null };

    new Promise(function (resolve, reject) {
      callback.result = resolve;
    }).then(async (data) => {
      const publicUrl = await DAMService.getPublicUrl(data.uid);
      const newData = { ...nodeConfig };
      newData.data.thumb = publicUrl;

      updateNode(newData);
      setDamCallback(null);
      setIframe(null);
      setNodeConfig(newData);
    });

    setDamCallback(callback);
  };

  const handleChangeActivity = () => {
    const callback = { result: null, route: '/embed/manager/files' };

    new Promise(function (resolve, reject) {
      callback.result = resolve;
    }).then(async (data) => {
      activityConfig.__internal.state = 'processing';
      activityConfig.__internal.action = 'update';
      activityConfig.internal_content_uid = data.uid;
      activityConfig.provider_direct_access_url = '';

      var results = await syncActivities([activityConfig]);
      var newConfig = results[0];

      for (const key in newConfig) {
        activityConfig[key] = newConfig[key];
      }

      setDamCallback(null);
      setIframe(null);
      setActivityConfig({ ...activityConfig });
    });

    setDamCallback(callback);
  };

  const handleCreateOffer = () => {
    var date = new Date().toISOString();
    var dateEnd = new Date(
      Date.now() + 60 * 60 * 24 * 365 * 1000
    ).toISOString();

    const offerMock = {
      name: textFromUid('default_new_register_name'),
      description: '',
      register_start: date,
      register_end: dateEnd,
      interaction_start: date,
      interaction_end: dateEnd,
      visibility_start: date,
      visibility_end: dateEnd,
      authorizations: [],
    };

    setRegisterConfig(offerMock);
  };

  const changeCourseThumb = () => {
    const callback = { result: null };

    new Promise(function (resolve, reject) {
      callback.result = resolve;
    }).then(async (data) => {
      const publicUrl = await DAMService.getPublicUrl(data.uid);
      const coursedata = {
        ...course,
        thumb_url: publicUrl,
      };

      updateCourseDetail(coursedata);
      setDamCallback(null);
      setIframe(null);
    });

    setDamCallback(callback);
  };

  const categoriesFields = useMemo(() => {
    if (!schemas) return null;

    const categories = { fields: [] };

    for (const schmea of schemas.filter((s) => s.type == 'Category')) {
      categories.fields.push({
        id: `category-${schmea.uid}`,
        required: true,
        schema: schmea,
        type: 'input-schema-checkbox',
      });
    }

    return categories;
  }, [schemas]);

  const securityFields = useMemo(() => {
    if (!schemas) return null;

    const categories = { fields: [] };

    for (const schmea of schemas.filter((s) => s.type == 'Security')) {
      categories.fields.push({
        id: `security-${schmea.uid}`,
        required: true,
        schema: schmea,
        type: 'input-schema-checkbox',
      });
    }

    return categories;
  }, [schemas]);

  const productsConfigCheckbox = useCallback(
    (purchases) => {
      if (!products) return null;
      if (!purchases) purchases = [];

      const result = {
        id: `products`,
        type: 'input-multi-checkbox',
        group: textFromUid('node_products'),
        defaultValues: purchases,
        data: products.map((product) => {
          return {
            name: product.name,
            value: product.uid,
            information: product.uid,
          };
        }),
      };

      return result;
    },
    [products]
  );

  async function getDefaultValues() {
    const register = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/register/uid/${match.params.registerUid}/management`,
      null,
      'GET'
    );

    const list = await register.authorizations
      .flatMap((a) =>
        a.references.map((ref) => {
          return ref.reference_uid;
        })
      )
      .filter((value, index, self) => self.indexOf(value) === index);

    setRefList(list);
    setDefaultValues(register);
  }

  const updateOfferDetail = async (inputs) => {
    var data = toTreeObject(inputs, null, {});

    updateOfferDetailFromObject(data);
  };

  const updateOfferDetailFromObject = async (data) => {
    var finalRegisters = [
      ...registers.filter((register) => register.uid != data.uid),
    ];

    if (data.uid) {
      data = await toast.promise(
        Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${data.uid}/management`,
          data,
          'PUT'
        ),
        {
          pending: textFromUid('pending_message_register_update'),
          success: textFromUid('success_message_register_update'),
          error: textFromUid('error_message_register_update'),
        }
      );
    } else {
      data = await toast.promise(
        Sdk.dynamic.bridge(
          `cms/v1.0/learning/course/uid/${course.uid}/management/registers`,
          data,
          'POST'
        ),
        {
          pending: textFromUid('pending_message_register_insert'),
          success: textFromUid('success_message_register_insert'),
          error: textFromUid('error_message_register_insert'),
        }
      );
    }

    finalRegisters.push(data);
    setRegisters(finalRegisters);
    setRegisterConfig(data);
  };

  const updateOfferEntities = async (inputs) => {
    var groups = [];

    for (const group of Object.keys(inputs).map((key) => inputs[key])) {
      var activeGroups = Object.keys(group).filter((key) => group[key] == true);
      groups.push(activeGroups);
    }

    groups = groups.filter((group) => group.length > 0);
    const authorizations = adjustAuthorizations(groups, false);
    updateOfferDetailFromObject({
      uid: registerConfig.uid,
      authorizations: authorizations,
    });
  };

  const adjustAuthorizations = (uids, combined) => {
    let groups = [];

    if (combined) {
      let uidflat = uids.flat().filter((el) => el !== undefined);
      groups.push(uidflat);
    } else {
      var result = [[]];
      uids
        .filter((group) => group != undefined)
        .forEach(
          (group, index) =>
            (result = group.flatMap((g) => result.map((r) => r.concat(g))))
        );
      groups = result;
    }

    let authorizations = groups.map((entities) => {
      return {
        role: 'Viewer',
        references: entities.map((item) => {
          return { reference_uid: item, type: 'Entity' };
        }),
      };
    });

    return authorizations;
  };

  const modalRegister = useMemo(() => {
    if (!registerConfig) return null;
    if (!certificates) return null;

    const certificatesType = certificates?.results?.map((item) => {
      var titleComp = item.document.content_info.find(
        (p) => p.indexOf('title=') == 0
      );
      var title = titleComp ? titleComp.split('=')[1] : '-';

      return {
        label: title,
        value: item.document.content_uid,
        file_uid: item.document.file_uid
      };
    });

    var currentSelection = certificatesType?.find(p=> p.file_uid == registerCertificate?.file_uid);

    const result = {
      id: `certificate_internal_content_uid`,
      config: {},
      type: 'input-select',
      field: 'certificate_internal_content_uid',
      group: textFromUid('certificate_type'),
      placeholder: 'Digite o tipo',
      required: false,
      options: certificatesType,
      defaultValue: currentSelection?.value,
      currentValue: currentSelection?.value
    };

    // console.log("currentSelection:", currentSelection);

    const requiredFields = [];
    const disabledUserFileds = ['uid', 'created_at', 'updated_at'];
    const order = [
      'uid',
      'name',
      'description',
      'register_start',
      'register_end',
      'interaction_start',
      'interaction_end',
      'visibility_start',
      'visibility_end',
    ];

    var options = objectToForm(registerConfig, {
      required: requiredFields,
      disabled: disabledUserFileds,
      order: order,
      informations: [],
      namePrefix: 'offer_',

      handleOptions: (key) => {
        if (key === 'certificate_internal_content_uid') return result;

        return null;
      },
    });

    const currentValues = {};
    options.forEach(
      (field) =>
        (currentValues[field && field.id] = field && field.currentValue)
    );

    var defaultValues = {};
    var selectedItems = registerConfig.authorizations
      .map((auth) => auth.references.map((refe) => refe.reference_uid))
      .flat();
    schemas.forEach((schema) => {
      if (!schema.items) return;

      var values = [];
      schema.items.forEach((entity) => {
        if (selectedItems.find((entityUid) => entityUid == entity.uid)) {
          values.push(entity.uid);
        }
      });
      defaultValues[schema.uid] = values;
    });

    return (
      <Components.Alerts.Modal
        theme={theme}
        closeIconColor="#764AB0"
        title={
          registerConfig.uid
            ? textFromUid('offer_edit')
            : textFromUid('offer_insert')
        }
        boxWidth={'60%'}
        maxHeight={'80%'}
        boxHeight={'100%'}
        onCloseClick={() => setRegisterConfig(null)}
        actionLabel="Confirmar"
      >
        <Components.Headers.TitleAndDescription
          title={textFromUid('offer_edit_detail_title')}
          description={textFromUid('offer_edit_detail_description')}
          padding={'0 20px'}
        />
        <DynamicForm
          handleSubmit={updateOfferDetail}
          form={{ fields: options }}
          submitText={textFromUid(
            registerConfig.uid
              ? 'button_update_register_details'
              : 'button_insert_register_details'
          )}
          defaultValues={currentValues}
        />
        {registerConfig.uid && (
          <Components.Headers.TitleAndDescription
            title={textFromUid('offer_edit_entity_title')}
            description={textFromUid('offer_edit_entity_description')}
            padding={'0 20px'}
          />
        )}
        {registerConfig.uid && (
          <DynamicForm
            form={securityFields}
            submitText={textFromUid('button_update_register_entities')}
            handleSubmit={updateOfferEntities}
            defaultValues={defaultValues}
          />
        )}
        {/* <Components.Forms.Divider /> */}
      </Components.Alerts.Modal>
    );
  }, [registerConfig, certificates, registerCertificate]);

  const updateOffer = (Uid) => {
    setOfferUid(Uid);
    loadOffer(Uid);
  };

  const deleteOffer = (uid) => {};

  const modalConfirmation = useMemo(() => {
    if (!modalConfirmationConfig) return null;

    return (
      <Components.Alerts.Modal
        closeIconColor="#764AB0"
        title={modalConfirmationConfig.title}
        text={modalConfirmationConfig.description}
        // actionIconColor={theme.colors.light}
        // actionColor={theme.colors.light}
        // actionBackground={theme.colors.primary.default}
        // closeIconColor={theme.colors.primary.default}
        onCloseClick={() => setModalConfirmationConfig(null)}
        buttons={
          <ButtonDynamic icon="plus" color="blue" actionClick={modalConfirmationConfig.action}>
            {textFromUid('modal_button_confirm_delete_node')}
          </ButtonDynamic>
        }
      />
    );
  }, [modalConfirmationConfig]);

  const nodeFieldOptions = {
    order: [
      'uid',
      'created_at',
      'updated_at',
      'duration',
      'row',
      'column',
      'tags',
      'data.*',
      'data.title',
      'data.tag_type',
      'data.tag',
      'data.description',
      'interaction_interval_start',
      'interaction_interval_end',
      'products',
    ],
    disabled: ['uid', 'created_at', 'updated_at'],
    required: ['title'],
    ignore: [
      'name',
      'data.thumb',
      'type_formula_score',
      'type',
      'entities',
      'tags',
      'activities',
      'interaction_interval_limit',
    ],
    handleOptions: (key) => {
      if (key === 'tag_type')
        return {
          id: 'data.tag_type',
          group: textFromUid('node_type_tags'),
          required: true,
          type: 'input-select',
          config: {},
          options: [
            {
              value: 'Lesson',
              label: textFromUid('node_type_tags_label_lesson'),
            },
            {
              value: 'Project',
              label: textFromUid('node_type_tags_label_project'),
            },
            {
              value: 'Course',
              label: textFromUid('node_type_tags_label_course'),
            },
            {
              value: 'Module',
              label: textFromUid('node_type_tags_label_module'),
            },
          ],
          defaultValue: nodeConfig.data.tag_type,
          field: 'data.tag_type',
          placeholder: textFromUid('node_type_tags_placeholder'),
        };
      return null;
    },
    namePrefix: 'node_',
  };

  const nodeFields = useMemo(() => {
    if (!nodeConfig) return null;

    var nodeData = {
      fields: objectToForm(nodeConfig, nodeFieldOptions),
    };

    // debugger;

    // nodeData.fields.forEach((field) => {
    //   //if (field.id.indexOf('data.') == 0) field.group = field.originalName;
    // });

    nodeData.fields.push(productsConfigCheckbox(nodeConfig.products));

    return nodeData;
  }, [nodeConfig]);

  const handleCloseNode = () => {
    setNodeConfig(null);
    setActiveRegister(null);
  };

  const modalNodeConfig = useMemo(() => {
    var nodeData = nodeFields;
    if (!nodeData) return null;

    // var nodeData = nodeFields;

    var nodeCurrentValues = nodeUserValues;

    if (!nodeUserValues) {
      nodeCurrentValues = {};
      nodeData.fields.forEach(
        (field) => (nodeCurrentValues[field.id] = field.currentValue)
      );
      setNodeUserValues(nodeCurrentValues);
    }

    var registerOptions = (registers ?? []).map((r) => {
      return { label: r.name, value: r.uid };
    });
    registerOptions.splice(0, 0, {
      label: textFromUid('select_edit_node_register_unselected'),
      value: 'null',
    });

    var currentOption = registerOptions[0].value;
    var nodeOverrideData = null;
    var nodeOverrideCurrentValues = nodeOverrideUserValues;

    if (activeRegister) {
      currentOption = activeRegister;
      var register = registers.find((reg) => reg.uid == activeRegister);
      var nodeOverride = register.node_overrides.find(
        (n) => n.node_uid == nodeConfig.uid
      );

      if (!nodeOverride) nodeOverride = {};

      var options = { ...nodeFieldOptions };

      options.namePrefix = 'node_override_';
      nodeOverrideData = { fields: objectToForm(nodeConfig, options) };
      nodeOverrideData.fields.splice(0, 10);
      nodeOverrideData.fields.push(
        productsConfigCheckbox(nodeOverride.products)
      );

      var tempReference = objectToForm(nodeOverride, options);

      if (!nodeOverrideCurrentValues) {
        nodeOverrideCurrentValues = {};
        nodeOverrideData.fields.forEach((field) => {
          field.required = false;
          var currentData = tempReference.find((ref) => ref.id == field.id);
          if (!currentData) {
            field.currentValue = null;
          } else {
            field.currentValue = currentData.currentValue;
          }
        });

        nodeOverrideData.fields.forEach(
          (field) => (nodeOverrideCurrentValues[field.id] = field.currentValue)
        );

        setNodeOverrideUserValues(nodeOverrideCurrentValues);
      }
    }

    return (
      <Components.Alerts.Modal
        theme={theme}
        closeIconColor="#764AB0"
        title={textFromUid('modal_node_edit_title')}
        boxWidth={'90%'}
        boxHeight={'100%'}
        maxHeight={'80%'}
        onCloseClick={() => handleCloseNode()}
        actionLabel={textFromUid('modal_node_edit_button')}
      >
        <Components.Headers.TitleAndDescription
          title={textFromUid('node_edit_title')}
          description={textFromUid('node_edit_description')}
          padding={'0 20px'}
        />
        <Components.Forms.Divider />
        <ContainerNode>
          <div style={{ width: '40%' }}>
            <Components.Headers.TitleAndDescription
              title={textFromUid('node_thumb')}
              description={''}
              padding={'0 0 0 10px'}
            />
            <Components.Images.ImagePreview
              src={nodeConfig.data.thumb}
              border="dashed"
              alt=""
            />
            <Components.Forms.Divider />
            <center>
              <ButtonDefault
                text={textFromUid('button_update_thumb')}
                icon={<IconUpload />}
                onClick={handleUpdateNodeThumb}
              />
            </center>
          </div>
          <div style={{ width: '100%', margin: '0px 20px' }}>
            <Components.Headers.TitleAndDescription
              title={textFromUid('node_config_base')}
              description={''}
              padding={'0 20px'}
            />
            <DynamicForm
              form={nodeData}
              handleChanges={setNodeUserValues}
              submitText={textFromUid('button_update_config_base')}
              defaultValues={nodeCurrentValues}
              handleSubmit={updateNode}
            />
          </div>
          <div
            style={{ width: '100%', height: '1900px', position: 'relative' }}
          >
            <Components.Headers.TitleAndDescription
              title={textFromUid('node_override_info_title')}
              description={textFromUid('node_override_info_description')}
              padding={'0 20 0 40px'}
            />
            <Components.Buttons.Select
              maxOptionsHeight={'90px'}
              placeholder={textFromUid('structure_base_course')}
              title={textFromUid('node_register')}
              defaultValue={currentOption}
              items={registerOptions}
              onChange={async (uid) => await loadRegister(uid)}
            />
            {activeRegister && (
              <OverrideContainer>
                <DynamicForm
                  key={activeRegister}
                  form={nodeOverrideData}
                  handleChanges={setNodeOverrideUserValues}
                  submitText={textFromUid('update')}
                  defaultValues={nodeOverrideCurrentValues}
                  handleSubmit={updateNodeOverride}
                />
              </OverrideContainer>
            )}
          </div>
        </ContainerNode>
      </Components.Alerts.Modal>
    );
  }, [nodeConfig, registers, activeRegister, products, nodeUserValues]);

  const modalActivityConfig = useMemo(() => {
    if (!activityConfig) return null;

    let activityUrl = `${Sdk.Api.domain}/cms/v1.1/learning/activity/uid/${activityConfig.uid}/management/content?project_key=${Sdk.Api.projectKey}&access_token=${Sdk.authorization.credential.accessToken}&api_env=${Sdk.Api.enviroment}&update_at=${activityConfig.updated_at}`;
    const disabledUserFileds = ['uid', 'created_at', 'updated_at'];

    const order = [
      'uid',
      'name',
      'score_min',
      'score_max',
      'score_weight',
      'score_group',
      'duration',
      'maximum_attempts_after_completed',
      'maximum_attempts_after_failed',
      'maximum_attempts_after_passed',
      'created_at',
      'updated_at',
      'required_predecessor_completion',
      'content_upload_required',
    ];

    const types = ['score_max', 'score_min', 'score_weight', 'score_group'];

    var options = objectToForm(activityConfig, {
      //  required: requiredFields,
      disabled: disabledUserFileds,
      order: order,
      // informations: [],
      namePrefix: 'modal_activity_edit_',
      handleType: (key) => {
        if (types.indexOf(key) != -1) return 'number';

        return null;
      },
    });

    const currentValues = {};
    options.forEach((field) => (currentValues[field.id] = field.currentValue));

    return (
      <Components.Alerts.Modal
        theme={theme}
        title={textFromUid('activity_edit')}
        boxWidth={'90%'}
        boxHeight={'80%'}
        onCloseClick={() => setActivityConfig(null)}
        closeIconColor="#764AB0"
      >
        <Components.Headers.TitleAndDescription
          title={textFromUid('activity_edit_title')}
          description={textFromUid('activity_edit_description')}
          padding={'0 20px'}
        />
        <Components.Forms.Divider />

        <Container>
          <div
            style={{
              width: '35%',
              height: 'auto',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Components.Headers.TitleAndDescription
              title={textFromUid('activity_config_base')}
              description={''}
              padding={'0 20px'}
            />
            <DynamicForm
              form={{ fields: options }}
              defaultValues={currentValues}
              submitText={textFromUid('activity_button_update')}
              handleSubmit={handleUpdateActivity}
            />
          </div>
          <div style={{ width: '65%' }}>
            <ActivityIframe
              allow="camera; microphone; display-capture; autoplay; clipboard-write; encrypted-media"
              sandbox="allow-scripts allow-forms allow-same-origin allow-pointer-lock allow-presentation allow-modals allow-popups allow-popups-to-escape-sandbox"
              src={activityUrl}
            />
            <center>
              <ButtonDefault
                text={textFromUid('button_change_activity')}
                onClick={handleChangeActivity}
                icon={<IconUpload />}
              />
            </center>
            {/* <Components.Headers.TitleAndDescription title={textFromUid("node_override_info_title")} description={textFromUid("node_override_info_description")} padding={"0 20 0 40px"}/>
                <Components.Buttons.Select placeholder={textFromUid("structure_base_course")}
                  title={textFromUid("node_register")}
                  defaultValue={currentOption}
                  items={registerOptions}
                  onChange={(option) => loadRegister(option)}
                />
                {activeRegister && <OverrideContainer>
                  <DynamicForm form={nodeOverrideData} handleChanges={setNodeOverrideUserValues} submitText={textFromUid("update")} defaultValues={nodeOverrideCurrentValues} handleSubmit={updateNodeOverride}/>
                </OverrideContainer>} */}
          </div>
        </Container>
      </Components.Alerts.Modal>
    );
  }, [activityConfig]);

  const courseContent = useMemo(() => {
    if (formIndex !== 0) return null;
    if (!course) return <Loading msg={textFromUid('loading_message')} />;

    var options = {
      order: [
        'uid',
        'title',
        'description',
        'internal_name',
        'created_at',
        'updated_at',
      ],
      disabled: ['uid', 'created_at', 'updated_at'],
      required: ['title'],
      namePrefix: 'course_',
    };

    var allFields = objectToForm(course, options);
    var data = { fields: allFields };
    var currentValues = {};

    allFields.forEach(
      (field) => (currentValues[field.id] = field.currentValue)
    );

    return (
      // <TabContainer>
      <Container>
        <div style={{ width: '100%', paddingLeft: 0, paddingRight: 10 }}>
          <Components.Headers.TitleAndDescription
            title={textFromUid('couse_detail_title')}
            description={textFromUid('couse_detail_description')}
            padding="20px 0px 20px 0px"
          />
          <DynamicForm
            form={data}
            submitText={textFromUid('update')}
            defaultValues={currentValues}
            handleSubmit={updateCourseDetail}
          />
        </div>
        <div style={{ width: '400px', paddingLeft: 10, paddingRight: 0 }}>
          <Components.Headers.TitleAndDescription
            title={textFromUid('course_thumb')}
            description={''}
            padding="20px 0px 20px 0px"
          />
          <Components.Images.ImagePreview
            src={course.thumb_url}
            border="dashed"
            alt=""
          />
          {/* <Components.Forms.Divider /> */}
          <center>
            <ButtonDefault
              text={textFromUid('button_update_thumb')}
              onClick={changeCourseThumb}
              icon={<IconUpload />}
            />
          </center>
        </div>
      </Container>
      // </TabContainer>
    );
  }, [course, categoriesFields, formIndex]);

  const categoryContent = useMemo(() => {
    if (formIndex !== 1) return null;
    if (!categoriesFields) return <NoResult />;

    var defaultValues = {};
    schemas.forEach((schema) => {
      if (!schema.items) return;

      var values = [];

      schema.items.forEach((entity) => {
        if (course.entities.find((entityUid) => entityUid == entity.uid)) {
          values.push(entity.uid);
        }
      });
      defaultValues[schema.uid] = values;
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('course_categories_title')}
          description={textFromUid('course_categories_description')}
          padding="20px 0px 20px 0px"
        />
        <DynamicForm
          form={categoriesFields}
          submitText={textFromUid('update')}
          handleSubmit={updateCourseCategories}
          defaultValues={defaultValues}
        />
      </>
    );
  }, [categoriesFields, formIndex]);

  const offersContent = useMemo(() => {
    if (formIndex != 2) return null;
    if (!registers) return <Loading msg={textFromUid('loading_message')} />;

    const order = [
      'uid',
      'name',
      'description',
      'register_start',
      'register_end',
      'interaction_start',
      'interaction_end',
      'visibility_start',
      'visibility_end',
    ];

    const paths = objectPaths(registers[0], {
      namePrefix: 'table_header_register_',
      order: order,
    });

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('course_registers_title')}
          description={textFromUid('course_registers_description')}
          padding="20px 0px 20px 0px"
        />
        {registers.length == 0 && <NoResult />}
        {registers.length > 0 && (
          <TableDynamic
            title={'Offers'}
            headers={paths}
            defaultFields={[]}
            data={registers}
            hasCheckbox={false}
            hasView={false}
            hasEdit={true}
            // hasDelete={true}
            onUpdate={updateOffer}
            // onDelete={deleteOffer}
          />
        )}

        {/* <Components.Forms.Divider marginLeft={'0px'} marginRight={'0px'} /> */}
        <ButtonDefault
          text={textFromUid('button_new_register')}
          onClick={() => handleCreateOffer()}
          icon={<IconAdd />}
        />
      </>
    );
  }, [registers, formIndex]);

  const nodesContent = useMemo(() => {
    if (formIndex !== 3) return null;
    if (!nodes) return <Loading msg={textFromUid('loading_message')} />;

    nodes.forEach(node=> 
    {
      var name = node?.data?.title;
      if (!name) name = node?.data?.name;
      node.name = name;
    })

    return (
      <>
        <Components.Headers.TitleAndDescription
          title={textFromUid('course_structure_title')}
          description={textFromUid('course_structure_description')}
          padding="20px 0px 20px 0px"
        />
        {/* <Components.Forms.Divider marginLeft={'0px'} marginRight={'0px'} /> */}
        <ContainerNodeTree>
          {nodes.length == 0 && <NoResult />}
          <Components.Recursive.TreeEditV2
            key={`nodes-tree`}
            nodes={nodes}
            onDeleteNode={deleteNode}
            onEditNode={editNode}
            onDeleteActivity={deleteActivity}
            onEditActivity={editActivity}
            handleNodeSelectAction={handleNodeInsert}
            optionsMenus={[
              { label: textFromUid('node_add_node'), value: 'node' },
              { label: textFromUid('node_add_activity'), value: 'activity' },
            ]}
          />
        </ContainerNodeTree>
        {/* <Components.Forms.Divider marginLeft={'0px'} marginRight={'0px'} /> */}
        <ButtonDefault
          text={textFromUid('add_node')}
          onClick={() => createNode()}
          icon={<IconAdd />}
        />
      </>
    );
  }, [nodes, formIndex]);

  return (
    <div className="rdp-admin-edit-tree">
      <Header />
      <Sidebar
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentBreadcrumbTitle={textFromUid('screen_course_edit_title')}
        currentRoute={consumer ? consumer.currentRoute : null}
      />
      <Footer />
      <PageContainer>
        <div className="form-buttons">
          <button
            className={`tab-button ${formIndex === 0 ? 'active' : null}`}
            onClick={() => setFormIndex(0)}
          >
            {textFromUid('tab_course_information')}
          </button>
          <button
            className={`tab-button ${formIndex === 1 ? 'active' : null}`}
            onClick={() => setFormIndex(1)}
          >
            {textFromUid('tab_course_categories')}
          </button>
          <button
            className={`tab-button ${formIndex === 2 ? 'active' : null}`}
            onClick={() => setFormIndex(2)}
          >
            {textFromUid('tab_course_offers')}
          </button>
          <button
            className={`tab-button ${formIndex === 3 ? 'active' : null}`}
            onClick={() => setFormIndex(3)}
          >
            {textFromUid('tab_course_content')}
          </button>
        </div>
        {courseContent}
        {categoryContent}
        {offersContent}
        {nodesContent}
      </PageContainer>
      {iframe && (
        <>
          <Mask />
          <Iframe
            onClickOutside={() => setIframe(null)}
            className="iframe-content"
            src={iframe}
          />
        </>
      )}
      {modalConfirmation}
      {modalNodeConfig}
      {modalActivityConfig}
      {modalRegister}
    </div>
  );
}
