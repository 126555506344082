import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import { textFromUid } from '../../utils/textUtilities';

export default function ListRevision({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [totalPages, setTotalPages] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [previousPageQuery, setPreviousPageQuery] = useState(null);

  useEffect(() => {
    configTable();
    getRevision();

    consumer.defineRoute({
      name: textFromUid('screen_revision_title'),
      uri: '/reviews',
    });
  }, []);

  async function configTable() {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.revision);
  }

  async function getRevision(page = 1) {
    const { activeProject } = Sdk.Api.authorization;
    let _connector = null;
    let connector = null;

    const tmpStructureCp = { ...elements };
    let revision = [];

    if (activeProject && activeProject.connectors.length > 0) {
      _connector = activeProject.connectors;
    }

    connector = _connector.find((connector) =>
      connector.type === 'Review_1' ? connector.uid : null
    );

    var params = nextQuery ? `?${nextQuery}` : '';

    const result = await Sdk.dynamic.bridge(
      `cms/v1.0/review/connector/uid/${connector?.uid}/management/flows${params}`
    );

    revision = result.result;
    console.log({ revision });
    if (revision.length == 0) {
      setMessages({ noContent: 'Nenhuma revisão registrada' });
      setPreloader(false);
      console.log('Nenhuma revisao');
      return;
    }
    revision.forEach((uidSchemas) => {
      tmpStructureCp.result = Array.isArray(tmpStructureCp.result)
        ? [...tmpStructureCp.result, { ...uidSchemas }]
        : [{ ...uidSchemas }];
    });
    console.log({ result });
    tmpStructureCp.current_page = result.current_page;
    tmpStructureCp.total_pages = result.total_pages;
    tmpStructureCp.page_size = revision.length;
    tmpStructureCp.row_count = result.total_results;
    console.log(tmpStructureCp);
    setCurrentPage(result.current_page);
    setTotalPages(result.total_pages);
    setNextQuery(result.next_page_query);

    setShowLoadMore(result.current_page < result.total_pages);

    setElements(tmpStructureCp);

    setPreloader(false);
  }

  const handleUpdate = (uid) => {
    history.push(`/review/${uid}/edit`);
  };

  async function handleDelete(data) {
    setDeleting(true);

    try {
      if (data) {
        await deleteItem(data);
        setMessages({ success: config.deleteText });
      }

      await getRevision();
      window.location.reload();
    } catch (error) {
      setMessages({ error: config.deleteError });
    }

    setDeleting(false);
  }

  async function deleteItem(uid) {
    const result = await Sdk.dynamic.bridge(
      `cms/v1.0/review/flow/uid/${uid}/management`,
      null,
      'DELETE'
    );

    return result;
  }
  const onPreviousButtonClick = () => {
    if (previousPageQuery) {
      getRevision(previousPageQuery);
      setPreviousPageQuery(null);
    }
  };
  const onNextButtonClick = () => {
    if (nextQuery) {
      getRevision(nextQuery);
      setNextQuery(null);
    }
  };
  return (
    <>
      <GenericList
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        buttonDelete={false}
        breadcrumb={{
          selectOptions: null,
          routeNew: '/review/new',
          label: 'Nova revisão',
          customNewOptionTitle: textFromUid('button_insert_revision'),
        }}
        loading={{
          preloader,
        }}
        actions={{
          closeMessage: () => {
            setMessages({ success: null, error: null, noContent: null });
          },
          onUpdate: (uid) => handleUpdate(uid),
          onDelete: async (uid) => await handleDelete(uid),
        }}
        showLoadMore={showLoadMore}
        isPaginated={true}
        onPreviousButtonClick={onPreviousButtonClick}
        onNextButtonClick={onNextButtonClick}
      />
      {deleting ? <Loading msg={config.loadingDeleteText} /> : null}
      <Footer />
    </>
  );
}
