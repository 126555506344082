import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import Sdk from 'api.digitalpages.module.sdk.api';

import './list-rubric.scss';
import { textFromUid } from '../../utils/textUtilities';

export default function ListRubric({ ItemRubric, editRubric, handleDelete }) {
  const [opened, setOpened] = useState(false);
  const [items, setItems] = useState(ItemRubric);
  const [criterions, setCriterions] = useState([
    {
      title: '',
      description: '',
      value: 0,
      value_description: '',
    },
  ]);

  useEffect(() => {
    setItems(ItemRubric);
  }, [ItemRubric]);

  const handleOpenItem = (uid) => {
    var temp = JSON.parse(JSON.stringify(items));
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].uid === uid) {
        temp[i].opened = !temp[i].opened;
      }
    }
    setItems(temp);
  };

  const onChangeRubric = (uid) => (event) => {
    const { name, value } = event.target;

    const currentItems = items;
    const rubric = currentItems.find((item) => item.uid === uid);

    rubric[name] = value;

    setItems([...currentItems]);
  };

  const onChangeTable = (uid) => (event) => {
    const { name, value } = event.target;

    const currentItems = items;
    const rubric = currentItems.find((item) =>
      item.criterions.find((data) => data?.uid === uid)
    );

    const criterion = rubric.criterions.find((data) => data?.uid === uid);
    criterion[name] = value;

    setItems([...currentItems]);
  };

  const dataFormmatted = (value) => {
    const date = new Date(value);
    const result = date.toDatetimeLocal();

    return result;
  };

  const onClickAddCriterion = () => {
    const newCriterio = {
      title: '',
      description: '',
      value: 0,
      value_description: '',
    };

    const newCriterions = cloneDeep(criterions);
    newCriterions.push(newCriterio);
    setCriterions(newCriterions);
  };

  const onClickRemoveCriterion = (index) => {
    const newCriterions = cloneDeep(criterions);

    if (index > -1) {
      newCriterions.splice(index, 1);

      setCriterions(newCriterions);
    }
  };

  const IconUp = `
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0L12 7H0L6 0Z" fill="#764AB0"/>
  </svg>
`;

  const IconDown = `
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 7L12 0L0 0L6 7Z" fill="#764AB0"/>
  </svg>
`;

  const IconDelete = `<svg fill="#764AB0" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M20,4H4"/><path d="M15 3v1H9V3l.429-.429C9.795 2.205 10.29 2 10.807 2h2.386c.517 0 1.012.205 1.377.571L15 3zM4.366 7l1.527 13.264C6.025 21.254 6.877 22 7.875 22h8.249c.998 0 1.85-.746 1.983-1.745L19.634 7H4.366z"/></svg>`;

  const CloseIcon = () => {
    return (
      <svg
        fill="#764AB0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
      >
        <path d="M25.707,6.293c-0.195-0.195-1.805-1.805-2-2c-0.391-0.391-1.024-0.391-1.414,0c-0.195,0.195-17.805,17.805-18,18c-0.391,0.391-0.391,1.024,0,1.414c0.279,0.279,1.721,1.721,2,2c0.391,0.391,1.024,0.391,1.414,0c0.195-0.195,17.805-17.805,18-18C26.098,7.317,26.098,6.683,25.707,6.293z" />
        <path d="M23.707,25.707c0.195-0.195,1.805-1.805,2-2c0.391-0.391,0.391-1.024,0-1.414c-0.195-0.195-17.805-17.805-18-18c-0.391-0.391-1.024-0.391-1.414,0c-0.279,0.279-1.721,1.721-2,2c-0.391,0.391-0.391,1.024,0,1.414c0.195,0.195,17.805,17.805,18,18C22.683,26.098,23.317,26.098,23.707,25.707z" />
      </svg>
    );
  };

  const AddIcon = () => {
    return (
      <svg
        fill="#764AB0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
      >
        <path d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2  s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z" />
      </svg>
    );
  };

  return (
    <div className="rdp-admin-container">
      <ul>
        {items &&
          items.map((item, itemIndex) => (
            <li
              className="rdp-admin-item"
              key={item.uid}
              style={{ height: item.opened ? 'auto' : '45px' }}
            >
              <div className="rdp-admin-item-header">
                <span className="rdp-admin-item-icon">
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#764AB0"
                      d="M11.8349 7.5L14.86 2.96227C15.2292 2.40848 14.8322 1.66668 14.1667 1.66668H1.66668V0.83332C1.66668 0.373086 1.29355 0 0.83332 0C0.373086 0 0 0.373086 0 0.83332V2.5V12.5V19.1667C0 19.6269 0.373086 20 0.83332 20C1.29355 20 1.66664 19.6269 1.66664 19.1667V13.3333H14.1666C14.8322 13.3333 15.2292 12.5915 14.86 12.0377L11.8349 7.5ZM1.66668 11.6667V3.33332H12.6096L10.14 7.03773C9.95336 7.31766 9.95336 7.6823 10.14 7.96223L12.6096 11.6666H1.66668V11.6667Z"
                    />
                  </svg>
                </span>
                <b className="rdp-admin-item-title">{item.title}</b>

                {handleDelete && (
                  <span
                    className="rdp-admin-delete"
                    style={{
                      backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(
                        IconDelete
                      )}')`,
                    }}
                    onClick={async () => await handleDelete(item.uid)}
                  />
                )}

                <span
                  className="rdp-admin-item-collapse"
                  onClick={() => handleOpenItem(item.uid)}
                  style={{
                    backgroundImage: item.opened
                      ? `url('data:image/svg+xml;utf8,${encodeURIComponent(
                          IconUp
                        )}')`
                      : `url('data:image/svg+xml;utf8,${encodeURIComponent(
                          IconDown
                        )}')`,
                  }}
                />
              </div>
              <div>
                <div className="rdp-admin-row">
                  <label>
                    <span>{textFromUid('rubric_uid')}</span>
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      placeholder={textFromUid('rubric_uid')}
                      className="rdp-admin-input-disabled"
                      required={true}
                      value={item.uid}
                      disabled
                    />
                  </label>
                </div>
                <div className="rdp-admin-row">
                  <label>
                    <span>{textFromUid('rubric_title')}</span>
                    <span style={{ color: 'red' }}>*</span>
                    <input
                      type="text"
                      placeholder={textFromUid('rubric_title')}
                      className="rdp-admin-input"
                      required={true}
                      name="title"
                      value={item.title}
                      onChange={(e) => onChangeRubric(item.uid)(e)}
                      disabled={editRubric ? false : true}
                    />
                  </label>
                </div>
                <div className="rdp-admin-row">
                  <label>
                    <span>{textFromUid('rubric_description')}</span>
                    <span style={{ color: 'red' }}>*</span>
                    <textarea
                      type="text"
                      placeholder={textFromUid('rubric_description')}
                      className="text-input-rubric"
                      required={true}
                      name="description"
                      value={item.description}
                      onChange={(e) => onChangeRubric(item.uid)(e)}
                      disabled={editRubric ? false : true}
                    />
                  </label>
                </div>
                <div
                  className="rdp-admin-container-criterion"
                  style={{ padding: '15px 10px' }}
                >
                  <div className="row">
                    <label>
                      <span>{textFromUid('rubric_criterion')}</span>
                    </label>
                  </div>

                  {editRubric && (
                    <div>
                      <span
                        onClick={onClickAddCriterion}
                        className="rdp-admin-button-new-rubric"
                        style={{ paddingRight: '15px' }}
                      >
                        <AddIcon />
                      </span>
                    </div>
                  )}
                </div>

                <table className="rdp-admin-table">
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_uid')}</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_title')}</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_description')}</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_value')}</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_value_description')}</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_created_at')}</span>
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>{textFromUid('rubric_updated_at')}</span>
                        </div>
                      </th>

                      {editRubric && (
                        <th>
                          <div>
                            <span>{textFromUid('rubric_delete')}</span>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>

                  {item &&
                    item.criterions.map((criterion, index) => (
                      <tbody key={criterion.uid}>
                        <tr>
                          <td>
                            <div>{criterion.uid}</div>
                          </td>
                          <td>
                            <div>
                              {editRubric ? (
                                <input
                                  type="text"
                                  required={true}
                                  className="rdp-admin-input-table"
                                  value={criterion.title}
                                  name="title"
                                  onChange={(e) =>
                                    onChangeTable(criterion.uid)(e)
                                  }
                                />
                              ) : (
                                criterion.title
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {editRubric ? (
                                <textarea
                                  className="rpd-admin-input-description"
                                  value={criterion.description}
                                  name="description"
                                  onChange={(e) =>
                                    onChangeTable(criterion.uid)(e)
                                  }
                                  rows={5}
                                  cols={800}
                                />
                              ) : (
                                criterion.description
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {editRubric ? (
                                <input
                                  type="text"
                                  required={true}
                                  className="rdp-admin-input-table"
                                  value={criterion.value}
                                  name="value"
                                  onChange={(e) =>
                                    onChangeTable(criterion.uid)(e)
                                  }
                                />
                              ) : (
                                criterion.value
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {editRubric ? (
                                <input
                                  type="text"
                                  required={true}
                                  className="rdp-admin-input-table"
                                  value={criterion.value_description}
                                  name="value_description"
                                  onChange={(e) =>
                                    onChangeTable(criterion.uid)(e)
                                  }
                                />
                              ) : (
                                criterion.value_description
                              )}
                            </div>
                          </td>
                          <td>
                            <div>{dataFormmatted(criterion.created_at)}</div>
                          </td>
                          <td>
                            <div>{dataFormmatted(criterion.updated_at)}</div>
                          </td>

                          {editRubric && (
                            <td>
                              <div>
                                <span
                                  onClick={() => onClickRemoveCriterion(index)}
                                  className="rdp-admin-button-new-rubric"
                                  style={{ left: '7px', position: 'relative' }}
                                >
                                  <CloseIcon />
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}
