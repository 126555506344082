import React, { useEffect, useState } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
  ViewAccessExternalList,
  ViewEditAccessExternal,
  ViewNewAccessExternal,
  ViewList,
  ViewDashboard,
  ViewNewContent,
  ViewIframe,
  ViewProductList,
  // ViewProductNew,
  ViewProductNewV,
  ViewGroupsList,
  ViewCoursesList,
  ViewUsersList,
  ViewProjectList,
  ViewActiveProjectUserList,
  ViewDirectoryUserList,
  ViewDevicesList,
  ViewIotList,
  ViewGroupsManagement,
  ViewCourseManagement,
  ViewDeviceManagement,
  ViewGroupIotManagement,
  ViewNewUser,
  ViewEditUser,
  ViewNewContainer,
  ViewProfilesList,
  ViewAdministratorsList,
  ViewNewProfile,
  ViewAdministratorsNew,
  ViewAdministratorsEdit,
  ViewEditTree,
  ViewEditTreeV2,
  ViewListTree,
  ViewNewCourse,
  ViewNewOffer,
  ViewEditOffer,
  ViewEditProject,
  ViewNewSchema,
  ViewNewSchemaProject,
  ViewNewProject,
  ViewEntitiesList,
  ViewProductsList,
  ViewTransactionsList,
  // ViewNewProduct,
  ViewEditProduct,
  ViewEditReview,
  ViewListRevision,
  ViewListSubmission,
  ViewNewReview,
  ViewSchemasList,
  ViewNewEntity,
  ViewEditEntity,
  ViewEditLayoutConfiguration,
  ViewCertificatesList,
  ViewEditCertificate,
  ViewForum,
  ViewListRubric,
  ViewNewRubric,
  ViewGroupList,
  ViewNewGroupEntity,
  ManagedContentList,
  ManagedContentNew,
  ManagedContentEdit,
  ViewCorrectContent
  
} from '../views';

import ViewLogin from '../views/login/login';
import ContextRoute from './context-route';
import RouteContext from '../contexts/route-context';

export default function Routes() {
  const history = useHistory();
  const [prefix, setPrefix] = useState(window.location.hash.includes("embed") ? "/embed/" : "/");

  useEffect(() => {
    window.addEventListener('@rdpmanager-admin.event(logout)', async () => {
      await Sdk.Api.authorization.logout();
      history.push('/login');
    });

    Sdk.authorization.addStatusListen((authorization) => {
      if (authorization.authenticated == true) return;
      history.push('/login');
    });
  }, []);

  console.log("prefix:", prefix);   

  return (
    <Switch>
      <Route exact path={`${prefix}login`} component={ViewLogin} />
      <ContextRoute exact path={prefix} contextComponent={RouteContext} component={ViewCorrectContent}/>
      <ContextRoute exact path={`${prefix}dashboard/uid/:dashboardUid`} contextComponent={RouteContext} component={ViewDashboard}/>
      <ContextRoute exact path={`${prefix}reference/name/:name`} contextComponent={RouteContext} component={ViewIframe} />

      <ContextRoute exact path={`${prefix}application/:name`} contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact path={`${prefix}cms/news/group/uid/:groupUid`} contextComponent={RouteContext} component={ViewList}/>

      <ContextRoute exact path={`${prefix}groups`} contextComponent={RouteContext} component={ViewGroupList}/>

      <ContextRoute exact path={`${prefix}cms/news/route/uid/:routeUid`} contextComponent={RouteContext} component={ViewList}/>
      <ContextRoute exact path={`${prefix}cms/news/route/uid/:routeUid/entity/uid/:entityUid`} contextComponent={RouteContext} component={ViewNewContent}/>

      <ContextRoute exact path={`${prefix}management/groups`} contextComponent={RouteContext} component={ViewGroupsList}/>
      <ContextRoute exact path={`${prefix}management/groups/:groupUid`} contextComponent={RouteContext} component={ViewGroupsManagement}/>
      <ContextRoute exact path={`${prefix}learning/courses`} contextComponent={RouteContext} component={ViewCoursesList}/>
      <ContextRoute exact path={`${prefix}learning/courses/:courseUid`} contextComponent={RouteContext} component={ViewCourseManagement}/>
      <ContextRoute exact={true} path={`${prefix}project/users`} contextComponent={RouteContext} component={ViewActiveProjectUserList}/>
      <ContextRoute exact={true} path={`${prefix}directory/users`} contextComponent={RouteContext} component={ViewDirectoryUserList}/>
      <ContextRoute exact={true} path={`${prefix}project`} contextComponent={RouteContext} component={ViewProjectList}/>
      <ContextRoute exact={true} path={`${prefix}users/new`} contextComponent={RouteContext} component={ViewNewUser}/>
      <ContextRoute exact={true} path={`${prefix}users/:userUid`} contextComponent={RouteContext} component={ViewEditUser}/>
      <ContextRoute exact={true} path={`${prefix}profiles`} contextComponent={RouteContext} component={ViewProfilesList}/>
      <ContextRoute exact={true} path={`${prefix}profiles/new`} contextComponent={RouteContext} component={ViewNewProfile}/>
      <ContextRoute exact={true} path={`${prefix}administrators`} contextComponent={RouteContext} component={ViewAdministratorsList}/>
      <ContextRoute exact={true} path={`${prefix}administrators/new`} contextComponent={RouteContext} component={ViewAdministratorsNew}/>
      <ContextRoute exact={true} path={`${prefix}administrators/:uid`} contextComponent={RouteContext} component={ViewAdministratorsEdit}/>
      <ContextRoute exact={true} path={`${prefix}devices/:uid`} contextComponent={RouteContext} component={ViewDeviceManagement}/>
      <ContextRoute exact={true} path={`${prefix}devices`} contextComponent={RouteContext} component={ViewDevicesList}/>

      <ContextRoute exact={true} path={`${prefix}iot/groups/schema/:schemaUid/entity/:uid`} contextComponent={RouteContext} component={ViewGroupIotManagement}/>
      <ContextRoute exact={true} path={`${prefix}iot/groups`} contextComponent={RouteContext} component={ViewIotList}/>
      <ContextRoute exact={true} path={`${prefix}container/new`} contextComponent={RouteContext} component={ViewNewContainer}/>
      <ContextRoute exact={true} path={`${prefix}tree/new`} contextComponent={RouteContext} component={ViewNewCourse}/>
      <ContextRoute exact={true} path={`${prefix}tree/:treeUid`} contextComponent={RouteContext} component={ViewEditTreeV2}/>

      <ContextRoute exact={true} path={`${prefix}tree`} contextComponent={RouteContext} component={ViewListTree}/>
      <ContextRoute exact={true} path={`${prefix}offer/new/:treeUid`} contextComponent={RouteContext} component={ViewNewOffer}/>
      <ContextRoute exact={true} path={`${prefix}offer/:treeUid/:registerUid`} contextComponent={RouteContext} component={ViewEditOffer}/>
    
      <ContextRoute exact={true} path={`${prefix}group/new`} contextComponent={RouteContext} component={ViewNewSchemaProject}/>
      <ContextRoute exact={true} path={`${prefix}project/new`} contextComponent={RouteContext} component={ViewNewProject}/>
      <ContextRoute exact={true} path={`${prefix}project/:projectUid`} contextComponent={RouteContext} component={ViewEditProject}/>
      <ContextRoute exact={true} path={`${prefix}entities`} contextComponent={RouteContext} component={ViewEntitiesList}/>
      <ContextRoute exact={true} path={`${prefix}products`} contextComponent={RouteContext} component={ViewProductsList}/>
      <ContextRoute exact={true} path={`${prefix}transactions`} contextComponent={RouteContext} component={ViewTransactionsList}/>
      <ContextRoute exact={true} path={`${prefix}product/new`} contextComponent={RouteContext} component={ViewProductNewV}/>
      {/* <ContextRoute exact={true} path={`${prefix}product/new`} contextComponent={RouteContext} component={ViewNewProduct}/> */}
      <ContextRoute exact={true} path={`${prefix}products/:productUid/edit`} contextComponent={RouteContext} component={ViewEditProduct}/>
      <ContextRoute exact={true} path={`${prefix}review/new`} contextComponent={RouteContext} component={ViewNewReview}/>
      <ContextRoute exact={true} path={`${prefix}review/:flowUid/edit`} contextComponent={RouteContext} component={ViewEditReview}/>
      <ContextRoute exact={true} path={`${prefix}reviews`} contextComponent={RouteContext} component={ViewListRevision}/>
      <ContextRoute exact={true} path={`${prefix}submission`} contextComponent={RouteContext} component={ViewListSubmission}/>

      <ContextRoute exact={true} path={`${prefix}schema/new`} contextComponent={RouteContext} component={ViewNewSchema}/>
      <ContextRoute exact={true} path={`${prefix}entities`} contextComponent={RouteContext} component={ViewEntitiesList}/>
      <ContextRoute exact={true} path={`${prefix}schema`} contextComponent={RouteContext} component={ViewSchemasList}/>
      <ContextRoute exact={true} path={`${prefix}schema/:schemaId`} contextComponent={RouteContext} component={ViewEntitiesList}/>
      <ContextRoute exact={true} path={`${prefix}schema/:schemaId/entities/new`} contextComponent={RouteContext} component={ViewNewEntity}/>
      <ContextRoute exact={true} path={`${prefix}schema/:schemaId/entities/:entityId/edit`} contextComponent={RouteContext} component={ViewEditEntity}/>
      <ContextRoute exact={true} path={`${prefix}settings/layout/edit`} contextComponent={RouteContext} component={ViewEditLayoutConfiguration}/>
      <ContextRoute exact={true} path={`${prefix}certificates`} contextComponent={RouteContext} component={ViewCertificatesList}/>
      <ContextRoute exact={true} path={`${prefix}certificates/:contentUid`} contextComponent={RouteContext} component={ViewEditCertificate}/>
      <ContextRoute exact={true} path={`${prefix}forum`} contextComponent={RouteContext} component={ViewForum}/>

      <ContextRoute exact={true} path={`${prefix}rubric`} contextComponent={RouteContext} component={ViewListRubric}/>
      <ContextRoute exact={true} path={`${prefix}rubric/new`} contextComponent={RouteContext} component={ViewNewRubric}/>

      <ContextRoute exact={true} path={`${prefix}group/:schemaUid`} contextComponent={RouteContext} component={ViewNewGroupEntity}/>

      <ContextRoute exact={true} path={`${prefix}external`} contextComponent={RouteContext} component={ViewAccessExternalList}/>

      <ContextRoute exact={true} path={`${prefix}external/new`} contextComponent={RouteContext} component={ViewNewAccessExternal}/>

      <ContextRoute exact={true} path={`${prefix}external/:externalUid`} contextComponent={RouteContext} component={ViewEditAccessExternal}/>

      <ContextRoute exact={true} path={`${prefix}managed/content/connector/uid/:connectorUid`} contextComponent={RouteContext} component={ManagedContentList}/>
      <ContextRoute exact={true} path={`${prefix}managed/content/connector/uid/:connectorUid/new`} contextComponent={RouteContext} component={ManagedContentNew}/>
      <ContextRoute exact={true} path={`${prefix}managed/content/connector/uid/:connectorUid/group/uid/:contentGroupUid`} contextComponent={RouteContext} component={ManagedContentEdit}/>
    </Switch>
  );
}
