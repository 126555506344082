import React, { useState, useRef, useEffect, useMemo } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { textFromUid } from '../../utils/textUtilities';
import './styles.scss';

const iconSeaarch = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    space="preserve"
  >
    <g extraneous="self">
      <path
        fill="#A6B1B7"
        d="M0.95,6.82c0-3.23,2.64-5.86,5.87-5.86
c3.24,0,5.87,2.63,5.87,5.86s-2.63,5.87-5.87,5.87C3.59,12.69,0.95,10.06,0.95,6.82z M15.86,15.18l-3.89-3.88
c1.05-1.2,1.68-2.77,1.68-4.48C13.65,3.06,10.59,0,6.82,0C3.06,0,0,3.06,0,6.82s3.06,6.82,6.82,6.82c1.71,0,3.28-0.63,4.48-1.67
l3.89,3.89C15.28,15.95,15.4,16,15.52,16c0.13,0,0.25-0.05,0.34-0.14C16.05,15.67,16.05,15.37,15.86,15.18z"
      />
    </g>
  </svg>
);

const iconFilter = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="768"
    height="768"
    viewBox="0 0 768 768"
  >
    <path
      fill="#000"
      d="M169.376 310.624l192 192c12.512 12.512 32.768 12.512 45.248 0l192-192c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0l-169.376 169.376-169.376-169.376c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248z"
    ></path>
  </svg>
);

export default function Filters({
  filtersValues = [],
  onChange = () => {},
  onSearch = null,
}) {
  const searchRef = useRef();
  const [filtersId, setFiltersId] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchTime, setSearchTime] = useState(null);

  const getFilterChildren = (uid) => {
    if (filtersValues.length > 0) {
      const filteredValues = filtersValues.filter(
        (filter) => filter.uid === uid
      );
      if (filteredValues.length > 0) {
        return filtersValues.filter((filter) => filter.uid === uid)[0].children;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    onChange(filtersId);
  }, [filtersId]);

  useEffect(() => {
    if (searchTerm == null) return;
    onSearch(searchTerm);
  }, [searchTerm]);

  const toggleFilter = (uid) => {
    if (!searchRef.current) return;
    var elem = searchRef.current.querySelector('[data-uid="' + uid + '"]');
    if (elem.style.display === 'block') {
      elem.style.display = 'none';
    } else {
      const elements = searchRef.current.querySelectorAll(
        'div[data-uid]:not([data-uid=""])'
      );
      for (const el of elements) {
        el.style.display = 'none';
      }
      elem.style.display = 'block';
    }
  };

  useOnClickOutside(searchRef, () => {
    if (!searchRef) return;
    const elements = searchRef.current.querySelectorAll(
      'div[data-uid]:not([data-uid=""])'
    );
    // console.log(els)
    for (const el of elements) {
      el.style.display = 'none';
    }
  });

  const searchInput = useMemo(() => {
    if (!onSearch) return null;

    return (
      <li className="rdp-admin-filter-item">
        <div className="rdp-admin-input-container">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {iconSeaarch}
        </div>
      </li>
    );
  }, [onSearch]);

  return (
    <div
      className="rdp-admin-container"
      // style={{ display: renderFilters ? 'block' : 'none' }}
      style={{ display: 'block' }}
      ref={searchRef}
    >
      <span>{textFromUid('filter_user')}</span>
      <ul className="rdp-admin-filter-items">
        {searchInput}
        {filtersValues.length > 0 &&
          filtersValues.map((filter, index) => (
            <li className="rdp-admin-filter-item" key={`group${index}`}>
              <span
                className="rdp-admin-filter-item-btn"
                onClick={() => toggleFilter(filter.uid)}
              >
                {filter.label}
                {iconFilter}
              </span>

              <div
                className="rdp-admin-filter-item-options"
                data-uid={filter.uid}
              >
                {getFilterChildren(filter.uid) &&
                  getFilterChildren(filter.uid).map((subitem, i) => (
                    <div
                      className="rdp-admin-filter-item-option"
                      key={`children${i}`}
                    >
                      <input
                        className="rdp-admin-item-option-label"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const filters = { ...filtersId };
                          const itemsArray = filters[filter.group];
                          // console.log(filter.group)
                          if (!checked) {
                            if (!Array.isArray(itemsArray)) return;
                            const itemsArrayCp = [...itemsArray];
                            const deleteIndex = itemsArrayCp.indexOf(
                              subitem.group
                            );
                            if (deleteIndex > -1) {
                              itemsArrayCp.splice(deleteIndex, 1);
                            }
                            if (itemsArrayCp.length === 0) {
                              delete filters[filter.group];
                              // console.log('deleted item')
                              // console.log(filters)
                            } else {
                              filters[filter.group] = itemsArrayCp;
                            }
                            setFiltersId(filters);
                          } else {
                            if (!Array.isArray(itemsArray)) {
                              filters[filter.group] = [subitem.group];
                            } else {
                              filters[filter.group] = [
                                ...filters[filter.group],
                                subitem.group,
                              ];
                            }
                            setFiltersId(filters);
                          }
                        }}
                        type="checkbox"
                        id={subitem.uid}
                        name={subitem.uid}
                      ></input>
                      <label
                        className="rdp-admin-filter-item-option-label"
                        for={subitem.uid}
                      >
                        {subitem.label}
                      </label>
                    </div>
                  ))}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}
