import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import {
  Footer,
  ComponentTree,
  Header,
  Sidebar,
  Breadcrumb,
} from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';

export default function ListEntities({ consumer, entity, history, match }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [schemaDetails, setSchemaDetails] = useState(null);

  useEffect(() => {
    configTable();
    getEntities();
    // getSchemas();
    // entitiesFunctions();

    // consumer.defineRoute({ name: '' });
  }, []);
  const getSchemaItems = async (uid) => {
    // const schemaItems = await Sdk.Api.authorization.entity.schemaItems(uid)
    const schemaItems = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/management/schema/uid/${uid}/items`
    );
    // console.log({schemaTree})
    return schemaItems;
  };
  async function configTable() {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.entities);
  }

  async function getSchemas() {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/management/schema/available`
    );

    return result;
  }

  async function entitiesFunctions(resultSchemas) {
    let schema = resultSchemas;

    let schemaData = [];

    await Promise.all(
      schema.map(async (items) => {
        await Sdk.Api.authorization.entity.schemaItems(items.uid).then(
          (response) =>
            (schemaData = [
              ...schemaData,
              {
                ...items,
                entity: response,
              },
            ])
        );
      })
    );

    return schemaData;
  }

  async function getEntities() {
    const schemaItems = await getSchemaItems(match.params.schemaId);

    const project = await Sdk.dynamic.bridge(
      `auth/v1.0/project/uid/${Sdk.authorization.activeProject.uid}`,
      null,
      'GET'
    );
    const { directory } = project;

    const schema = await Sdk.Api.authorization.entity.schema(match.params.schemaId)
    const schemaDetailsData = await Sdk.dynamic.bridge(`auth/v1.0/entity/schema/available/directory/uid/${directory.uid}`);
    var tempSchema = schemaDetailsData.find(item => item.uid === match.params.schemaId);
    setSchemaDetails(tempSchema);

    if (schemaItems.length === 0) {
      setMessages({ noContent: 'Nenhuma entidade encontrada' });
      setElements({
        result: schemaItems,
        row_count: schemaItems.length,
        page_size: schemaItems.length,
      });
      setPreloader(false);
      return;
    }
    setElements({
      result: schemaItems.sort((a, b) => {
        return a.row - b.row;
      }),
      row_count: schemaItems.length,
      page_size: schemaItems.length,
    });
    setPreloader(false);

    // const tmpStructureCp = { ...elements };

    // let resultSchemas = await getSchemas();

    // const entities = await entitiesFunctions(resultSchemas);

    // let schemaData = [];

    // console.log('entities', entities);

    // entities.map((schema) => {
    //   if (schema.entity.length > 0) {
    //     schema.entity.map((entity) => {
    //       let dataObject = entity.data;

    //       if (dataObject.titulo) {
    //         dataObject = { ...dataObject, name: dataObject.titulo };

    //         delete dataObject.titulo;
    //       }

    //       schemaData = [...schemaData, { ...entity, ...dataObject }];
    //     });
    //   }
    // });

    // schemaData.forEach((uidSchemas) => {
    //   tmpStructureCp.result = Array.isArray(tmpStructureCp.result)
    //     ? [...tmpStructureCp.result, uidSchemas]
    //     : [uidSchemas];

    //   tmpStructureCp.current_page = 1;
    //   tmpStructureCp.page_count = 1;
    //   tmpStructureCp.page_size = schemaData.length;
    //   tmpStructureCp.row_count = schemaData.length;

    //   if (schemaData.length == 0) {
    //     setMessages({ noContent: 'Nenhum curso registrado' });
    //   }
    // });

    // setElements(tmpStructureCp);

    // setPreloader(false);
  }

  async function handleDelete(data) {
    setDeleting(true);

    console.log('handleDelete data', data);

    try {
      if (Array.isArray(data)) {
        await handleMultiDelete(data);
        setMessages({ success: config.multiDeleteText });
      } else {
        await deleteItem(data);

        console.log('config', config);
        setMessages({ success: config.deleteText });

        // setPreloader(true);
        // await getEntities();
      }

      await getEntities();
    } catch (error) {
      setMessages({ error: config.deleteError });
    }

    setDeleting(false);
  }

  async function handleMultiDelete(uids) {
    for (const uid of uids) {
      await deleteItem(uid);
    }
  }

  async function deleteItem(uid) {
    const result = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/uid/${uid}`,
      null,
      'DELETE'
    );

    return result;
  }

  //cms/v1.0/learning/course/uid/4ab2375e-105b-4ff1-286d-08d9488cea42/management/clone
  function onUpdate(uid) {
    history.push(`entities/${uid}`);
  }

  return (
    <>
      {elements.result ? (
        <div className="rdp-admin-new-entity">
          <Header />
          <Sidebar
            currentRoute="/users/new"
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb
            currentBreadcrumbTitle={schemaDetails.name}
            currentRoute={consumer ? consumer.currentRoute : null}
            newOption={() =>
              history.push(
                `/schema/${match.params.schemaId}/entities/new?row=${elements.result.length}&column=0`
              )
            }
            label="Criar Registro"
            customNewOptionTitle="Criar Registro"
          />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            <div className="content-wrapper">
              {elements.result && (
                <ComponentTree
                  items={elements.result}
                  handleAddEntity={(item) => {
                    history.push(
                      `/schema/${match.params.schemaId}/entities/${item.uid}/edit`
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  );
}
