import React, { Component } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';
import './iframe.scss';

import { Header, Sidebar, Breadcrumb } from '../../components';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export default class ViewIframe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
      uri: '',
    };
  }

  loadIframeUri() {
    const { consumer } = this.props;
    let uri =
      consumer && consumer.currentRoute
        ? consumer.currentRoute.externalUri
        : '';

    const { name: appName } = this.props.match.params;

    if (Sdk.authorization.credential) {
      const domain = Sdk.Api.domain;
      const projectKey = Sdk.Api.projectKey;
      const envCode = Sdk.Api.enviroment[0];
      const activeProject = Sdk.Api.authorization.activeProject;
      const accessToken = Sdk.authorization.credential.accessToken;

      var uriModel = `${domain}/product/${appName}/c/${projectKey}/p/${activeProject.uid}/${envCode}/?access_token=${accessToken}&language=pt_br`;

      const apps = {
        composer: '#/embed/',
        dam: '#/embedv2/files',
      };

      const sufix = apps[appName];

      if (
        consumer.currentRoute &&
        consumer.currentRoute.force_project_and_access_token
      ) {
        uri = `${uriModel}${sufix}`;
      }
    }

    this.setState({ uri });
  }

  componentDidMount() {
    this.loadIframeUri();
  }

  resetURIFromStateToReloadFrame() {
    this.setState({ uri: '' }, () => {
      setTimeout(() => {
        this.loadIframeUri();
      }, 500);
    });
  }

  render() {
    return (
      <div className="rdp-admin-tex">
        <Header />
        <Sidebar
          onClickOption={() => this.resetURIFromStateToReloadFrame()}
          defineGroup={
            this.props.consumer ? this.props.consumer.defineGroup : null
          }
          defineRoute={
            this.props.consumer ? this.props.consumer.defineRoute : null
          }
          groups={this.props.consumer ? this.props.consumer.groups : null}
        />
        {/* <Breadcrumb
          currentRoute={consumer ? consumer.currentRoute : null}
          defineRoute={consumer ? consumer.defineRoute : null}
        /> */}
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          <iframe
            src={this.state.uri}
            style={{ width: '100%', height: 'calc(100% - 2px)' }}
          />
        </div>
      </div>
    );
  }
}
