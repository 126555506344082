import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 200px;
  height: 200px;
  background: #ffffff66;
  border-radius: 25px;
  padding: 15px;
`;

export const ContainerSub = styled.div`
  border-radius: 25px;
  border: solid 1px #d2dae8;
  height: 100%;
  font-size: 11pt;
  text-align: center;
`;

export const ContainerIcon = styled.div`
  svg{
    height: 50px;
    width: 50px;
    margin-top: 36px;
  }
`;


export const ContainerText = styled.div`
  font-size: 10pt;
  text-align: center;
  padding-top: 12px;
  color: #a6b1b6;
`;
