import React, { Component } from 'react';
import './device-management.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
  ModalFormDynamic,
} from '../../components';
import Loading from '../../components/loading';
import Configuration from './device-management.config';

export default class ViewDeviceManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      selectOptions: [],
      ready: false,
      schemas: [],
      loading: true,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
      modalEntity: {
        open: false,
      },
    };
    this.entityFieldPrefix = 'entities';
  }

  componentDidMount() {
    const { uid } = this.props.match.params;
    if (uid !== 'new') {
      this.getDeviceDetails().then(() => this.getSchemas(true));
    } else {
      this.getSchemas(true);
    }
  }

  transformEntityField = (uid) => `${this.entityFieldPrefix}.${uid}`;

  getSchemasItems = async (uid) =>
    await Sdk.Api.authorization.entity.schemaItems(uid, { noCache: true });

  /**
   * transform array entities, example: ["scenario-1", "equipment-1"] in
   * {
   *   scenarios: ["scenario-1"]
   *   equipments: ["equipment-1"]
   * }
   */
  hydrateSchemaValues = (schemas, selectOptions) => {
    const entities = this.state.values.entities || [];

    const transform = (schema) =>
      selectOptions
        .find(
          (entity) =>
            entity.identifier === this.transformEntityField(schema.uid)
        )
        .data.filter((option) =>
          entities.some((entity) => entity === option.uid)
        )
        .map((option) => option.uid);

    return schemas.reduce(
      (accumulator, schema) => ({
        ...accumulator,
        [this.transformEntityField(schema.uid)]: transform(schema),
      }),
      {}
    );
  };

  /**
   * add actions to entities button "novo"
   * when clicked in entities.{uid} button,
   *  should execute the action with schema inside
   */
  hydrateModalEntityActions = (schemas) =>
    schemas.reduce(
      (accumulator, value) => ({
        ...accumulator,
        [this.transformEntityField(value.uid)]: () =>
          this.toggleModalEntity(value),
      }),
      {}
    );

  getSchemas = async (initialize) => {
    const schemas = await Sdk.authorization.entity.entitySchemasAvailableInProject(
      null,
      { noCache: true }
    );
    const entity = Configuration.getEntity();

    const extendedSelectionOptions = await Promise.all(
      schemas.map(async (schema) => ({
        identifier: this.transformEntityField(schema.uid),
        data: await this.getSchemasItems(schema.uid),
      }))
    );

    const extendedConfiguration = schemas.map((schema) => ({
      identifier: this.transformEntityField(schema.uid),
      field: schema.name,
      type: 'reference-entity',
      options: {
        multiple: true,
      },
      action_button: {
        title: 'Novo',
      },
      reference_entity_properties: {
        options_fields: [
          {
            identifier: 'name',
            type: 'input-text',
          },
        ],
      },
    }));

    const modalEntitiesActions = this.hydrateModalEntityActions(schemas);
    const entityValues = this.hydrateSchemaValues(
      schemas,
      extendedSelectionOptions
    );

    if (initialize) {
      this.setState({
        values: {
          ...this.state.values,
          ...entityValues,
        },
      });
    }

    this.setState({
      modalEntitiesActions,
      loading: false,
      selectOptions: [
        ...this.state.selectOptions.filter(
          ({ identifier }) =>
            !extendedSelectionOptions.some(
              (extended) => extended.identifier === identifier
            )
        ),
        ...extendedSelectionOptions,
      ],
      entity: {
        ...entity,
        configuration: [...entity.configuration, ...extendedConfiguration],
      },
    });
  };

  getDeviceDetails = async () => {
    const { uid } = this.props.match.params;
    const data = await Sdk.Api.iot.getDevice(uid);

    this.setState({
      ready: true,
      values: {
        ...data.device,
        path: data.device.custom_data ? data.device.custom_data.path : null,
      },
    });
  };

  saveAll = async () => {
    try {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgSuccess: 'Salvo com sucesso!',
      });
    } catch (e) {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgError: 'Erro ao salvar.',
      });
    }
  };

  hydrateFormData = (form) => {
    const entities = Object.entries(form.data).reduce(
      (accumulator, [key, value]) => {
        if (key.includes(this.entityFieldPrefix) && Array.isArray(value)) {
          return [...accumulator, ...value];
        }
        return accumulator;
      },
      []
    );

    return {
      name: form.data.name,
      description: form.data.description,
      custom_data: {
        path: form.data.path,
      },
      entities,
      filter_fields: 'Items.Object.WebId;Items.Object.Path',
    };
  };

  createDevice = async (form) => {
    const response = await Sdk.Api.iot.createDevice(this.hydrateFormData(form));

    if (response) {
      this.setState({
        values: {},
        msgSuccess: 'Salvo com sucesso!',
        loading: false,
      });
    }
  };

  updateDevice = async (form) => {
    const { uid } = this.props.match.params;

    const response = await Sdk.Api.iot.updateDevice(
      uid,
      this.hydrateFormData(form)
    );

    if (response) {
      this.setState({
        msgSuccess: 'Atualizado com sucesso!',
        loading: false,
      });
    }
  };

  handleSubmit = async (form) => {
    this.setState({
      msgError: null,
      loading: true,
    });

    try {
      if (this.props.match.params.uid === 'new') {
        await this.createDevice(form);
      } else {
        await this.updateDevice(form);
      }
    } catch {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgError: 'O path especificado não está correto',
      });
    }
  };

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  handleOnDetails = async (current) => {
    await this.getMembers(current);
    this.setState({
      open: {
        current,
      },
    });
  };

  linkCreatedEntity = async (result) => {
    await this.getSchemas();

    const propertyValue = this.transformEntityField(this.state.modalEntity.uid);

    this.setState({
      values: {
        ...this.state.values,
        [propertyValue]: [...this.state.values[propertyValue], result.uid],
      },
    });
  };

  submitModalEntity = async (form) => {
    this.setState({
      modalEntity: {
        ...this.state.modalEntity,
        loading: true,
      },
    });

    const result = await Sdk.Api.authorization.entity.createSchemaItem(
      this.state.modalEntity.uid,
      {
        row: 0,
        column: 0,
        data: form.data,
      }
    );

    await this.linkCreatedEntity(result);

    this.setState({
      modalEntity: {
        ...this.state.modalEntity,
        loading: false,
        open: false,
      },
    });
  };

  toggleModalEntity(schema) {
    const { modalEntity } = this.state;

    const open = !modalEntity.open;
    let title = null;
    let uid = null;

    if (schema) {
      title = `Criar ${schema.name}`;
      uid = schema.uid;
    }

    this.setState({
      modalEntity: {
        ...modalEntity,
        uid,
        open,
        title,
      },
    });
  }

  renderModalEntity() {
    return (
      <ModalFormDynamic
        formProps={{
          values: {},
          isNew: true,
          handleSubmit: this.submitModalEntity,
          entity: {
            layout_configuration: {
              hide_event_period: true,
              hide_products_restriction: true,
            },
            configuration: [
              {
                field: 'Nome',
                identifier: 'name',
                type: 'input-text',
              },
            ],
          },
          hideDraftButton: true,
          submitText: this.state.modalEntity.loading ? 'Salvando...' : 'Salvar',
          submitEnabled: true,
        }}
        open={this.state.modalEntity.open}
        title={this.state.modalEntity.title}
        onClose={() => this.toggleModalEntity()}
      />
    );
  }

  render() {
    const { consumer } = this.props;
    const {
      values,
      entity,
      loading,
      loadingSubmit,
      msgSuccess,
      msgError,
    } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
        {this.renderModalEntity()}
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loading && <Loading msg="Carregando formulário..." />}
          {loadingSubmit && <Loading msg="Enviando..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {/* {entity && (
            <FormDynamic
              actions={{
                click: this.state.modalEntitiesActions,
              }}
              selectOptions={this.state.selectOptions}
              values={values}
              isNew={true}
              cleanValues={msgSuccess ? true : false}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              entity={entity}
              hideDraftButton={true}
              submitText={'Salvar'}
              submitEnabled={true}
            />
          )} */}
        </div>
      </div>
    );
  }
}
